import { CheckboxLabel } from "./CheckboxLabel"
import { CheckboxLabelledRow } from "./CheckboxLabelledRow"
import { CheckboxReadOnly } from "../Checkbox"
import { HTMLAttributes } from "react"

export interface CheckboxLabelledReadOnlyProps
  extends HTMLAttributes<HTMLDivElement> {
  checked: boolean
  disabled?: boolean
  labelClassName?: string
}

export function CheckboxLabelledReadOnly({
  checked,
  disabled,
  children,
  labelClassName,
  ...otherProps
}: CheckboxLabelledReadOnlyProps) {
  return (
    <CheckboxLabelledRow {...otherProps}>
      <CheckboxReadOnly checked={checked} disabled={disabled} />
      <CheckboxLabel className={labelClassName}>{children}</CheckboxLabel>
    </CheckboxLabelledRow>
  )
}
