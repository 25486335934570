import { useIdStringFilter } from "components/TableV2/useFilter"
import { useListPricingTiersForCompanyQuery } from "features/api/company"

export const tierFilterKey = "tiers"

export function useTierFilter() {
  const { data } = useListPricingTiersForCompanyQuery({})

  const { filter, selectedKeys: selectedTierIdStrings } = useIdStringFilter({
    key: tierFilterKey,
    label: "Tier",
    values: data?.tiers ?? [],
    sort: (a, b) => a.rank - b.rank,
    labelExtractor: (t) => t.name,
    autoFilterOptions: true,
  })

  return { filter, selectedTierIdStrings }
}
