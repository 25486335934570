import {
  ACCOUNT_PARAM,
  CLASS_PARAM,
  CLERK_PARAM,
  CUSTOMER_PARAM,
  CUSTOMER_TAG_PARAM,
  DATE_RANGE_PARAM,
  DEPARTMENT_PARAM,
  END_DATE_PARAM,
  FINELINE_PARAM,
  JOB_PARAM,
  LOCATION_PARAM,
  PARAM_DELIMITER,
  PRODUCT_PARAM,
  PRODUCT_TAG_PARAM,
  REP_PARAM,
  START_DATE_PARAM,
  TERMS_PARAM,
  VENDOR_PARAM,
} from "../params"
import {
  Cell,
  ChipCell,
  DateRange,
  ReportConfig,
  ReportData,
  Row,
  Sort,
  TextCell,
} from "../types"
import {
  Cell as CellPB,
  Cell_Type as CellTypePB,
  Cell_Width as CellWidthPB,
  Chip_Type as ChipType,
  Report,
} from "gen/analytics/models_pb"
import {
  Filter,
  Filter_Type as FilterType,
  Filters,
  Filter_RangeType as RangeType,
} from "gen/analyticsv2/models_pb"
import { Format, Timestamp } from "lib/timestamp"

import { Decimal } from "decimal"
import { Money } from "money"
import { Money as MoneyPB } from "gen/proto/money/models_pb"
import { Decimal as PBDecimal } from "gen/proto/decimal/models_pb"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { ParsedUrlQuery } from "querystring"
import { TimestampRange } from "lib/timestamp_range"
import { Timestamp as Timestamppb } from "gen/google/protobuf/timestamp_pb"
import { UUID } from "uuid-rd"
import { queryParamsToDate } from "lib/params"
import { urls } from "urls"

export const TOTAL_KEY = "total"

export const clientTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

type queryParamValue = string | string[] | undefined
export function isString(value: queryParamValue): value is string {
  return typeof value === "string"
}

function extractParams(paramsString: queryParamValue): string[] {
  if (!isString(paramsString)) {
    return []
  }

  return paramsString.split(PARAM_DELIMITER)
}

export function getEnums(enumsString: queryParamValue): number[] {
  return extractParams(enumsString)
    .map((enumString) => Number(enumString))
    .filter((n): n is number => !isNaN(n))
}

function getUUIDs(idsString: queryParamValue): PBUUID[] {
  return extractParams(idsString).map((idString) =>
    UUID.fromString(idString).toPB()
  )
}

function getDate(dateString: queryParamValue): Date | undefined {
  if (!isString(dateString)) {
    return undefined
  }

  return queryParamsToDate(dateString) ?? undefined
}

export function getDateRangeFilter(
  c: ReportConfig,
  startDateString: queryParamValue,
  endDateString: queryParamValue,
  rangeDateString: queryParamValue
): SerializableFilter | undefined {
  const start = getDate(startDateString)
  const end = getDate(endDateString)
  const range = getDateRange(rangeDateString)

  switch (c.dateFilter.type) {
    case "date":
      if (c.dateFilter.side === "start") {
        return {
          type: FilterType.TIMESTAMP_RANGE,
          rangeType: RangeType.START_ONLY,
          clientTimezone: clientTimeZone(),
          clientDateRange: {
            start: start,
            end: undefined,
          },
          ids: [],
        }
      } else {
        return {
          type: FilterType.TIMESTAMP_RANGE,
          rangeType: RangeType.END_ONLY,
          clientTimezone: clientTimeZone(),
          clientDateRange: {
            start: undefined,
            end: end,
          },
          ids: [],
        }
      }
    case "daterange":
      return {
        type: FilterType.TIMESTAMP_RANGE,
        rangeType: RangeType.FULL,
        clientTimezone: clientTimeZone(),
        clientDateRange: range,
        ids: [],
      }
      return {
        // Customer aging doesn't always have a date, but still needs a client timezone
        type: FilterType.TIMESTAMP_RANGE,
        rangeType: RangeType.UNDEFINED,
        clientTimezone: clientTimeZone(),
        ids: [],
      }
  }
}

function getDateRange(
  dateRangeString: string | string[] | undefined
): DateRange | undefined {
  if (typeof dateRangeString !== "string") {
    return undefined
  }

  const tr = TimestampRange.fromQueryParam(dateRangeString)

  return tr?.start && tr.end
    ? {
        start: Timestamppb.toDate(tr.start),
        end: Timestamppb.toDate(tr.end),
      }
    : undefined
}

export function extractBytes({ bytes }: { bytes: Uint8Array }): Uint8Array {
  return bytes
}

export function timeSort(label: string, value: number): Sort {
  return {
    label,
    value,
    ascSortType: "Oldest",
    descSortType: "Newest",
  }
}

export function numericalSort(label: string, value: number): Sort {
  return {
    label,
    value,
    ascSortType: "Low to High",
    descSortType: "High to Low",
  }
}

export function alphabeticSort(label: string, value: number): Sort {
  return {
    label,
    value,
    ascSortType: "A to Z",
    descSortType: "Z to A",
  }
}

function textCell(cell: Partial<TextCell>): TextCell {
  return {
    type: "text",
    width: "base",
    font: "base",
    value: "",
    ...cell,
  }
}
export function baseCell(value?: string): Cell {
  return textCell({ value })
}

export function baseDecimalCell(value?: PBDecimal): Cell {
  return baseCell(Decimal.fromPB(value).toPercentString(1))
}

export function baseNumberCell(value: number): Cell {
  return baseCell(value.toString())
}

export function baseMoneyCell(m?: MoneyPB, hideZero?: boolean): Cell {
  const money = Money.fromPB(m)
  return baseCell(money.isZero() && hideZero ? "" : money.format())
}

export function boldCell(value?: string): Cell {
  return textCell({ value, font: "bold" })
}

export function dateCell(value?: Timestamppb): Cell {
  const val = Timestamp.format(Format.DATE_TIME, value)
  return textCell({ value: val, width: "medium" })
}

export function longCell(value?: string): Cell {
  return textCell({ value, width: "medium", font: "bold" })
}

export function parseReport(report?: Report): ReportData {
  const summaryLabels: string[] = []
  const summaryValues: { [summaryLabel: string]: string } = {}
  for (const { label, value } of report?.summaries ?? []) {
    summaryLabels.push(label)
    summaryValues[label] = value
  }

  const rows: Row[] = []
  for (const { key, cellsByHeader } of report?.rows ?? []) {
    const cells: {
      [headerKey: string]: Cell
    } = {}
    for (const header in cellsByHeader) {
      cells[header] = parseCell(cellsByHeader[header])
    }
    rows.push({
      key: key,
      cells: cells,
    })
  }

  return {
    headersLabels: report?.headers,
    summaryLabels: summaryLabels,
    summaryValues: summaryValues,
    rows: rows,
  }
}

function parseCell(cell?: CellPB): Cell {
  if (cell?.type == CellTypePB.CHIP) {
    return parseChipCell(cell)
  }

  let width: "base" | "medium" | "long" = "base"
  switch (cell?.width) {
    case CellWidthPB.LONG:
      width = "long"
      break
    case CellWidthPB.MEDIUM:
      width = "medium"
      break
  }
  return {
    value: cell?.value ?? "",
    type: "text",
    font: cell?.type == CellTypePB.BOLD ? "bold" : "base",
    width,
  }
}

function parseChipCell(cell: CellPB): Cell {
  if (cell.value == "") {
    return baseCell("")
  }
  const chipCell: Cell = {
    type: "chip",
    url: "",
    color: "gray",
    value: cell.value,
  }
  const chipSettings: Record<ChipType, Partial<ChipCell>> = {
    [ChipType.RETURN]: {
      color: "yellow",
      url: urls.return(
        cell.chip?.return?.saleId,
        cell.chip?.return?.returnIdentifier
      ),
    },
    [ChipType.SALE]: {
      color: "green",
      url: urls.sale(cell.chip?.sale?.saleId),
    },
    [ChipType.PRODUCT]: {
      url: urls.product(cell.chip?.product?.productId),
      color: "blue",
    },
    [ChipType.CUSTOMER]: {
      url: urls.customer(cell.chip?.customer?.customerId),
      color: "sky",
    },
    [ChipType.LOCATION]: {
      color: "gray",
    },
    [ChipType.ACCOUNT]: {
      color: "gray",
    },
    [ChipType.BILL_PAYMENT]: {
      color: "green",
      url: urls.bill(cell.chip?.billPayment?.billPaymentId, false),
    },
    [ChipType.BILL_REFUND]: {
      color: "yellow",
      url: urls.bill(cell.chip?.billRefund?.billPaymentId, true),
    },
    [ChipType.ORDER_SHIPMENT]: {
      color: "gray",
      url: urls.orderShipment(cell.chip?.orderShipment?.orderShipmentId),
    },
    [ChipType.TRANSFER]: {
      color: "gray",
      url: urls.transfer(cell.chip?.transfer?.transferId),
    },
    [ChipType.VENDOR]: {
      color: "gray",
      url: urls.vendor(cell.chip?.vendor?.vendorId),
    },
    [ChipType.COUNT]: {
      color: "gray",
      url: urls.inventoryCount(cell.chip?.count?.countId),
    },
    [ChipType.UNDEFINED]: {},
    [ChipType.CLOSE_OUT]: {},
    [ChipType.PETTY_CASH]: {},
  }
  return { ...chipCell, ...chipSettings[cell.chip?.type ?? ChipType.UNDEFINED] }
}

export type SerializableReq<T> = Omit<T, "filters"> & {
  safeFilters: SerializableFilters
}

type ReqWithFilters<T> = Omit<SerializableReq<T>, "safeFilters"> & {
  filters: Filters
}

type SerializableFilters = {
  locationFilter: SerializableFilter | undefined
  productFilter: SerializableFilter | undefined
  productTagFilter: SerializableFilter | undefined
  customerFilter: SerializableFilter | undefined
  jobFilter: SerializableFilter | undefined
  customerTagFilter: SerializableFilter | undefined
  vendorFilter: SerializableFilter | undefined
  departmentFilter: SerializableFilter | undefined
  classFilter: SerializableFilter | undefined
  finelineFilter: SerializableFilter | undefined
  clerkFilter: SerializableFilter | undefined
  repFilter: SerializableFilter | undefined
  dateRangeFilter: SerializableFilter | undefined
  accountFilter: SerializableFilter | undefined
  termsFilter: SerializableFilter | undefined
}

export function restoreSerializableReq<T>(
  req: SerializableReq<T>
): ReqWithFilters<T> {
  return {
    ...req,
    filters: {
      timestampRangeFilter: restoreSerializableFilter(
        req.safeFilters.dateRangeFilter
      ),
      staffFilter: restoreSerializableFilter(req.safeFilters.clerkFilter),
      locationFilter: restoreSerializableFilter(req.safeFilters.locationFilter),
      productFilter: restoreSerializableFilter(req.safeFilters.productFilter),
      productTagFilter: restoreSerializableFilter(
        req.safeFilters.productTagFilter
      ),
      customerFilter: restoreSerializableFilter(req.safeFilters.customerFilter),
      jobFilter: restoreSerializableFilter(req.safeFilters.jobFilter),
      customerTagFilter: restoreSerializableFilter(
        req.safeFilters.customerTagFilter
      ),
      vendorFilter: restoreSerializableFilter(req.safeFilters.vendorFilter),
      departmentFilter: restoreSerializableFilter(
        req.safeFilters.departmentFilter
      ),
      classFilter: restoreSerializableFilter(req.safeFilters.classFilter),
      finelineFilter: restoreSerializableFilter(req.safeFilters.finelineFilter),
      repFilter: restoreSerializableFilter(req.safeFilters.repFilter),
      clerkFilter: restoreSerializableFilter(req.safeFilters.clerkFilter),
      accountFilter: restoreSerializableFilter(req.safeFilters.accountFilter),
      termsFilter: restoreSerializableFilter(req.safeFilters.termsFilter),
    },
  }
}

export type SerializableFilter = Omit<Filter, "clientTimestampRange"> & {
  clientDateRange?: DateRange
}

export function parseSerializableFilters(
  p: ParsedUrlQuery,
  c: ReportConfig
): SerializableFilters {
  return {
    locationFilter: idFilter(p[LOCATION_PARAM]),
    productFilter: idFilter(p[PRODUCT_PARAM]),
    productTagFilter: idFilter(p[PRODUCT_TAG_PARAM]),
    customerFilter: idFilter(p[CUSTOMER_PARAM]),
    jobFilter: idFilter(p[JOB_PARAM]),
    customerTagFilter: idFilter(p[CUSTOMER_TAG_PARAM]),
    vendorFilter: idFilter(p[VENDOR_PARAM]),
    departmentFilter: idFilter(p[DEPARTMENT_PARAM]),
    classFilter: idFilter(p[CLASS_PARAM]),
    finelineFilter: idFilter(p[FINELINE_PARAM]),
    clerkFilter: idFilter(p[CLERK_PARAM]),
    repFilter: idFilter(p[REP_PARAM]),
    accountFilter: idFilter(p[ACCOUNT_PARAM]),
    termsFilter: idFilter(p[TERMS_PARAM]),
    dateRangeFilter: getDateRangeFilter(
      c,
      p[START_DATE_PARAM],
      p[END_DATE_PARAM],
      p[DATE_RANGE_PARAM]
    ),
  }
}

export function idFilter(idsString: queryParamValue): SerializableFilter {
  const ids = getUUIDs(idsString)
  return Filter.create({
    type: FilterType.ID,
    ids: ids,
  })
}

function restoreSerializableFilter(
  filter: SerializableFilter | undefined
): Filter | undefined {
  const start = filter?.clientDateRange?.start
  const end = filter?.clientDateRange?.end
  return Filter.create({
    type: filter?.type,
    rangeType: filter?.rangeType,
    clientTimezone: filter?.clientTimezone,
    ids: filter?.ids,
    clientTimestampRange: filter?.clientDateRange
      ? {
          start: start ? Timestamppb.fromDate(start) : undefined,
          end: end ? Timestamppb.fromDate(end) : undefined,
        }
      : undefined,
  })
}
