/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "api/company/service.proto" (package "rd.api.company", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { CompanyService } from "./service_pb";
import type { ReorderFinanceChargeSettingsRes } from "./service_pb";
import type { ReorderFinanceChargeSettingsReq } from "./service_pb";
import type { DeactivateFinanceChargeSettingRes } from "./service_pb";
import type { DeactivateFinanceChargeSettingReq } from "./service_pb";
import type { ListCustomersForFinanceChargeSettingRes } from "./service_pb";
import type { ListCustomersForFinanceChargeSettingReq } from "./service_pb";
import type { ListActiveFinanceChargeSettingsForCompanyRes } from "./service_pb";
import type { ListActiveFinanceChargeSettingsForCompanyReq } from "./service_pb";
import type { UpdateFinanceChargeSettingRes } from "./service_pb";
import type { UpdateFinanceChargeSettingReq } from "./service_pb";
import type { CreateFinanceChargeSettingRes } from "./service_pb";
import type { CreateFinanceChargeSettingReq } from "./service_pb";
import type { GetPermissionsGroupsRes } from "./service_pb";
import type { GetPermissionsGroupsReq } from "./service_pb";
import type { UpdateCardSurchargeRateRes } from "./service_pb";
import type { UpdateCardSurchargeRateReq } from "./service_pb";
import type { GetCurrentUserRes } from "./service_pb";
import type { GetCurrentUserReq } from "./service_pb";
import type { ListCustomersForTierRes } from "./service_pb";
import type { ListCustomersForTierReq } from "./service_pb";
import type { ListPricingTiersForCompanyRes } from "./service_pb";
import type { ListPricingTiersForCompanyReq } from "./service_pb";
import type { ReorderPricingTiersRes } from "./service_pb";
import type { ReorderPricingTiersReq } from "./service_pb";
import type { DeletePricingTierRes } from "./service_pb";
import type { DeletePricingTierReq } from "./service_pb";
import type { UpdatePricingTierRes } from "./service_pb";
import type { UpdatePricingTierReq } from "./service_pb";
import type { CreatePricingTierRes } from "./service_pb";
import type { CreatePricingTierReq } from "./service_pb";
import type { ListCustomersForTermRes } from "./service_pb";
import type { ListCustomersForTermReq } from "./service_pb";
import type { ListFinancingTermsForCompanyRes } from "./service_pb";
import type { ListFinancingTermsForCompanyReq } from "./service_pb";
import type { ReorderFinancingTermsRes } from "./service_pb";
import type { ReorderFinancingTermsReq } from "./service_pb";
import type { DeleteFinancingTermRes } from "./service_pb";
import type { DeleteFinancingTermReq } from "./service_pb";
import type { UpdateFinancingTermRes } from "./service_pb";
import type { UpdateFinancingTermReq } from "./service_pb";
import type { CreateFinancingTermRes } from "./service_pb";
import type { CreateFinancingTermReq } from "./service_pb";
import type { DeactivateProductCategoryV2Res } from "./service_pb";
import type { DeactivateProductCategoryV2Req } from "./service_pb";
import type { ListProductsForCategoryV2Res } from "./service_pb";
import type { ListProductsForCategoryV2Req } from "./service_pb";
import type { ListProductCategoriesForCompanyV2Res } from "./service_pb";
import type { ListProductCategoriesForCompanyV2Req } from "./service_pb";
import type { UpdateProductCategoryV2Res } from "./service_pb";
import type { UpdateProductCategoryV2Req } from "./service_pb";
import type { CreateProductCategoryV2Res } from "./service_pb";
import type { CreateProductCategoryV2Req } from "./service_pb";
import type { ListProductsForFeeRes } from "./service_pb";
import type { ListProductsForFeeReq } from "./service_pb";
import type { ListAdditionalFeesForCompanyRes } from "./service_pb";
import type { ListAdditionalFeesForCompanyReq } from "./service_pb";
import type { ReorderAdditionalFeesRes } from "./service_pb";
import type { ReorderAdditionalFeesReq } from "./service_pb";
import type { DeleteAdditionalFeeRes } from "./service_pb";
import type { DeleteAdditionalFeeReq } from "./service_pb";
import type { UpdateAdditionalFeeRes } from "./service_pb";
import type { UpdateAdditionalFeeReq } from "./service_pb";
import type { CreateAdditionalFeeRes } from "./service_pb";
import type { CreateAdditionalFeeReq } from "./service_pb";
import type { GetStripeLoginURLRes } from "./service_pb";
import type { GetStripeLoginURLReq } from "./service_pb";
import type { GetStripeOnboardingURLRes } from "./service_pb";
import type { GetStripeOnboardingURLReq } from "./service_pb";
import type { GetTaxRateRes } from "./service_pb";
import type { GetTaxRateReq } from "./service_pb";
import type { ListTaxRatesForCompanyRes } from "./service_pb";
import type { ListTaxRatesForCompanyReq } from "./service_pb";
import type { DeactivateCustomTaxRateRes } from "./service_pb";
import type { DeactivateCustomTaxRateReq } from "./service_pb";
import type { ReorderCustomTaxRatesRes } from "./service_pb";
import type { ReorderCustomTaxRatesReq } from "./service_pb";
import type { UpdateCustomTaxRateRes } from "./service_pb";
import type { UpdateCustomTaxRateReq } from "./service_pb";
import type { CreateCustomTaxRateRes } from "./service_pb";
import type { CreateCustomTaxRateReq } from "./service_pb";
import type { UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes } from "./service_pb";
import type { UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq } from "./service_pb";
import type { UpdateCompanyLoyaltyProgramPolicyRes } from "./service_pb";
import type { UpdateCompanyLoyaltyProgramPolicyReq } from "./service_pb";
import type { UpdateCompanyIsLoyaltyProgramEnabledRes } from "./service_pb";
import type { UpdateCompanyIsLoyaltyProgramEnabledReq } from "./service_pb";
import type { UpdateCompanyIsCardSurchargeTaxExemptRes } from "./service_pb";
import type { UpdateCompanyIsCardSurchargeTaxExemptReq } from "./service_pb";
import type { UpdateCompanyIsFinancingTermsShownOnInvoiceRes } from "./service_pb";
import type { UpdateCompanyIsFinancingTermsShownOnInvoiceReq } from "./service_pb";
import type { UpdateCompanyIsColorFormulaHiddenRes } from "./service_pb";
import type { UpdateCompanyIsColorFormulaHiddenReq } from "./service_pb";
import type { UpdateCompanyIsPriceShownOnWillCallRes } from "./service_pb";
import type { UpdateCompanyIsPriceShownOnWillCallReq } from "./service_pb";
import type { UpdateCompanyIsDiscountShownToCustomersRes } from "./service_pb";
import type { UpdateCompanyIsDiscountShownToCustomersReq } from "./service_pb";
import type { UpdateCompanyContractorRewardsSettingsRes } from "./service_pb";
import type { UpdateCompanyContractorRewardsSettingsReq } from "./service_pb";
import type { UpdateCompanyShouldAutoSubmitContractorRewardsRes } from "./service_pb";
import type { UpdateCompanyShouldAutoSubmitContractorRewardsReq } from "./service_pb";
import type { UpdateCompanyGeneralCommunicationsSettingsRes } from "./service_pb";
import type { UpdateCompanyGeneralCommunicationsSettingsReq } from "./service_pb";
import type { UpdateCompanySecuritySettingsRes } from "./service_pb";
import type { UpdateCompanySecuritySettingsReq } from "./service_pb";
import type { UpdateCompanyGeneralSettingsRes } from "./service_pb";
import type { UpdateCompanyGeneralSettingsReq } from "./service_pb";
import type { UpdateCompanyStatementSettingsRes } from "./service_pb";
import type { UpdateCompanyStatementSettingsReq } from "./service_pb";
import type { GetCompanyRes } from "./service_pb";
import type { GetCompanyReq } from "./service_pb";
import type { GetCompanyForSubdomainRes } from "./service_pb";
import type { GetCompanyForSubdomainReq } from "./service_pb";
import type { UpdateStaffPermissionsRes } from "./service_pb";
import type { UpdateStaffPermissionsReq } from "./service_pb";
import type { ListAuthorizedEndpointsRes } from "./service_pb";
import type { ListAuthorizedEndpointsReq } from "./service_pb";
import type { DeactivateStaffWebAuthnKeysRes } from "./service_pb";
import type { DeactivateStaffWebAuthnKeysReq } from "./service_pb";
import type { ReactivateStaffRes } from "./service_pb";
import type { ReactivateStaffReq } from "./service_pb";
import type { DeactivateStaffRes } from "./service_pb";
import type { DeactivateStaffReq } from "./service_pb";
import type { ListStaffsForCompanyRes } from "./service_pb";
import type { ListStaffsForCompanyReq } from "./service_pb";
import type { UpdateStaffRes } from "./service_pb";
import type { UpdateStaffReq } from "./service_pb";
import type { CreateStaffRes } from "./service_pb";
import type { CreateStaffReq } from "./service_pb";
import type { GetStaffRes } from "./service_pb";
import type { GetStaffReq } from "./service_pb";
import type { UpdateLocationQuickbooksClassRes } from "./service_pb";
import type { UpdateLocationQuickbooksClassReq } from "./service_pb";
import type { UpdateLocationLogoImageUrlRes } from "./service_pb";
import type { UpdateLocationLogoImageUrlReq } from "./service_pb";
import type { UpdateLocationCashFloatRes } from "./service_pb";
import type { UpdateLocationCashFloatReq } from "./service_pb";
import type { UpdateLocationBMOutletNumberRes } from "./service_pb";
import type { UpdateLocationBMOutletNumberReq } from "./service_pb";
import type { UpdateLocationGoogleReviewUrlRes } from "./service_pb";
import type { UpdateLocationGoogleReviewUrlReq } from "./service_pb";
import type { ClearCardReaderRes } from "./service_pb";
import type { ClearCardReaderReq } from "./service_pb";
import type { ListCardReadersForLocationRes } from "./service_pb";
import type { ListCardReadersForLocationReq } from "./service_pb";
import type { RegisterCardReaderForLocationRes } from "./service_pb";
import type { RegisterCardReaderForLocationReq } from "./service_pb";
import type { ListLocationsForCompanyRes } from "./service_pb";
import type { ListLocationsForCompanyReq } from "./service_pb";
import type { UpdateLocationRes } from "./service_pb";
import type { UpdateLocationReq } from "./service_pb";
import type { CreateLocationRes } from "./service_pb";
import type { CreateLocationReq } from "./service_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetLocationRes } from "./service_pb";
import type { GetLocationReq } from "./service_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rd.api.company.CompanyService
 */
export interface ICompanyServiceClient {
    /**
     * Location
     *
     * @generated from protobuf rpc: GetLocation(rd.api.company.GetLocationReq) returns (rd.api.company.GetLocationRes);
     */
    getLocation(input: GetLocationReq, options?: RpcOptions): UnaryCall<GetLocationReq, GetLocationRes>;
    /**
     * @generated from protobuf rpc: CreateLocation(rd.api.company.CreateLocationReq) returns (rd.api.company.CreateLocationRes);
     */
    createLocation(input: CreateLocationReq, options?: RpcOptions): UnaryCall<CreateLocationReq, CreateLocationRes>;
    /**
     * @generated from protobuf rpc: UpdateLocation(rd.api.company.UpdateLocationReq) returns (rd.api.company.UpdateLocationRes);
     */
    updateLocation(input: UpdateLocationReq, options?: RpcOptions): UnaryCall<UpdateLocationReq, UpdateLocationRes>;
    /**
     * @generated from protobuf rpc: ListLocationsForCompany(rd.api.company.ListLocationsForCompanyReq) returns (rd.api.company.ListLocationsForCompanyRes);
     */
    listLocationsForCompany(input: ListLocationsForCompanyReq, options?: RpcOptions): UnaryCall<ListLocationsForCompanyReq, ListLocationsForCompanyRes>;
    /**
     * @generated from protobuf rpc: RegisterCardReaderForLocation(rd.api.company.RegisterCardReaderForLocationReq) returns (rd.api.company.RegisterCardReaderForLocationRes);
     */
    registerCardReaderForLocation(input: RegisterCardReaderForLocationReq, options?: RpcOptions): UnaryCall<RegisterCardReaderForLocationReq, RegisterCardReaderForLocationRes>;
    /**
     * @generated from protobuf rpc: ListCardReadersForLocation(rd.api.company.ListCardReadersForLocationReq) returns (rd.api.company.ListCardReadersForLocationRes);
     */
    listCardReadersForLocation(input: ListCardReadersForLocationReq, options?: RpcOptions): UnaryCall<ListCardReadersForLocationReq, ListCardReadersForLocationRes>;
    /**
     * @generated from protobuf rpc: ClearCardReader(rd.api.company.ClearCardReaderReq) returns (rd.api.company.ClearCardReaderRes);
     */
    clearCardReader(input: ClearCardReaderReq, options?: RpcOptions): UnaryCall<ClearCardReaderReq, ClearCardReaderRes>;
    /**
     * @generated from protobuf rpc: UpdateLocationGoogleReviewUrl(rd.api.company.UpdateLocationGoogleReviewUrlReq) returns (rd.api.company.UpdateLocationGoogleReviewUrlRes);
     */
    updateLocationGoogleReviewUrl(input: UpdateLocationGoogleReviewUrlReq, options?: RpcOptions): UnaryCall<UpdateLocationGoogleReviewUrlReq, UpdateLocationGoogleReviewUrlRes>;
    /**
     * @generated from protobuf rpc: UpdateLocationBMOutletNumber(rd.api.company.UpdateLocationBMOutletNumberReq) returns (rd.api.company.UpdateLocationBMOutletNumberRes);
     */
    updateLocationBMOutletNumber(input: UpdateLocationBMOutletNumberReq, options?: RpcOptions): UnaryCall<UpdateLocationBMOutletNumberReq, UpdateLocationBMOutletNumberRes>;
    /**
     * @generated from protobuf rpc: UpdateLocationCashFloat(rd.api.company.UpdateLocationCashFloatReq) returns (rd.api.company.UpdateLocationCashFloatRes);
     */
    updateLocationCashFloat(input: UpdateLocationCashFloatReq, options?: RpcOptions): UnaryCall<UpdateLocationCashFloatReq, UpdateLocationCashFloatRes>;
    /**
     * @generated from protobuf rpc: UpdateLocationLogoImageUrl(rd.api.company.UpdateLocationLogoImageUrlReq) returns (rd.api.company.UpdateLocationLogoImageUrlRes);
     */
    updateLocationLogoImageUrl(input: UpdateLocationLogoImageUrlReq, options?: RpcOptions): UnaryCall<UpdateLocationLogoImageUrlReq, UpdateLocationLogoImageUrlRes>;
    /**
     * @generated from protobuf rpc: UpdateLocationQuickbooksClass(rd.api.company.UpdateLocationQuickbooksClassReq) returns (rd.api.company.UpdateLocationQuickbooksClassRes);
     */
    updateLocationQuickbooksClass(input: UpdateLocationQuickbooksClassReq, options?: RpcOptions): UnaryCall<UpdateLocationQuickbooksClassReq, UpdateLocationQuickbooksClassRes>;
    /**
     * Staff
     *
     * @generated from protobuf rpc: GetStaff(rd.api.company.GetStaffReq) returns (rd.api.company.GetStaffRes);
     */
    getStaff(input: GetStaffReq, options?: RpcOptions): UnaryCall<GetStaffReq, GetStaffRes>;
    /**
     * @generated from protobuf rpc: CreateStaff(rd.api.company.CreateStaffReq) returns (rd.api.company.CreateStaffRes);
     */
    createStaff(input: CreateStaffReq, options?: RpcOptions): UnaryCall<CreateStaffReq, CreateStaffRes>;
    /**
     * @generated from protobuf rpc: UpdateStaff(rd.api.company.UpdateStaffReq) returns (rd.api.company.UpdateStaffRes);
     */
    updateStaff(input: UpdateStaffReq, options?: RpcOptions): UnaryCall<UpdateStaffReq, UpdateStaffRes>;
    /**
     * @generated from protobuf rpc: ListStaffsForCompany(rd.api.company.ListStaffsForCompanyReq) returns (rd.api.company.ListStaffsForCompanyRes);
     */
    listStaffsForCompany(input: ListStaffsForCompanyReq, options?: RpcOptions): UnaryCall<ListStaffsForCompanyReq, ListStaffsForCompanyRes>;
    /**
     * @generated from protobuf rpc: DeactivateStaff(rd.api.company.DeactivateStaffReq) returns (rd.api.company.DeactivateStaffRes);
     */
    deactivateStaff(input: DeactivateStaffReq, options?: RpcOptions): UnaryCall<DeactivateStaffReq, DeactivateStaffRes>;
    /**
     * @generated from protobuf rpc: ReactivateStaff(rd.api.company.ReactivateStaffReq) returns (rd.api.company.ReactivateStaffRes);
     */
    reactivateStaff(input: ReactivateStaffReq, options?: RpcOptions): UnaryCall<ReactivateStaffReq, ReactivateStaffRes>;
    /**
     * @generated from protobuf rpc: DeactivateStaffWebAuthnKeys(rd.api.company.DeactivateStaffWebAuthnKeysReq) returns (rd.api.company.DeactivateStaffWebAuthnKeysRes);
     */
    deactivateStaffWebAuthnKeys(input: DeactivateStaffWebAuthnKeysReq, options?: RpcOptions): UnaryCall<DeactivateStaffWebAuthnKeysReq, DeactivateStaffWebAuthnKeysRes>;
    /**
     * @generated from protobuf rpc: ListAuthorizedEndpoints(rd.api.company.ListAuthorizedEndpointsReq) returns (rd.api.company.ListAuthorizedEndpointsRes);
     */
    listAuthorizedEndpoints(input: ListAuthorizedEndpointsReq, options?: RpcOptions): UnaryCall<ListAuthorizedEndpointsReq, ListAuthorizedEndpointsRes>;
    /**
     * @generated from protobuf rpc: UpdateStaffPermissions(rd.api.company.UpdateStaffPermissionsReq) returns (rd.api.company.UpdateStaffPermissionsRes);
     */
    updateStaffPermissions(input: UpdateStaffPermissionsReq, options?: RpcOptions): UnaryCall<UpdateStaffPermissionsReq, UpdateStaffPermissionsRes>;
    /**
     * Company
     *
     * @generated from protobuf rpc: GetCompanyForSubdomain(rd.api.company.GetCompanyForSubdomainReq) returns (rd.api.company.GetCompanyForSubdomainRes);
     */
    getCompanyForSubdomain(input: GetCompanyForSubdomainReq, options?: RpcOptions): UnaryCall<GetCompanyForSubdomainReq, GetCompanyForSubdomainRes>;
    /**
     * @generated from protobuf rpc: GetCompany(rd.api.company.GetCompanyReq) returns (rd.api.company.GetCompanyRes);
     */
    getCompany(input: GetCompanyReq, options?: RpcOptions): UnaryCall<GetCompanyReq, GetCompanyRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyStatementSettings(rd.api.company.UpdateCompanyStatementSettingsReq) returns (rd.api.company.UpdateCompanyStatementSettingsRes);
     */
    updateCompanyStatementSettings(input: UpdateCompanyStatementSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyStatementSettingsReq, UpdateCompanyStatementSettingsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyGeneralSettings(rd.api.company.UpdateCompanyGeneralSettingsReq) returns (rd.api.company.UpdateCompanyGeneralSettingsRes);
     */
    updateCompanyGeneralSettings(input: UpdateCompanyGeneralSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyGeneralSettingsReq, UpdateCompanyGeneralSettingsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanySecuritySettings(rd.api.company.UpdateCompanySecuritySettingsReq) returns (rd.api.company.UpdateCompanySecuritySettingsRes);
     */
    updateCompanySecuritySettings(input: UpdateCompanySecuritySettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanySecuritySettingsReq, UpdateCompanySecuritySettingsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyGeneralCommunicationsSettings(rd.api.company.UpdateCompanyGeneralCommunicationsSettingsReq) returns (rd.api.company.UpdateCompanyGeneralCommunicationsSettingsRes);
     */
    updateCompanyGeneralCommunicationsSettings(input: UpdateCompanyGeneralCommunicationsSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyGeneralCommunicationsSettingsReq, UpdateCompanyGeneralCommunicationsSettingsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyShouldAutoSubmitContractorRewards(rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsReq) returns (rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsRes);
     */
    updateCompanyShouldAutoSubmitContractorRewards(input: UpdateCompanyShouldAutoSubmitContractorRewardsReq, options?: RpcOptions): UnaryCall<UpdateCompanyShouldAutoSubmitContractorRewardsReq, UpdateCompanyShouldAutoSubmitContractorRewardsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyContractorRewardsSettings(rd.api.company.UpdateCompanyContractorRewardsSettingsReq) returns (rd.api.company.UpdateCompanyContractorRewardsSettingsRes);
     */
    updateCompanyContractorRewardsSettings(input: UpdateCompanyContractorRewardsSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyContractorRewardsSettingsReq, UpdateCompanyContractorRewardsSettingsRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsDiscountShownToCustomers(rd.api.company.UpdateCompanyIsDiscountShownToCustomersReq) returns (rd.api.company.UpdateCompanyIsDiscountShownToCustomersRes);
     */
    updateCompanyIsDiscountShownToCustomers(input: UpdateCompanyIsDiscountShownToCustomersReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsDiscountShownToCustomersReq, UpdateCompanyIsDiscountShownToCustomersRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsPriceShownOnWillCall(rd.api.company.UpdateCompanyIsPriceShownOnWillCallReq) returns (rd.api.company.UpdateCompanyIsPriceShownOnWillCallRes);
     */
    updateCompanyIsPriceShownOnWillCall(input: UpdateCompanyIsPriceShownOnWillCallReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsPriceShownOnWillCallReq, UpdateCompanyIsPriceShownOnWillCallRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsColorFormulaHidden(rd.api.company.UpdateCompanyIsColorFormulaHiddenReq) returns (rd.api.company.UpdateCompanyIsColorFormulaHiddenRes);
     */
    updateCompanyIsColorFormulaHidden(input: UpdateCompanyIsColorFormulaHiddenReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsColorFormulaHiddenReq, UpdateCompanyIsColorFormulaHiddenRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsFinancingTermsShownOnInvoice(rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceReq) returns (rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceRes);
     */
    updateCompanyIsFinancingTermsShownOnInvoice(input: UpdateCompanyIsFinancingTermsShownOnInvoiceReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsFinancingTermsShownOnInvoiceReq, UpdateCompanyIsFinancingTermsShownOnInvoiceRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsCardSurchargeTaxExempt(rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptReq) returns (rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptRes);
     */
    updateCompanyIsCardSurchargeTaxExempt(input: UpdateCompanyIsCardSurchargeTaxExemptReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsCardSurchargeTaxExemptReq, UpdateCompanyIsCardSurchargeTaxExemptRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyIsLoyaltyProgramEnabled(rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledReq) returns (rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledRes);
     */
    updateCompanyIsLoyaltyProgramEnabled(input: UpdateCompanyIsLoyaltyProgramEnabledReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsLoyaltyProgramEnabledReq, UpdateCompanyIsLoyaltyProgramEnabledRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyLoyaltyProgramPolicy(rd.api.company.UpdateCompanyLoyaltyProgramPolicyReq) returns (rd.api.company.UpdateCompanyLoyaltyProgramPolicyRes);
     */
    updateCompanyLoyaltyProgramPolicy(input: UpdateCompanyLoyaltyProgramPolicyReq, options?: RpcOptions): UnaryCall<UpdateCompanyLoyaltyProgramPolicyReq, UpdateCompanyLoyaltyProgramPolicyRes>;
    /**
     * @generated from protobuf rpc: UpdateCompanyShouldAutoSignOutAfterSaleSettings(rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq) returns (rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes);
     */
    updateCompanyShouldAutoSignOutAfterSaleSettings(input: UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes>;
    /**
     * Tax Rates
     *
     * @generated from protobuf rpc: CreateCustomTaxRate(rd.api.company.CreateCustomTaxRateReq) returns (rd.api.company.CreateCustomTaxRateRes);
     */
    createCustomTaxRate(input: CreateCustomTaxRateReq, options?: RpcOptions): UnaryCall<CreateCustomTaxRateReq, CreateCustomTaxRateRes>;
    /**
     * @generated from protobuf rpc: UpdateCustomTaxRate(rd.api.company.UpdateCustomTaxRateReq) returns (rd.api.company.UpdateCustomTaxRateRes);
     */
    updateCustomTaxRate(input: UpdateCustomTaxRateReq, options?: RpcOptions): UnaryCall<UpdateCustomTaxRateReq, UpdateCustomTaxRateRes>;
    /**
     * @generated from protobuf rpc: ReorderCustomTaxRates(rd.api.company.ReorderCustomTaxRatesReq) returns (rd.api.company.ReorderCustomTaxRatesRes);
     */
    reorderCustomTaxRates(input: ReorderCustomTaxRatesReq, options?: RpcOptions): UnaryCall<ReorderCustomTaxRatesReq, ReorderCustomTaxRatesRes>;
    /**
     * @generated from protobuf rpc: DeactivateCustomTaxRate(rd.api.company.DeactivateCustomTaxRateReq) returns (rd.api.company.DeactivateCustomTaxRateRes);
     */
    deactivateCustomTaxRate(input: DeactivateCustomTaxRateReq, options?: RpcOptions): UnaryCall<DeactivateCustomTaxRateReq, DeactivateCustomTaxRateRes>;
    /**
     * @generated from protobuf rpc: ListTaxRatesForCompany(rd.api.company.ListTaxRatesForCompanyReq) returns (rd.api.company.ListTaxRatesForCompanyRes);
     */
    listTaxRatesForCompany(input: ListTaxRatesForCompanyReq, options?: RpcOptions): UnaryCall<ListTaxRatesForCompanyReq, ListTaxRatesForCompanyRes>;
    /**
     * @generated from protobuf rpc: GetTaxRate(rd.api.company.GetTaxRateReq) returns (rd.api.company.GetTaxRateRes);
     */
    getTaxRate(input: GetTaxRateReq, options?: RpcOptions): UnaryCall<GetTaxRateReq, GetTaxRateRes>;
    /**
     * Stripe
     *
     * @generated from protobuf rpc: GetStripeOnboardingURL(rd.api.company.GetStripeOnboardingURLReq) returns (rd.api.company.GetStripeOnboardingURLRes);
     */
    getStripeOnboardingURL(input: GetStripeOnboardingURLReq, options?: RpcOptions): UnaryCall<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes>;
    /**
     * @generated from protobuf rpc: GetStripeCustomOnboardingURL(rd.api.company.GetStripeOnboardingURLReq) returns (rd.api.company.GetStripeOnboardingURLRes);
     */
    getStripeCustomOnboardingURL(input: GetStripeOnboardingURLReq, options?: RpcOptions): UnaryCall<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes>;
    /**
     * @generated from protobuf rpc: GetStripeLoginURL(rd.api.company.GetStripeLoginURLReq) returns (rd.api.company.GetStripeLoginURLRes);
     */
    getStripeLoginURL(input: GetStripeLoginURLReq, options?: RpcOptions): UnaryCall<GetStripeLoginURLReq, GetStripeLoginURLRes>;
    /**
     * Additional Fees
     *
     * @generated from protobuf rpc: CreateAdditionalFee(rd.api.company.CreateAdditionalFeeReq) returns (rd.api.company.CreateAdditionalFeeRes);
     */
    createAdditionalFee(input: CreateAdditionalFeeReq, options?: RpcOptions): UnaryCall<CreateAdditionalFeeReq, CreateAdditionalFeeRes>;
    /**
     * @generated from protobuf rpc: UpdateAdditionalFee(rd.api.company.UpdateAdditionalFeeReq) returns (rd.api.company.UpdateAdditionalFeeRes);
     */
    updateAdditionalFee(input: UpdateAdditionalFeeReq, options?: RpcOptions): UnaryCall<UpdateAdditionalFeeReq, UpdateAdditionalFeeRes>;
    /**
     * @generated from protobuf rpc: DeleteAdditionalFee(rd.api.company.DeleteAdditionalFeeReq) returns (rd.api.company.DeleteAdditionalFeeRes);
     */
    deleteAdditionalFee(input: DeleteAdditionalFeeReq, options?: RpcOptions): UnaryCall<DeleteAdditionalFeeReq, DeleteAdditionalFeeRes>;
    /**
     * @generated from protobuf rpc: ReorderAdditionalFees(rd.api.company.ReorderAdditionalFeesReq) returns (rd.api.company.ReorderAdditionalFeesRes);
     */
    reorderAdditionalFees(input: ReorderAdditionalFeesReq, options?: RpcOptions): UnaryCall<ReorderAdditionalFeesReq, ReorderAdditionalFeesRes>;
    /**
     * @generated from protobuf rpc: ListAdditionalFeesForCompany(rd.api.company.ListAdditionalFeesForCompanyReq) returns (rd.api.company.ListAdditionalFeesForCompanyRes);
     */
    listAdditionalFeesForCompany(input: ListAdditionalFeesForCompanyReq, options?: RpcOptions): UnaryCall<ListAdditionalFeesForCompanyReq, ListAdditionalFeesForCompanyRes>;
    /**
     * @generated from protobuf rpc: ListProductsForFee(rd.api.company.ListProductsForFeeReq) returns (rd.api.company.ListProductsForFeeRes);
     */
    listProductsForFee(input: ListProductsForFeeReq, options?: RpcOptions): UnaryCall<ListProductsForFeeReq, ListProductsForFeeRes>;
    /**
     * Product Categories V2
     *
     * @generated from protobuf rpc: CreateProductCategoryV2(rd.api.company.CreateProductCategoryV2Req) returns (rd.api.company.CreateProductCategoryV2Res);
     */
    createProductCategoryV2(input: CreateProductCategoryV2Req, options?: RpcOptions): UnaryCall<CreateProductCategoryV2Req, CreateProductCategoryV2Res>;
    /**
     * @generated from protobuf rpc: UpdateProductCategoryV2(rd.api.company.UpdateProductCategoryV2Req) returns (rd.api.company.UpdateProductCategoryV2Res);
     */
    updateProductCategoryV2(input: UpdateProductCategoryV2Req, options?: RpcOptions): UnaryCall<UpdateProductCategoryV2Req, UpdateProductCategoryV2Res>;
    /**
     * @generated from protobuf rpc: ListProductCategoriesForCompanyV2(rd.api.company.ListProductCategoriesForCompanyV2Req) returns (rd.api.company.ListProductCategoriesForCompanyV2Res);
     */
    listProductCategoriesForCompanyV2(input: ListProductCategoriesForCompanyV2Req, options?: RpcOptions): UnaryCall<ListProductCategoriesForCompanyV2Req, ListProductCategoriesForCompanyV2Res>;
    /**
     * @generated from protobuf rpc: ListProductsForCategoryV2(rd.api.company.ListProductsForCategoryV2Req) returns (rd.api.company.ListProductsForCategoryV2Res);
     */
    listProductsForCategoryV2(input: ListProductsForCategoryV2Req, options?: RpcOptions): UnaryCall<ListProductsForCategoryV2Req, ListProductsForCategoryV2Res>;
    /**
     * @generated from protobuf rpc: DeactivateProductCategoryV2(rd.api.company.DeactivateProductCategoryV2Req) returns (rd.api.company.DeactivateProductCategoryV2Res);
     */
    deactivateProductCategoryV2(input: DeactivateProductCategoryV2Req, options?: RpcOptions): UnaryCall<DeactivateProductCategoryV2Req, DeactivateProductCategoryV2Res>;
    /**
     * FinancingTerms
     *
     * @generated from protobuf rpc: CreateFinancingTerm(rd.api.company.CreateFinancingTermReq) returns (rd.api.company.CreateFinancingTermRes);
     */
    createFinancingTerm(input: CreateFinancingTermReq, options?: RpcOptions): UnaryCall<CreateFinancingTermReq, CreateFinancingTermRes>;
    /**
     * @generated from protobuf rpc: UpdateFinancingTerm(rd.api.company.UpdateFinancingTermReq) returns (rd.api.company.UpdateFinancingTermRes);
     */
    updateFinancingTerm(input: UpdateFinancingTermReq, options?: RpcOptions): UnaryCall<UpdateFinancingTermReq, UpdateFinancingTermRes>;
    /**
     * @generated from protobuf rpc: DeleteFinancingTerm(rd.api.company.DeleteFinancingTermReq) returns (rd.api.company.DeleteFinancingTermRes);
     */
    deleteFinancingTerm(input: DeleteFinancingTermReq, options?: RpcOptions): UnaryCall<DeleteFinancingTermReq, DeleteFinancingTermRes>;
    /**
     * @generated from protobuf rpc: ReorderFinancingTerms(rd.api.company.ReorderFinancingTermsReq) returns (rd.api.company.ReorderFinancingTermsRes);
     */
    reorderFinancingTerms(input: ReorderFinancingTermsReq, options?: RpcOptions): UnaryCall<ReorderFinancingTermsReq, ReorderFinancingTermsRes>;
    /**
     * @generated from protobuf rpc: ListFinancingTermsForCompany(rd.api.company.ListFinancingTermsForCompanyReq) returns (rd.api.company.ListFinancingTermsForCompanyRes);
     */
    listFinancingTermsForCompany(input: ListFinancingTermsForCompanyReq, options?: RpcOptions): UnaryCall<ListFinancingTermsForCompanyReq, ListFinancingTermsForCompanyRes>;
    /**
     * @generated from protobuf rpc: ListCustomersForTerm(rd.api.company.ListCustomersForTermReq) returns (rd.api.company.ListCustomersForTermRes);
     */
    listCustomersForTerm(input: ListCustomersForTermReq, options?: RpcOptions): UnaryCall<ListCustomersForTermReq, ListCustomersForTermRes>;
    /**
     * Pricing Tiers
     *
     * @generated from protobuf rpc: CreatePricingTier(rd.api.company.CreatePricingTierReq) returns (rd.api.company.CreatePricingTierRes);
     */
    createPricingTier(input: CreatePricingTierReq, options?: RpcOptions): UnaryCall<CreatePricingTierReq, CreatePricingTierRes>;
    /**
     * @generated from protobuf rpc: UpdatePricingTier(rd.api.company.UpdatePricingTierReq) returns (rd.api.company.UpdatePricingTierRes);
     */
    updatePricingTier(input: UpdatePricingTierReq, options?: RpcOptions): UnaryCall<UpdatePricingTierReq, UpdatePricingTierRes>;
    /**
     * @generated from protobuf rpc: DeletePricingTier(rd.api.company.DeletePricingTierReq) returns (rd.api.company.DeletePricingTierRes);
     */
    deletePricingTier(input: DeletePricingTierReq, options?: RpcOptions): UnaryCall<DeletePricingTierReq, DeletePricingTierRes>;
    /**
     * @generated from protobuf rpc: ReorderPricingTiers(rd.api.company.ReorderPricingTiersReq) returns (rd.api.company.ReorderPricingTiersRes);
     */
    reorderPricingTiers(input: ReorderPricingTiersReq, options?: RpcOptions): UnaryCall<ReorderPricingTiersReq, ReorderPricingTiersRes>;
    /**
     * @generated from protobuf rpc: ListPricingTiersForCompany(rd.api.company.ListPricingTiersForCompanyReq) returns (rd.api.company.ListPricingTiersForCompanyRes);
     */
    listPricingTiersForCompany(input: ListPricingTiersForCompanyReq, options?: RpcOptions): UnaryCall<ListPricingTiersForCompanyReq, ListPricingTiersForCompanyRes>;
    /**
     * @generated from protobuf rpc: ListCustomersForTier(rd.api.company.ListCustomersForTierReq) returns (rd.api.company.ListCustomersForTierRes);
     */
    listCustomersForTier(input: ListCustomersForTierReq, options?: RpcOptions): UnaryCall<ListCustomersForTierReq, ListCustomersForTierRes>;
    /**
     * User
     *
     * @generated from protobuf rpc: GetCurrentUser(rd.api.company.GetCurrentUserReq) returns (rd.api.company.GetCurrentUserRes);
     */
    getCurrentUser(input: GetCurrentUserReq, options?: RpcOptions): UnaryCall<GetCurrentUserReq, GetCurrentUserRes>;
    /**
     * Credit Card
     *
     * @generated from protobuf rpc: UpdateCardSurchargeRate(rd.api.company.UpdateCardSurchargeRateReq) returns (rd.api.company.UpdateCardSurchargeRateRes);
     */
    updateCardSurchargeRate(input: UpdateCardSurchargeRateReq, options?: RpcOptions): UnaryCall<UpdateCardSurchargeRateReq, UpdateCardSurchargeRateRes>;
    /**
     * Roles
     *
     * @generated from protobuf rpc: GetPermissionsGroups(rd.api.company.GetPermissionsGroupsReq) returns (rd.api.company.GetPermissionsGroupsRes);
     */
    getPermissionsGroups(input: GetPermissionsGroupsReq, options?: RpcOptions): UnaryCall<GetPermissionsGroupsReq, GetPermissionsGroupsRes>;
    /**
     * Finance Charge Settings
     *
     * @generated from protobuf rpc: CreateFinanceChargeSetting(rd.api.company.CreateFinanceChargeSettingReq) returns (rd.api.company.CreateFinanceChargeSettingRes);
     */
    createFinanceChargeSetting(input: CreateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<CreateFinanceChargeSettingReq, CreateFinanceChargeSettingRes>;
    /**
     * @generated from protobuf rpc: UpdateFinanceChargeSetting(rd.api.company.UpdateFinanceChargeSettingReq) returns (rd.api.company.UpdateFinanceChargeSettingRes);
     */
    updateFinanceChargeSetting(input: UpdateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<UpdateFinanceChargeSettingReq, UpdateFinanceChargeSettingRes>;
    /**
     * @generated from protobuf rpc: ListActiveFinanceChargeSettingsForCompany(rd.api.company.ListActiveFinanceChargeSettingsForCompanyReq) returns (rd.api.company.ListActiveFinanceChargeSettingsForCompanyRes);
     */
    listActiveFinanceChargeSettingsForCompany(input: ListActiveFinanceChargeSettingsForCompanyReq, options?: RpcOptions): UnaryCall<ListActiveFinanceChargeSettingsForCompanyReq, ListActiveFinanceChargeSettingsForCompanyRes>;
    /**
     * @generated from protobuf rpc: ListCustomersForFinanceChargeSetting(rd.api.company.ListCustomersForFinanceChargeSettingReq) returns (rd.api.company.ListCustomersForFinanceChargeSettingRes);
     */
    listCustomersForFinanceChargeSetting(input: ListCustomersForFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<ListCustomersForFinanceChargeSettingReq, ListCustomersForFinanceChargeSettingRes>;
    /**
     * @generated from protobuf rpc: DeactivateFinanceChargeSetting(rd.api.company.DeactivateFinanceChargeSettingReq) returns (rd.api.company.DeactivateFinanceChargeSettingRes);
     */
    deactivateFinanceChargeSetting(input: DeactivateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<DeactivateFinanceChargeSettingReq, DeactivateFinanceChargeSettingRes>;
    /**
     * @generated from protobuf rpc: ReorderFinanceChargeSettings(rd.api.company.ReorderFinanceChargeSettingsReq) returns (rd.api.company.ReorderFinanceChargeSettingsRes);
     */
    reorderFinanceChargeSettings(input: ReorderFinanceChargeSettingsReq, options?: RpcOptions): UnaryCall<ReorderFinanceChargeSettingsReq, ReorderFinanceChargeSettingsRes>;
}
/**
 * @generated from protobuf service rd.api.company.CompanyService
 */
export class CompanyServiceClient implements ICompanyServiceClient, ServiceInfo {
    typeName = CompanyService.typeName;
    methods = CompanyService.methods;
    options = CompanyService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Location
     *
     * @generated from protobuf rpc: GetLocation(rd.api.company.GetLocationReq) returns (rd.api.company.GetLocationRes);
     */
    getLocation(input: GetLocationReq, options?: RpcOptions): UnaryCall<GetLocationReq, GetLocationRes> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetLocationReq, GetLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLocation(rd.api.company.CreateLocationReq) returns (rd.api.company.CreateLocationRes);
     */
    createLocation(input: CreateLocationReq, options?: RpcOptions): UnaryCall<CreateLocationReq, CreateLocationRes> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateLocationReq, CreateLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocation(rd.api.company.UpdateLocationReq) returns (rd.api.company.UpdateLocationRes);
     */
    updateLocation(input: UpdateLocationReq, options?: RpcOptions): UnaryCall<UpdateLocationReq, UpdateLocationRes> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationReq, UpdateLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListLocationsForCompany(rd.api.company.ListLocationsForCompanyReq) returns (rd.api.company.ListLocationsForCompanyRes);
     */
    listLocationsForCompany(input: ListLocationsForCompanyReq, options?: RpcOptions): UnaryCall<ListLocationsForCompanyReq, ListLocationsForCompanyRes> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListLocationsForCompanyReq, ListLocationsForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RegisterCardReaderForLocation(rd.api.company.RegisterCardReaderForLocationReq) returns (rd.api.company.RegisterCardReaderForLocationRes);
     */
    registerCardReaderForLocation(input: RegisterCardReaderForLocationReq, options?: RpcOptions): UnaryCall<RegisterCardReaderForLocationReq, RegisterCardReaderForLocationRes> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<RegisterCardReaderForLocationReq, RegisterCardReaderForLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCardReadersForLocation(rd.api.company.ListCardReadersForLocationReq) returns (rd.api.company.ListCardReadersForLocationRes);
     */
    listCardReadersForLocation(input: ListCardReadersForLocationReq, options?: RpcOptions): UnaryCall<ListCardReadersForLocationReq, ListCardReadersForLocationRes> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCardReadersForLocationReq, ListCardReadersForLocationRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ClearCardReader(rd.api.company.ClearCardReaderReq) returns (rd.api.company.ClearCardReaderRes);
     */
    clearCardReader(input: ClearCardReaderReq, options?: RpcOptions): UnaryCall<ClearCardReaderReq, ClearCardReaderRes> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ClearCardReaderReq, ClearCardReaderRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationGoogleReviewUrl(rd.api.company.UpdateLocationGoogleReviewUrlReq) returns (rd.api.company.UpdateLocationGoogleReviewUrlRes);
     */
    updateLocationGoogleReviewUrl(input: UpdateLocationGoogleReviewUrlReq, options?: RpcOptions): UnaryCall<UpdateLocationGoogleReviewUrlReq, UpdateLocationGoogleReviewUrlRes> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationGoogleReviewUrlReq, UpdateLocationGoogleReviewUrlRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationBMOutletNumber(rd.api.company.UpdateLocationBMOutletNumberReq) returns (rd.api.company.UpdateLocationBMOutletNumberRes);
     */
    updateLocationBMOutletNumber(input: UpdateLocationBMOutletNumberReq, options?: RpcOptions): UnaryCall<UpdateLocationBMOutletNumberReq, UpdateLocationBMOutletNumberRes> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationBMOutletNumberReq, UpdateLocationBMOutletNumberRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationCashFloat(rd.api.company.UpdateLocationCashFloatReq) returns (rd.api.company.UpdateLocationCashFloatRes);
     */
    updateLocationCashFloat(input: UpdateLocationCashFloatReq, options?: RpcOptions): UnaryCall<UpdateLocationCashFloatReq, UpdateLocationCashFloatRes> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationCashFloatReq, UpdateLocationCashFloatRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationLogoImageUrl(rd.api.company.UpdateLocationLogoImageUrlReq) returns (rd.api.company.UpdateLocationLogoImageUrlRes);
     */
    updateLocationLogoImageUrl(input: UpdateLocationLogoImageUrlReq, options?: RpcOptions): UnaryCall<UpdateLocationLogoImageUrlReq, UpdateLocationLogoImageUrlRes> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationLogoImageUrlReq, UpdateLocationLogoImageUrlRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLocationQuickbooksClass(rd.api.company.UpdateLocationQuickbooksClassReq) returns (rd.api.company.UpdateLocationQuickbooksClassRes);
     */
    updateLocationQuickbooksClass(input: UpdateLocationQuickbooksClassReq, options?: RpcOptions): UnaryCall<UpdateLocationQuickbooksClassReq, UpdateLocationQuickbooksClassRes> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateLocationQuickbooksClassReq, UpdateLocationQuickbooksClassRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Staff
     *
     * @generated from protobuf rpc: GetStaff(rd.api.company.GetStaffReq) returns (rd.api.company.GetStaffRes);
     */
    getStaff(input: GetStaffReq, options?: RpcOptions): UnaryCall<GetStaffReq, GetStaffRes> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStaffReq, GetStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateStaff(rd.api.company.CreateStaffReq) returns (rd.api.company.CreateStaffRes);
     */
    createStaff(input: CreateStaffReq, options?: RpcOptions): UnaryCall<CreateStaffReq, CreateStaffRes> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateStaffReq, CreateStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStaff(rd.api.company.UpdateStaffReq) returns (rd.api.company.UpdateStaffRes);
     */
    updateStaff(input: UpdateStaffReq, options?: RpcOptions): UnaryCall<UpdateStaffReq, UpdateStaffRes> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateStaffReq, UpdateStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListStaffsForCompany(rd.api.company.ListStaffsForCompanyReq) returns (rd.api.company.ListStaffsForCompanyRes);
     */
    listStaffsForCompany(input: ListStaffsForCompanyReq, options?: RpcOptions): UnaryCall<ListStaffsForCompanyReq, ListStaffsForCompanyRes> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListStaffsForCompanyReq, ListStaffsForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateStaff(rd.api.company.DeactivateStaffReq) returns (rd.api.company.DeactivateStaffRes);
     */
    deactivateStaff(input: DeactivateStaffReq, options?: RpcOptions): UnaryCall<DeactivateStaffReq, DeactivateStaffRes> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateStaffReq, DeactivateStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReactivateStaff(rd.api.company.ReactivateStaffReq) returns (rd.api.company.ReactivateStaffRes);
     */
    reactivateStaff(input: ReactivateStaffReq, options?: RpcOptions): UnaryCall<ReactivateStaffReq, ReactivateStaffRes> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReactivateStaffReq, ReactivateStaffRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateStaffWebAuthnKeys(rd.api.company.DeactivateStaffWebAuthnKeysReq) returns (rd.api.company.DeactivateStaffWebAuthnKeysRes);
     */
    deactivateStaffWebAuthnKeys(input: DeactivateStaffWebAuthnKeysReq, options?: RpcOptions): UnaryCall<DeactivateStaffWebAuthnKeysReq, DeactivateStaffWebAuthnKeysRes> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateStaffWebAuthnKeysReq, DeactivateStaffWebAuthnKeysRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAuthorizedEndpoints(rd.api.company.ListAuthorizedEndpointsReq) returns (rd.api.company.ListAuthorizedEndpointsRes);
     */
    listAuthorizedEndpoints(input: ListAuthorizedEndpointsReq, options?: RpcOptions): UnaryCall<ListAuthorizedEndpointsReq, ListAuthorizedEndpointsRes> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAuthorizedEndpointsReq, ListAuthorizedEndpointsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStaffPermissions(rd.api.company.UpdateStaffPermissionsReq) returns (rd.api.company.UpdateStaffPermissionsRes);
     */
    updateStaffPermissions(input: UpdateStaffPermissionsReq, options?: RpcOptions): UnaryCall<UpdateStaffPermissionsReq, UpdateStaffPermissionsRes> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateStaffPermissionsReq, UpdateStaffPermissionsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Company
     *
     * @generated from protobuf rpc: GetCompanyForSubdomain(rd.api.company.GetCompanyForSubdomainReq) returns (rd.api.company.GetCompanyForSubdomainRes);
     */
    getCompanyForSubdomain(input: GetCompanyForSubdomainReq, options?: RpcOptions): UnaryCall<GetCompanyForSubdomainReq, GetCompanyForSubdomainRes> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCompanyForSubdomainReq, GetCompanyForSubdomainRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCompany(rd.api.company.GetCompanyReq) returns (rd.api.company.GetCompanyRes);
     */
    getCompany(input: GetCompanyReq, options?: RpcOptions): UnaryCall<GetCompanyReq, GetCompanyRes> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCompanyReq, GetCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyStatementSettings(rd.api.company.UpdateCompanyStatementSettingsReq) returns (rd.api.company.UpdateCompanyStatementSettingsRes);
     */
    updateCompanyStatementSettings(input: UpdateCompanyStatementSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyStatementSettingsReq, UpdateCompanyStatementSettingsRes> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyStatementSettingsReq, UpdateCompanyStatementSettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyGeneralSettings(rd.api.company.UpdateCompanyGeneralSettingsReq) returns (rd.api.company.UpdateCompanyGeneralSettingsRes);
     */
    updateCompanyGeneralSettings(input: UpdateCompanyGeneralSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyGeneralSettingsReq, UpdateCompanyGeneralSettingsRes> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyGeneralSettingsReq, UpdateCompanyGeneralSettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanySecuritySettings(rd.api.company.UpdateCompanySecuritySettingsReq) returns (rd.api.company.UpdateCompanySecuritySettingsRes);
     */
    updateCompanySecuritySettings(input: UpdateCompanySecuritySettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanySecuritySettingsReq, UpdateCompanySecuritySettingsRes> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanySecuritySettingsReq, UpdateCompanySecuritySettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyGeneralCommunicationsSettings(rd.api.company.UpdateCompanyGeneralCommunicationsSettingsReq) returns (rd.api.company.UpdateCompanyGeneralCommunicationsSettingsRes);
     */
    updateCompanyGeneralCommunicationsSettings(input: UpdateCompanyGeneralCommunicationsSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyGeneralCommunicationsSettingsReq, UpdateCompanyGeneralCommunicationsSettingsRes> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyGeneralCommunicationsSettingsReq, UpdateCompanyGeneralCommunicationsSettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyShouldAutoSubmitContractorRewards(rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsReq) returns (rd.api.company.UpdateCompanyShouldAutoSubmitContractorRewardsRes);
     */
    updateCompanyShouldAutoSubmitContractorRewards(input: UpdateCompanyShouldAutoSubmitContractorRewardsReq, options?: RpcOptions): UnaryCall<UpdateCompanyShouldAutoSubmitContractorRewardsReq, UpdateCompanyShouldAutoSubmitContractorRewardsRes> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyShouldAutoSubmitContractorRewardsReq, UpdateCompanyShouldAutoSubmitContractorRewardsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyContractorRewardsSettings(rd.api.company.UpdateCompanyContractorRewardsSettingsReq) returns (rd.api.company.UpdateCompanyContractorRewardsSettingsRes);
     */
    updateCompanyContractorRewardsSettings(input: UpdateCompanyContractorRewardsSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyContractorRewardsSettingsReq, UpdateCompanyContractorRewardsSettingsRes> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyContractorRewardsSettingsReq, UpdateCompanyContractorRewardsSettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsDiscountShownToCustomers(rd.api.company.UpdateCompanyIsDiscountShownToCustomersReq) returns (rd.api.company.UpdateCompanyIsDiscountShownToCustomersRes);
     */
    updateCompanyIsDiscountShownToCustomers(input: UpdateCompanyIsDiscountShownToCustomersReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsDiscountShownToCustomersReq, UpdateCompanyIsDiscountShownToCustomersRes> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsDiscountShownToCustomersReq, UpdateCompanyIsDiscountShownToCustomersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsPriceShownOnWillCall(rd.api.company.UpdateCompanyIsPriceShownOnWillCallReq) returns (rd.api.company.UpdateCompanyIsPriceShownOnWillCallRes);
     */
    updateCompanyIsPriceShownOnWillCall(input: UpdateCompanyIsPriceShownOnWillCallReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsPriceShownOnWillCallReq, UpdateCompanyIsPriceShownOnWillCallRes> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsPriceShownOnWillCallReq, UpdateCompanyIsPriceShownOnWillCallRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsColorFormulaHidden(rd.api.company.UpdateCompanyIsColorFormulaHiddenReq) returns (rd.api.company.UpdateCompanyIsColorFormulaHiddenRes);
     */
    updateCompanyIsColorFormulaHidden(input: UpdateCompanyIsColorFormulaHiddenReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsColorFormulaHiddenReq, UpdateCompanyIsColorFormulaHiddenRes> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsColorFormulaHiddenReq, UpdateCompanyIsColorFormulaHiddenRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsFinancingTermsShownOnInvoice(rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceReq) returns (rd.api.company.UpdateCompanyIsFinancingTermsShownOnInvoiceRes);
     */
    updateCompanyIsFinancingTermsShownOnInvoice(input: UpdateCompanyIsFinancingTermsShownOnInvoiceReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsFinancingTermsShownOnInvoiceReq, UpdateCompanyIsFinancingTermsShownOnInvoiceRes> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsFinancingTermsShownOnInvoiceReq, UpdateCompanyIsFinancingTermsShownOnInvoiceRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsCardSurchargeTaxExempt(rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptReq) returns (rd.api.company.UpdateCompanyIsCardSurchargeTaxExemptRes);
     */
    updateCompanyIsCardSurchargeTaxExempt(input: UpdateCompanyIsCardSurchargeTaxExemptReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsCardSurchargeTaxExemptReq, UpdateCompanyIsCardSurchargeTaxExemptRes> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsCardSurchargeTaxExemptReq, UpdateCompanyIsCardSurchargeTaxExemptRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyIsLoyaltyProgramEnabled(rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledReq) returns (rd.api.company.UpdateCompanyIsLoyaltyProgramEnabledRes);
     */
    updateCompanyIsLoyaltyProgramEnabled(input: UpdateCompanyIsLoyaltyProgramEnabledReq, options?: RpcOptions): UnaryCall<UpdateCompanyIsLoyaltyProgramEnabledReq, UpdateCompanyIsLoyaltyProgramEnabledRes> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyIsLoyaltyProgramEnabledReq, UpdateCompanyIsLoyaltyProgramEnabledRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyLoyaltyProgramPolicy(rd.api.company.UpdateCompanyLoyaltyProgramPolicyReq) returns (rd.api.company.UpdateCompanyLoyaltyProgramPolicyRes);
     */
    updateCompanyLoyaltyProgramPolicy(input: UpdateCompanyLoyaltyProgramPolicyReq, options?: RpcOptions): UnaryCall<UpdateCompanyLoyaltyProgramPolicyReq, UpdateCompanyLoyaltyProgramPolicyRes> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyLoyaltyProgramPolicyReq, UpdateCompanyLoyaltyProgramPolicyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCompanyShouldAutoSignOutAfterSaleSettings(rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq) returns (rd.api.company.UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes);
     */
    updateCompanyShouldAutoSignOutAfterSaleSettings(input: UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, options?: RpcOptions): UnaryCall<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq, UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Tax Rates
     *
     * @generated from protobuf rpc: CreateCustomTaxRate(rd.api.company.CreateCustomTaxRateReq) returns (rd.api.company.CreateCustomTaxRateRes);
     */
    createCustomTaxRate(input: CreateCustomTaxRateReq, options?: RpcOptions): UnaryCall<CreateCustomTaxRateReq, CreateCustomTaxRateRes> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateCustomTaxRateReq, CreateCustomTaxRateRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCustomTaxRate(rd.api.company.UpdateCustomTaxRateReq) returns (rd.api.company.UpdateCustomTaxRateRes);
     */
    updateCustomTaxRate(input: UpdateCustomTaxRateReq, options?: RpcOptions): UnaryCall<UpdateCustomTaxRateReq, UpdateCustomTaxRateRes> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCustomTaxRateReq, UpdateCustomTaxRateRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderCustomTaxRates(rd.api.company.ReorderCustomTaxRatesReq) returns (rd.api.company.ReorderCustomTaxRatesRes);
     */
    reorderCustomTaxRates(input: ReorderCustomTaxRatesReq, options?: RpcOptions): UnaryCall<ReorderCustomTaxRatesReq, ReorderCustomTaxRatesRes> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderCustomTaxRatesReq, ReorderCustomTaxRatesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateCustomTaxRate(rd.api.company.DeactivateCustomTaxRateReq) returns (rd.api.company.DeactivateCustomTaxRateRes);
     */
    deactivateCustomTaxRate(input: DeactivateCustomTaxRateReq, options?: RpcOptions): UnaryCall<DeactivateCustomTaxRateReq, DeactivateCustomTaxRateRes> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateCustomTaxRateReq, DeactivateCustomTaxRateRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListTaxRatesForCompany(rd.api.company.ListTaxRatesForCompanyReq) returns (rd.api.company.ListTaxRatesForCompanyRes);
     */
    listTaxRatesForCompany(input: ListTaxRatesForCompanyReq, options?: RpcOptions): UnaryCall<ListTaxRatesForCompanyReq, ListTaxRatesForCompanyRes> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListTaxRatesForCompanyReq, ListTaxRatesForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaxRate(rd.api.company.GetTaxRateReq) returns (rd.api.company.GetTaxRateRes);
     */
    getTaxRate(input: GetTaxRateReq, options?: RpcOptions): UnaryCall<GetTaxRateReq, GetTaxRateRes> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTaxRateReq, GetTaxRateRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Stripe
     *
     * @generated from protobuf rpc: GetStripeOnboardingURL(rd.api.company.GetStripeOnboardingURLReq) returns (rd.api.company.GetStripeOnboardingURLRes);
     */
    getStripeOnboardingURL(input: GetStripeOnboardingURLReq, options?: RpcOptions): UnaryCall<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStripeCustomOnboardingURL(rd.api.company.GetStripeOnboardingURLReq) returns (rd.api.company.GetStripeOnboardingURLRes);
     */
    getStripeCustomOnboardingURL(input: GetStripeOnboardingURLReq, options?: RpcOptions): UnaryCall<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStripeOnboardingURLReq, GetStripeOnboardingURLRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStripeLoginURL(rd.api.company.GetStripeLoginURLReq) returns (rd.api.company.GetStripeLoginURLRes);
     */
    getStripeLoginURL(input: GetStripeLoginURLReq, options?: RpcOptions): UnaryCall<GetStripeLoginURLReq, GetStripeLoginURLRes> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStripeLoginURLReq, GetStripeLoginURLRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Additional Fees
     *
     * @generated from protobuf rpc: CreateAdditionalFee(rd.api.company.CreateAdditionalFeeReq) returns (rd.api.company.CreateAdditionalFeeRes);
     */
    createAdditionalFee(input: CreateAdditionalFeeReq, options?: RpcOptions): UnaryCall<CreateAdditionalFeeReq, CreateAdditionalFeeRes> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAdditionalFeeReq, CreateAdditionalFeeRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateAdditionalFee(rd.api.company.UpdateAdditionalFeeReq) returns (rd.api.company.UpdateAdditionalFeeRes);
     */
    updateAdditionalFee(input: UpdateAdditionalFeeReq, options?: RpcOptions): UnaryCall<UpdateAdditionalFeeReq, UpdateAdditionalFeeRes> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateAdditionalFeeReq, UpdateAdditionalFeeRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAdditionalFee(rd.api.company.DeleteAdditionalFeeReq) returns (rd.api.company.DeleteAdditionalFeeRes);
     */
    deleteAdditionalFee(input: DeleteAdditionalFeeReq, options?: RpcOptions): UnaryCall<DeleteAdditionalFeeReq, DeleteAdditionalFeeRes> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteAdditionalFeeReq, DeleteAdditionalFeeRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderAdditionalFees(rd.api.company.ReorderAdditionalFeesReq) returns (rd.api.company.ReorderAdditionalFeesRes);
     */
    reorderAdditionalFees(input: ReorderAdditionalFeesReq, options?: RpcOptions): UnaryCall<ReorderAdditionalFeesReq, ReorderAdditionalFeesRes> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderAdditionalFeesReq, ReorderAdditionalFeesRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAdditionalFeesForCompany(rd.api.company.ListAdditionalFeesForCompanyReq) returns (rd.api.company.ListAdditionalFeesForCompanyRes);
     */
    listAdditionalFeesForCompany(input: ListAdditionalFeesForCompanyReq, options?: RpcOptions): UnaryCall<ListAdditionalFeesForCompanyReq, ListAdditionalFeesForCompanyRes> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAdditionalFeesForCompanyReq, ListAdditionalFeesForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListProductsForFee(rd.api.company.ListProductsForFeeReq) returns (rd.api.company.ListProductsForFeeRes);
     */
    listProductsForFee(input: ListProductsForFeeReq, options?: RpcOptions): UnaryCall<ListProductsForFeeReq, ListProductsForFeeRes> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListProductsForFeeReq, ListProductsForFeeRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Product Categories V2
     *
     * @generated from protobuf rpc: CreateProductCategoryV2(rd.api.company.CreateProductCategoryV2Req) returns (rd.api.company.CreateProductCategoryV2Res);
     */
    createProductCategoryV2(input: CreateProductCategoryV2Req, options?: RpcOptions): UnaryCall<CreateProductCategoryV2Req, CreateProductCategoryV2Res> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateProductCategoryV2Req, CreateProductCategoryV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProductCategoryV2(rd.api.company.UpdateProductCategoryV2Req) returns (rd.api.company.UpdateProductCategoryV2Res);
     */
    updateProductCategoryV2(input: UpdateProductCategoryV2Req, options?: RpcOptions): UnaryCall<UpdateProductCategoryV2Req, UpdateProductCategoryV2Res> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateProductCategoryV2Req, UpdateProductCategoryV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListProductCategoriesForCompanyV2(rd.api.company.ListProductCategoriesForCompanyV2Req) returns (rd.api.company.ListProductCategoriesForCompanyV2Res);
     */
    listProductCategoriesForCompanyV2(input: ListProductCategoriesForCompanyV2Req, options?: RpcOptions): UnaryCall<ListProductCategoriesForCompanyV2Req, ListProductCategoriesForCompanyV2Res> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListProductCategoriesForCompanyV2Req, ListProductCategoriesForCompanyV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListProductsForCategoryV2(rd.api.company.ListProductsForCategoryV2Req) returns (rd.api.company.ListProductsForCategoryV2Res);
     */
    listProductsForCategoryV2(input: ListProductsForCategoryV2Req, options?: RpcOptions): UnaryCall<ListProductsForCategoryV2Req, ListProductsForCategoryV2Res> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListProductsForCategoryV2Req, ListProductsForCategoryV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateProductCategoryV2(rd.api.company.DeactivateProductCategoryV2Req) returns (rd.api.company.DeactivateProductCategoryV2Res);
     */
    deactivateProductCategoryV2(input: DeactivateProductCategoryV2Req, options?: RpcOptions): UnaryCall<DeactivateProductCategoryV2Req, DeactivateProductCategoryV2Res> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateProductCategoryV2Req, DeactivateProductCategoryV2Res>("unary", this._transport, method, opt, input);
    }
    /**
     * FinancingTerms
     *
     * @generated from protobuf rpc: CreateFinancingTerm(rd.api.company.CreateFinancingTermReq) returns (rd.api.company.CreateFinancingTermRes);
     */
    createFinancingTerm(input: CreateFinancingTermReq, options?: RpcOptions): UnaryCall<CreateFinancingTermReq, CreateFinancingTermRes> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateFinancingTermReq, CreateFinancingTermRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateFinancingTerm(rd.api.company.UpdateFinancingTermReq) returns (rd.api.company.UpdateFinancingTermRes);
     */
    updateFinancingTerm(input: UpdateFinancingTermReq, options?: RpcOptions): UnaryCall<UpdateFinancingTermReq, UpdateFinancingTermRes> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateFinancingTermReq, UpdateFinancingTermRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteFinancingTerm(rd.api.company.DeleteFinancingTermReq) returns (rd.api.company.DeleteFinancingTermRes);
     */
    deleteFinancingTerm(input: DeleteFinancingTermReq, options?: RpcOptions): UnaryCall<DeleteFinancingTermReq, DeleteFinancingTermRes> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteFinancingTermReq, DeleteFinancingTermRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderFinancingTerms(rd.api.company.ReorderFinancingTermsReq) returns (rd.api.company.ReorderFinancingTermsRes);
     */
    reorderFinancingTerms(input: ReorderFinancingTermsReq, options?: RpcOptions): UnaryCall<ReorderFinancingTermsReq, ReorderFinancingTermsRes> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderFinancingTermsReq, ReorderFinancingTermsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListFinancingTermsForCompany(rd.api.company.ListFinancingTermsForCompanyReq) returns (rd.api.company.ListFinancingTermsForCompanyRes);
     */
    listFinancingTermsForCompany(input: ListFinancingTermsForCompanyReq, options?: RpcOptions): UnaryCall<ListFinancingTermsForCompanyReq, ListFinancingTermsForCompanyRes> {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListFinancingTermsForCompanyReq, ListFinancingTermsForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCustomersForTerm(rd.api.company.ListCustomersForTermReq) returns (rd.api.company.ListCustomersForTermRes);
     */
    listCustomersForTerm(input: ListCustomersForTermReq, options?: RpcOptions): UnaryCall<ListCustomersForTermReq, ListCustomersForTermRes> {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomersForTermReq, ListCustomersForTermRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Pricing Tiers
     *
     * @generated from protobuf rpc: CreatePricingTier(rd.api.company.CreatePricingTierReq) returns (rd.api.company.CreatePricingTierRes);
     */
    createPricingTier(input: CreatePricingTierReq, options?: RpcOptions): UnaryCall<CreatePricingTierReq, CreatePricingTierRes> {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreatePricingTierReq, CreatePricingTierRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePricingTier(rd.api.company.UpdatePricingTierReq) returns (rd.api.company.UpdatePricingTierRes);
     */
    updatePricingTier(input: UpdatePricingTierReq, options?: RpcOptions): UnaryCall<UpdatePricingTierReq, UpdatePricingTierRes> {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePricingTierReq, UpdatePricingTierRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePricingTier(rd.api.company.DeletePricingTierReq) returns (rd.api.company.DeletePricingTierRes);
     */
    deletePricingTier(input: DeletePricingTierReq, options?: RpcOptions): UnaryCall<DeletePricingTierReq, DeletePricingTierRes> {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeletePricingTierReq, DeletePricingTierRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderPricingTiers(rd.api.company.ReorderPricingTiersReq) returns (rd.api.company.ReorderPricingTiersRes);
     */
    reorderPricingTiers(input: ReorderPricingTiersReq, options?: RpcOptions): UnaryCall<ReorderPricingTiersReq, ReorderPricingTiersRes> {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderPricingTiersReq, ReorderPricingTiersRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListPricingTiersForCompany(rd.api.company.ListPricingTiersForCompanyReq) returns (rd.api.company.ListPricingTiersForCompanyRes);
     */
    listPricingTiersForCompany(input: ListPricingTiersForCompanyReq, options?: RpcOptions): UnaryCall<ListPricingTiersForCompanyReq, ListPricingTiersForCompanyRes> {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPricingTiersForCompanyReq, ListPricingTiersForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCustomersForTier(rd.api.company.ListCustomersForTierReq) returns (rd.api.company.ListCustomersForTierRes);
     */
    listCustomersForTier(input: ListCustomersForTierReq, options?: RpcOptions): UnaryCall<ListCustomersForTierReq, ListCustomersForTierRes> {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomersForTierReq, ListCustomersForTierRes>("unary", this._transport, method, opt, input);
    }
    /**
     * User
     *
     * @generated from protobuf rpc: GetCurrentUser(rd.api.company.GetCurrentUserReq) returns (rd.api.company.GetCurrentUserRes);
     */
    getCurrentUser(input: GetCurrentUserReq, options?: RpcOptions): UnaryCall<GetCurrentUserReq, GetCurrentUserRes> {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetCurrentUserReq, GetCurrentUserRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Credit Card
     *
     * @generated from protobuf rpc: UpdateCardSurchargeRate(rd.api.company.UpdateCardSurchargeRateReq) returns (rd.api.company.UpdateCardSurchargeRateRes);
     */
    updateCardSurchargeRate(input: UpdateCardSurchargeRateReq, options?: RpcOptions): UnaryCall<UpdateCardSurchargeRateReq, UpdateCardSurchargeRateRes> {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateCardSurchargeRateReq, UpdateCardSurchargeRateRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Roles
     *
     * @generated from protobuf rpc: GetPermissionsGroups(rd.api.company.GetPermissionsGroupsReq) returns (rd.api.company.GetPermissionsGroupsRes);
     */
    getPermissionsGroups(input: GetPermissionsGroupsReq, options?: RpcOptions): UnaryCall<GetPermissionsGroupsReq, GetPermissionsGroupsRes> {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPermissionsGroupsReq, GetPermissionsGroupsRes>("unary", this._transport, method, opt, input);
    }
    /**
     * Finance Charge Settings
     *
     * @generated from protobuf rpc: CreateFinanceChargeSetting(rd.api.company.CreateFinanceChargeSettingReq) returns (rd.api.company.CreateFinanceChargeSettingRes);
     */
    createFinanceChargeSetting(input: CreateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<CreateFinanceChargeSettingReq, CreateFinanceChargeSettingRes> {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateFinanceChargeSettingReq, CreateFinanceChargeSettingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateFinanceChargeSetting(rd.api.company.UpdateFinanceChargeSettingReq) returns (rd.api.company.UpdateFinanceChargeSettingRes);
     */
    updateFinanceChargeSetting(input: UpdateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<UpdateFinanceChargeSettingReq, UpdateFinanceChargeSettingRes> {
        const method = this.methods[73], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateFinanceChargeSettingReq, UpdateFinanceChargeSettingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListActiveFinanceChargeSettingsForCompany(rd.api.company.ListActiveFinanceChargeSettingsForCompanyReq) returns (rd.api.company.ListActiveFinanceChargeSettingsForCompanyRes);
     */
    listActiveFinanceChargeSettingsForCompany(input: ListActiveFinanceChargeSettingsForCompanyReq, options?: RpcOptions): UnaryCall<ListActiveFinanceChargeSettingsForCompanyReq, ListActiveFinanceChargeSettingsForCompanyRes> {
        const method = this.methods[74], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListActiveFinanceChargeSettingsForCompanyReq, ListActiveFinanceChargeSettingsForCompanyRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListCustomersForFinanceChargeSetting(rd.api.company.ListCustomersForFinanceChargeSettingReq) returns (rd.api.company.ListCustomersForFinanceChargeSettingRes);
     */
    listCustomersForFinanceChargeSetting(input: ListCustomersForFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<ListCustomersForFinanceChargeSettingReq, ListCustomersForFinanceChargeSettingRes> {
        const method = this.methods[75], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCustomersForFinanceChargeSettingReq, ListCustomersForFinanceChargeSettingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeactivateFinanceChargeSetting(rd.api.company.DeactivateFinanceChargeSettingReq) returns (rd.api.company.DeactivateFinanceChargeSettingRes);
     */
    deactivateFinanceChargeSetting(input: DeactivateFinanceChargeSettingReq, options?: RpcOptions): UnaryCall<DeactivateFinanceChargeSettingReq, DeactivateFinanceChargeSettingRes> {
        const method = this.methods[76], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeactivateFinanceChargeSettingReq, DeactivateFinanceChargeSettingRes>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReorderFinanceChargeSettings(rd.api.company.ReorderFinanceChargeSettingsReq) returns (rd.api.company.ReorderFinanceChargeSettingsRes);
     */
    reorderFinanceChargeSettings(input: ReorderFinanceChargeSettingsReq, options?: RpcOptions): UnaryCall<ReorderFinanceChargeSettingsReq, ReorderFinanceChargeSettingsRes> {
        const method = this.methods[77], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReorderFinanceChargeSettingsReq, ReorderFinanceChargeSettingsRes>("unary", this._transport, method, opt, input);
    }
}
