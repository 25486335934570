import { UseBoolFilterProps, UseBoolFilterRes } from "./types"

import { BoolFilter } from "gen/search/service/service_pb"
import { useFilter } from "./useFilter"
import { useMemo } from "react"

export function useBoolFilter(props: UseBoolFilterProps): UseBoolFilterRes {
  const { selectedKeys, ...res } = useFilter({
    ...props,
    values: [BoolFilter.TRUE, BoolFilter.FALSE],
    keyExtractor: (option) => option.toString(),
    autoFilterOptions: true,
  })

  const selectedValue = useMemo(
    () =>
      selectedKeys.length === 1 ? Number(selectedKeys[0]) : BoolFilter.NONE,
    [selectedKeys]
  )

  return {
    ...res,
    selectedValue,
  }
}
