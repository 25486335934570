import { UseIdFilterProps, UseIdFilterRes } from "./types"
import { useCallback, useMemo } from "react"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"
import { useFilter } from "./useFilter"

export function useIdFilter<TValue extends { id?: PBUUID }>(
  props: UseIdFilterProps<TValue>
): UseIdFilterRes {
  const keyExtractor = useCallback(({ id }: TValue) => UUID.idString(id), [])

  const res = useFilter({
    ...props,
    keyExtractor,
  })

  const selected = useMemo<PBUUID[]>(
    () => res.selectedKeys.map((id) => UUID.fromString(id).toPB()),
    [res.selectedKeys]
  )

  return {
    ...res,
    selected,
  }
}
