// @ts-strict-ignore
import { Flag, Value } from "gen/flags/models_pb"
import { GetFlagsReq, GetFlagsRes } from "gen/api/flags/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { FlagsServiceClient } from "gen/api/flags/service_pb.client"
import { listEnumValues } from "@protobuf-ts/runtime"

const f = new FlagsServiceClient(transport)

export const flagsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlags: builder.query<GetFlagsRes, GetFlagsReq>({
      query: (r) => f.getFlags(r),
    }),
  }),
})

export const { useGetFlagsQuery, useLazyGetFlagsQuery } = flagsApiSlice

type UseFlagsRes = {
  [key in Exclude<keyof typeof Flag, "UNDEFINED">]: Value
} & {
  isLoading: boolean
}

const defaultFlagValue: Value = {
  boolVal: false,
  stringVal: "",
  intVal: 0n,
}

// useFlags is a custom hook to simplify using feature flags
// from the frontend.
export function useFlags(): UseFlagsRes {
  const { currentData, isLoading, error } = useGetFlagsQuery(
    {},
    // RTK Query will keep your data cached for 60 seconds by default after the last component unsubscribes.
    // We want to refetch on mount if the last refetch was more than 30 seconds ago.
    // default refetch: https://redux-toolkit.js.org/rtk-query/api/createApi#keepunuseddatafor
    // re-fetching on mount: https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#encouraging-re-fetching-with-refetchonmountorargchange
    {
      refetchOnMountOrArgChange: 30, // 30 seconds
    }
  )

  error && console.debug("getFlags error: ", error)
  console.debug("flags: ", currentData?.flags)

  const res: UseFlagsRes = { isLoading } as UseFlagsRes
  for (const { name, number } of listEnumValues(Flag)) {
    if (!currentData) {
      res[name] = defaultFlagValue
      continue
    }

    res[name] = currentData.flags[number] ?? defaultFlagValue
  }
  return res
}
