import {
  ClearCardReaderReq,
  ClearCardReaderRes,
  CreateAdditionalFeeReq,
  CreateAdditionalFeeRes,
  CreateCustomTaxRateReq,
  CreateCustomTaxRateRes,
  CreateFinanceChargeSettingReq,
  CreateFinanceChargeSettingRes,
  CreateFinancingTermReq,
  CreateFinancingTermRes,
  CreateLocationReq,
  CreateLocationRes,
  CreatePricingTierReq,
  CreatePricingTierRes,
  CreateProductCategoryV2Req,
  CreateProductCategoryV2Res,
  CreateStaffReq,
  CreateStaffRes,
  DeactivateCustomTaxRateReq,
  DeactivateCustomTaxRateRes,
  DeactivateFinanceChargeSettingReq,
  DeactivateFinanceChargeSettingRes,
  DeactivateProductCategoryV2Req,
  DeactivateProductCategoryV2Res,
  DeactivateStaffReq,
  DeactivateStaffRes,
  DeactivateStaffWebAuthnKeysReq,
  DeactivateStaffWebAuthnKeysRes,
  DeleteAdditionalFeeReq,
  DeleteAdditionalFeeRes,
  DeleteFinancingTermReq,
  DeleteFinancingTermRes,
  DeletePricingTierReq,
  DeletePricingTierRes,
  GetCompanyForSubdomainRes,
  GetCompanyReq,
  GetCompanyRes,
  GetCurrentUserReq,
  GetCurrentUserRes,
  GetLocationReq,
  GetLocationRes,
  GetPermissionsGroupsReq,
  GetPermissionsGroupsRes,
  GetStaffReq,
  GetStaffRes,
  GetStripeLoginURLReq,
  GetStripeLoginURLRes,
  GetStripeOnboardingURLReq,
  GetStripeOnboardingURLRes,
  GetTaxRateReq,
  GetTaxRateRes,
  ListActiveFinanceChargeSettingsForCompanyReq,
  ListActiveFinanceChargeSettingsForCompanyRes,
  ListAdditionalFeesForCompanyReq,
  ListAdditionalFeesForCompanyRes,
  ListAuthorizedEndpointsReq,
  ListAuthorizedEndpointsRes,
  ListCardReadersForLocationReq,
  ListCardReadersForLocationRes,
  ListCustomersForFinanceChargeSettingReq,
  ListCustomersForFinanceChargeSettingRes,
  ListCustomersForTermReq,
  ListCustomersForTermRes,
  ListCustomersForTierReq,
  ListCustomersForTierRes,
  ListFinancingTermsForCompanyReq,
  ListFinancingTermsForCompanyRes,
  ListLocationsForCompanyReq,
  ListLocationsForCompanyRes,
  ListPricingTiersForCompanyReq,
  ListPricingTiersForCompanyRes,
  ListProductCategoriesForCompanyV2Req,
  ListProductCategoriesForCompanyV2Res,
  ListProductsForCategoryV2Req,
  ListProductsForCategoryV2Res,
  ListProductsForFeeReq,
  ListProductsForFeeRes,
  ListStaffsForCompanyReq,
  ListStaffsForCompanyRes,
  ListTaxRatesForCompanyReq,
  ListTaxRatesForCompanyRes,
  ReactivateStaffReq,
  ReactivateStaffRes,
  ReorderAdditionalFeesReq,
  ReorderAdditionalFeesRes,
  ReorderCustomTaxRatesReq,
  ReorderCustomTaxRatesRes,
  ReorderFinanceChargeSettingsReq,
  ReorderFinanceChargeSettingsRes,
  ReorderFinancingTermsReq,
  ReorderFinancingTermsRes,
  ReorderPricingTiersReq,
  ReorderPricingTiersRes,
  UpdateAdditionalFeeReq,
  UpdateAdditionalFeeRes,
  UpdateCardSurchargeRateReq,
  UpdateCardSurchargeRateRes,
  UpdateCompanyContractorRewardsSettingsReq,
  UpdateCompanyContractorRewardsSettingsRes,
  UpdateCompanyGeneralCommunicationsSettingsReq,
  UpdateCompanyGeneralCommunicationsSettingsRes,
  UpdateCompanyGeneralSettingsReq,
  UpdateCompanyGeneralSettingsRes,
  UpdateCompanyIsCardSurchargeTaxExemptReq,
  UpdateCompanyIsCardSurchargeTaxExemptRes,
  UpdateCompanyIsColorFormulaHiddenReq,
  UpdateCompanyIsColorFormulaHiddenRes,
  UpdateCompanyIsDiscountShownToCustomersReq,
  UpdateCompanyIsDiscountShownToCustomersRes,
  UpdateCompanyIsFinancingTermsShownOnInvoiceReq,
  UpdateCompanyIsFinancingTermsShownOnInvoiceRes,
  UpdateCompanyIsLoyaltyProgramEnabledReq,
  UpdateCompanyIsLoyaltyProgramEnabledRes,
  UpdateCompanyIsPriceShownOnWillCallReq,
  UpdateCompanyIsPriceShownOnWillCallRes,
  UpdateCompanyLoyaltyProgramPolicyReq,
  UpdateCompanyLoyaltyProgramPolicyRes,
  UpdateCompanySecuritySettingsReq,
  UpdateCompanySecuritySettingsRes,
  UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq,
  UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes,
  UpdateCompanyShouldAutoSubmitContractorRewardsReq,
  UpdateCompanyShouldAutoSubmitContractorRewardsRes,
  UpdateCompanyStatementSettingsReq,
  UpdateCompanyStatementSettingsRes,
  UpdateCustomTaxRateReq,
  UpdateCustomTaxRateRes,
  UpdateFinanceChargeSettingReq,
  UpdateFinanceChargeSettingRes,
  UpdateFinancingTermReq,
  UpdateFinancingTermRes,
  UpdateLocationBMOutletNumberReq,
  UpdateLocationBMOutletNumberRes,
  UpdateLocationCashFloatReq,
  UpdateLocationCashFloatRes,
  UpdateLocationGoogleReviewUrlReq,
  UpdateLocationGoogleReviewUrlRes,
  UpdateLocationLogoImageUrlReq,
  UpdateLocationLogoImageUrlRes,
  UpdateLocationQuickbooksClassReq,
  UpdateLocationQuickbooksClassRes,
  UpdateLocationReq,
  UpdateLocationRes,
  UpdatePricingTierReq,
  UpdatePricingTierRes,
  UpdateProductCategoryV2Req,
  UpdateProductCategoryV2Res,
  UpdateStaffPermissionsReq,
  UpdateStaffPermissionsRes,
  UpdateStaffReq,
  UpdateStaffRes,
} from "gen/api/company/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { CompanyServiceClient } from "gen/api/company/service_pb.client"
import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"

const c = new CompanyServiceClient(transport)

export const companyApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "Company",
      "Location",
      "Staff",
      "PricingTier",
      "FinancingTerm",
      "FinancingRate",
      "ProductCategory",
      "ProductCategoryV2",
      "AdditionalFee",
      "TaxRate",
      "PermissionsGroups",
      "FinanceChargeSetting",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      /* Company */
      getCompanyForSubdomain: builder.query<GetCompanyForSubdomainRes, string>({
        query: (subdomain) => c.getCompanyForSubdomain({ subdomain }),
      }),
      getCompany: builder.query<GetCompanyRes, GetCompanyReq>({
        query: (r) => c.getCompany(r),
        providesTags: ["Company"],
      }),
      updateCompanyGeneralSettings: builder.mutation<
        UpdateCompanyGeneralSettingsRes,
        UpdateCompanyGeneralSettingsReq
      >({
        query: (r) => c.updateCompanyGeneralSettings(r),
        invalidatesTags: ["Company"],
      }),
      updateCompanySecuritySettings: builder.mutation<
        UpdateCompanySecuritySettingsRes,
        UpdateCompanySecuritySettingsReq
      >({
        query: (r) => c.updateCompanySecuritySettings(r),
        invalidatesTags: ["Company"],
      }),
      updateCompanyShouldAutoSubmitContractorRewards: builder.mutation<
        UpdateCompanyShouldAutoSubmitContractorRewardsRes,
        UpdateCompanyShouldAutoSubmitContractorRewardsReq
      >({
        query: (r) => c.updateCompanyShouldAutoSubmitContractorRewards(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.shouldAutoSubmitContractorRewards =
                  request.shouldAutoSubmitContractorRewards
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyContractorRewardsSettings: builder.mutation<
        UpdateCompanyContractorRewardsSettingsRes,
        UpdateCompanyContractorRewardsSettingsReq
      >({
        query: (r) => c.updateCompanyContractorRewardsSettings(r),
        invalidatesTags: ["Company"],
      }),
      updateCompanyIsDiscountShownToCustomers: builder.mutation<
        UpdateCompanyIsDiscountShownToCustomersRes,
        UpdateCompanyIsDiscountShownToCustomersReq
      >({
        query: (r) => c.updateCompanyIsDiscountShownToCustomers(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isDiscountShownToCustomers =
                  request.isDiscountShownToCustomers
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyIsColorFormulaHidden: builder.mutation<
        UpdateCompanyIsColorFormulaHiddenRes,
        UpdateCompanyIsColorFormulaHiddenReq
      >({
        query: (r) => c.updateCompanyIsColorFormulaHidden(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isColorFormulaHidden = request.isColorFormulaHidden
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyIsFinancingTermsShownOnInvoice: builder.mutation<
        UpdateCompanyIsFinancingTermsShownOnInvoiceRes,
        UpdateCompanyIsFinancingTermsShownOnInvoiceReq
      >({
        query: (r) => c.updateCompanyIsFinancingTermsShownOnInvoice(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isFinancingTermsShownOnInvoice =
                  request.isFinancingTermsShownOnInvoice
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyIsPriceShownOnWillCall: builder.mutation<
        UpdateCompanyIsPriceShownOnWillCallRes,
        UpdateCompanyIsPriceShownOnWillCallReq
      >({
        query: (r) => c.updateCompanyIsPriceShownOnWillCall(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isPriceShownOnWillCall = request.isPriceShownOnWillCall
              }
            )
          )
          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyGeneralCommunicationsSettings: builder.mutation<
        UpdateCompanyGeneralCommunicationsSettingsRes,
        UpdateCompanyGeneralCommunicationsSettingsReq
      >({
        query: (r) => c.updateCompanyGeneralCommunicationsSettings(r),
        invalidatesTags: ["Company"],
      }),
      UpdateCompanyStatementSettings: builder.mutation<
        UpdateCompanyStatementSettingsRes,
        UpdateCompanyStatementSettingsReq
      >({
        query: (r) => c.updateCompanyStatementSettings(r),
        invalidatesTags: ["Company"],
      }),
      updateCompanyIsCardSurchargeTaxExempt: builder.mutation<
        UpdateCompanyIsCardSurchargeTaxExemptRes,
        UpdateCompanyIsCardSurchargeTaxExemptReq
      >({
        query: (r) => c.updateCompanyIsCardSurchargeTaxExempt(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isCardSurchargeTaxExempt =
                  request.isCardSurchargeTaxExempt
              }
            )
          )
          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyIsLoyaltyProgramEnabled: builder.mutation<
        UpdateCompanyIsLoyaltyProgramEnabledRes,
        UpdateCompanyIsLoyaltyProgramEnabledReq
      >({
        query: (r) => c.updateCompanyIsLoyaltyProgramEnabled(r),
        invalidatesTags: ["Company"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "getCompany",
              {},
              ({ company }) => {
                if (!company) {
                  return
                }

                company.isLoyaltyProgramEnabled =
                  request.isLoyaltyProgramEnabled
              }
            )
          )
          queryFulfilled.catch(patchResult.undo)
        },
      }),
      updateCompanyLoyaltyProgramPolicy: builder.mutation<
        UpdateCompanyLoyaltyProgramPolicyRes,
        UpdateCompanyLoyaltyProgramPolicyReq
      >({
        query: (r) => c.updateCompanyLoyaltyProgramPolicy(r),
        invalidatesTags: ["Company"],
      }),
      updateCompanyShouldAutoSignOutAfterSaleSettings: builder.mutation<
        UpdateCompanyShouldAutoSignOutAfterSaleSettingsRes,
        UpdateCompanyShouldAutoSignOutAfterSaleSettingsReq
      >({
        query: (r) => c.updateCompanyShouldAutoSignOutAfterSaleSettings(r),
        invalidatesTags: ["Company"],
      }),
      getStripeExpressOnboardingURL: builder.query<
        GetStripeOnboardingURLRes,
        GetStripeOnboardingURLReq
      >({
        query: (r) => c.getStripeOnboardingURL(r),
      }),
      getStripeCustomOnboardingURL: builder.query<
        GetStripeOnboardingURLRes,
        GetStripeOnboardingURLReq
      >({
        query: (r) => c.getStripeCustomOnboardingURL(r),
      }),
      getStripeLoginURL: builder.query<
        GetStripeLoginURLRes,
        GetStripeLoginURLReq
      >({
        query: (r) => c.getStripeLoginURL(r),
      }),
      updateCardSurchargeRateSettings: builder.mutation<
        UpdateCardSurchargeRateRes,
        UpdateCardSurchargeRateReq
      >({
        query: (r) => c.updateCardSurchargeRate(r),
        invalidatesTags: ["Company"],
      }),
      /* Location */
      getLocation: builder.query<GetLocationRes, GetLocationReq>({
        query: (r) => c.getLocation(r),
        providesTags: (_response, _error, request) => [
          {
            type: "Location",
            id: request.locationId
              ? UUID.fromPB(request.locationId).toString()
              : "",
          },
        ],
      }),
      listLocationsForCompany: builder.query<
        ListLocationsForCompanyRes,
        ListLocationsForCompanyReq
      >({
        query: (r) => c.listLocationsForCompany(r),
        providesTags: ["Location"],
      }),
      listCardReadersForLocation: builder.query<
        ListCardReadersForLocationRes,
        ListCardReadersForLocationReq
      >({
        query: ({ locationId }) => c.listCardReadersForLocation({ locationId }),
        providesTags: ["Location"],
      }),
      createLocation: builder.mutation<CreateLocationRes, CreateLocationReq>({
        query: (r) => c.createLocation(r),
        invalidatesTags: ["Location", "TaxRate"],
      }),
      updateLocation: builder.mutation<UpdateLocationRes, UpdateLocationReq>({
        query: (r) => c.updateLocation(r),
        invalidatesTags: ["Location"],
      }),
      updateLocationGoogleReviewUrl: builder.mutation<
        UpdateLocationGoogleReviewUrlRes,
        UpdateLocationGoogleReviewUrlReq
      >({
        query: (r) => c.updateLocationGoogleReviewUrl(r),
        invalidatesTags: (_res, _err, req) => [
          {
            type: "Location",
            id: UUID.idString(req.locationId),
          },
        ],
      }),
      updateLocationCashFloat: builder.mutation<
        UpdateLocationCashFloatRes,
        UpdateLocationCashFloatReq
      >({
        query: (r) => c.updateLocationCashFloat(r),
        invalidatesTags: (_res, _err, req) => [
          {
            type: "Location",
            id: UUID.idString(req.locationId),
          },
        ],
      }),
      updateLocationQuickbooksClass: builder.mutation<
        UpdateLocationQuickbooksClassRes,
        UpdateLocationQuickbooksClassReq
      >({
        query: (r) => c.updateLocationQuickbooksClass(r),
        invalidatesTags: (_res, _err, req) => [
          {
            type: "Location",
            id: UUID.idString(req.locationId),
          },
        ],
      }),
      updateLocationBMOutletNumber: builder.mutation<
        UpdateLocationBMOutletNumberRes,
        UpdateLocationBMOutletNumberReq
      >({
        query: (r) => c.updateLocationBMOutletNumber(r),
        invalidatesTags: (_res, _err, req) => [
          {
            type: "Location",
            id: UUID.idString(req.locationId),
          },
        ],
      }),
      updateLocationLogoImageUrl: builder.mutation<
        UpdateLocationLogoImageUrlRes,
        UpdateLocationLogoImageUrlReq
      >({
        query: (r) => c.updateLocationLogoImageUrl(r),
        invalidatesTags: (_res, _err, req) => [
          {
            type: "Location",
            id: UUID.idString(req.locationId),
          },
        ],
      }),

      /* Staff */
      getStaff: builder.query<GetStaffRes, GetStaffReq>({
        query: (r) => c.getStaff(r),
        providesTags: (_response, _error, request) => [
          { type: "Staff", id: UUID.fromPB(request.id!).toString() },
        ],
      }),
      listStaffsForCompany: builder.query<
        ListStaffsForCompanyRes,
        ListStaffsForCompanyReq
      >({
        query: (r) => c.listStaffsForCompany(r),
        providesTags: ["Staff"],
      }),
      createStaff: builder.mutation<CreateStaffRes, CreateStaffReq>({
        query: (r) => c.createStaff(r),
        invalidatesTags: ["Staff"],
      }),
      updateStaff: builder.mutation<UpdateStaffRes, UpdateStaffReq>({
        query: (r) => c.updateStaff(r),
        invalidatesTags: ["Staff"],
      }),
      deactivateStaff: builder.mutation<DeactivateStaffRes, DeactivateStaffReq>(
        {
          query: (r) => c.deactivateStaff(r),
          invalidatesTags: ["Staff"],
        }
      ),
      reactivateStaff: builder.mutation<ReactivateStaffRes, ReactivateStaffReq>(
        {
          query: (r) => c.reactivateStaff(r),
          invalidatesTags: ["Staff"],
        }
      ),
      deactivateStaffWebAuthnKeys: builder.mutation<
        DeactivateStaffWebAuthnKeysRes,
        DeactivateStaffWebAuthnKeysReq
      >({
        query: (r) => c.deactivateStaffWebAuthnKeys(r),
        invalidatesTags: ["Staff"],
      }),
      listAuthorizedEndpoints: builder.query<
        ListAuthorizedEndpointsRes,
        ListAuthorizedEndpointsReq
      >({
        query: (r) => c.listAuthorizedEndpoints(r),
      }),
      updateStaffPermissions: builder.mutation<
        UpdateStaffPermissionsRes,
        UpdateStaffPermissionsReq
      >({
        query: (r) => c.updateStaffPermissions(r),
        invalidatesTags: ["Staff"],
      }),

      /* User */
      getCurrentUser: builder.query<GetCurrentUserRes, GetCurrentUserReq>({
        query: (r) => c.getCurrentUser(r),
      }),

      /* Pricing Tier */
      listPricingTiersForCompany: builder.query<
        ListPricingTiersForCompanyRes,
        ListPricingTiersForCompanyReq
      >({
        query: (r) => c.listPricingTiersForCompany(r),
        providesTags: ["PricingTier"],
      }),
      listCustomersForTier: builder.query<
        ListCustomersForTierRes,
        ListCustomersForTierReq
      >({
        query: (r) => c.listCustomersForTier(r),
        providesTags: ["PricingTier"],
      }),
      createPricingTier: builder.mutation<
        CreatePricingTierRes,
        CreatePricingTierReq
      >({
        query: (r) => c.createPricingTier(r),
        invalidatesTags: ["PricingTier"],
      }),
      updatePricingTier: builder.mutation<
        UpdatePricingTierRes,
        UpdatePricingTierReq
      >({
        query: (r) => c.updatePricingTier(r),
        invalidatesTags: ["PricingTier"],
      }),
      deletePricingTier: builder.mutation<
        DeletePricingTierRes,
        DeletePricingTierReq
      >({
        query: (r) => c.deletePricingTier(r),
        invalidatesTags: ["PricingTier"],
      }),
      reorderPricingTiers: builder.mutation<
        ReorderPricingTiersRes,
        ReorderPricingTiersReq
      >({
        query: (r) => c.reorderPricingTiers(r),
        invalidatesTags: ["PricingTier"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "listPricingTiersForCompany",
              {},
              ({ tiers }) => {
                for (const [i, tier] of tiers.entries()) {
                  tiers[i].rank = request.idStrings.indexOf(tier.idString) + 1
                }

                tiers.sort((a, b) => {
                  if (a.rank < b.rank) {
                    return -1
                  }
                  if (a.rank > b.rank) {
                    return 1
                  }
                  return 0
                })
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),

      /* Financing Term */
      listFinancingTermsForCompany: builder.query<
        ListFinancingTermsForCompanyRes,
        ListFinancingTermsForCompanyReq
      >({
        query: (r) => c.listFinancingTermsForCompany(r),
        providesTags: ["FinancingTerm"],
      }),
      listCustomersForTerm: builder.query<
        ListCustomersForTermRes,
        ListCustomersForTermReq
      >({
        query: (r) => c.listCustomersForTerm(r),
      }),
      createFinancingTerm: builder.mutation<
        CreateFinancingTermRes,
        CreateFinancingTermReq
      >({
        query: (r) => c.createFinancingTerm(r),
        invalidatesTags: ["FinancingTerm"],
      }),
      updateFinancingTerm: builder.mutation<
        UpdateFinancingTermRes,
        UpdateFinancingTermReq
      >({
        query: (r) => c.updateFinancingTerm(r),
        invalidatesTags: ["FinancingTerm"],
      }),
      deleteFinancingTerm: builder.mutation<
        DeleteFinancingTermRes,
        DeleteFinancingTermReq
      >({
        query: (r) => c.deleteFinancingTerm(r),
        invalidatesTags: ["FinancingTerm"],
      }),
      reorderFinancingTerms: builder.mutation<
        ReorderFinancingTermsRes,
        ReorderFinancingTermsReq
      >({
        query: (r) => c.reorderFinancingTerms(r),
        invalidatesTags: ["FinancingTerm"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "listFinancingTermsForCompany",
              {},
              ({ terms }) => {
                for (const [i, term] of terms.entries()) {
                  terms[i].position =
                    request.idStrings.indexOf(term.idString) + 1
                }

                terms.sort((a, b) => {
                  if (a.position < b.position) {
                    return -1
                  }
                  if (a.position > b.position) {
                    return 1
                  }
                  return 0
                })
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),

      /* Product Category V2*/
      listProductCategoriesForCompanyV2: builder.query<
        ListProductCategoriesForCompanyV2Res,
        ListProductCategoriesForCompanyV2Req
      >({
        query: (r) => c.listProductCategoriesForCompanyV2(r),
        providesTags: ["ProductCategoryV2"],
      }),
      createProductCategoryV2: builder.mutation<
        CreateProductCategoryV2Res,
        CreateProductCategoryV2Req
      >({
        query: (r) => c.createProductCategoryV2(r),
        invalidatesTags: ["ProductCategoryV2"],
      }),
      updateProductCategoryV2: builder.mutation<
        UpdateProductCategoryV2Res,
        UpdateProductCategoryV2Req
      >({
        query: (r) => c.updateProductCategoryV2(r),
        invalidatesTags: ["ProductCategoryV2"],
      }),
      deactivateProductCategoryV2: builder.mutation<
        DeactivateProductCategoryV2Res,
        DeactivateProductCategoryV2Req
      >({
        query: (r) => c.deactivateProductCategoryV2(r),
        invalidatesTags: ["ProductCategoryV2"],
      }),
      listProductsForCategoryV2: builder.query<
        ListProductsForCategoryV2Res,
        ListProductsForCategoryV2Req
      >({
        query: (r) => c.listProductsForCategoryV2(r),
      }),

      /* Additional Fee */
      listAdditionalFeesForCompany: builder.query<
        ListAdditionalFeesForCompanyRes,
        ListAdditionalFeesForCompanyReq
      >({
        query: (r) => c.listAdditionalFeesForCompany(r),
        providesTags: ["AdditionalFee"],
      }),
      listProductsForFee: builder.query<
        ListProductsForFeeRes,
        ListProductsForFeeReq
      >({
        query: (r) => c.listProductsForFee(r),
      }),
      createAdditionalFee: builder.mutation<
        CreateAdditionalFeeRes,
        CreateAdditionalFeeReq
      >({
        query: (r) => c.createAdditionalFee(r),
        invalidatesTags: ["AdditionalFee"],
      }),
      updateAdditionalFee: builder.mutation<
        UpdateAdditionalFeeRes,
        UpdateAdditionalFeeReq
      >({
        query: (r) => c.updateAdditionalFee(r),
        invalidatesTags: ["AdditionalFee"],
      }),
      deleteAdditionalFee: builder.mutation<
        DeleteAdditionalFeeRes,
        DeleteAdditionalFeeReq
      >({
        query: (r) => c.deleteAdditionalFee(r),
        invalidatesTags: ["AdditionalFee"],
      }),
      reorderAdditionalFees: builder.mutation<
        ReorderAdditionalFeesRes,
        ReorderAdditionalFeesReq
      >({
        query: (r) => c.reorderAdditionalFees(r),
        invalidatesTags: ["AdditionalFee"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "listAdditionalFeesForCompany",
              {},
              ({ fees }) => {
                for (const [i, tier] of fees.entries()) {
                  fees[i].position =
                    request.idStrings.indexOf(tier.idString) + 1
                }

                fees.sort((a, b) => {
                  if (a.position < b.position) {
                    return -1
                  }
                  if (a.position > b.position) {
                    return 1
                  }
                  return 0
                })
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      clearCardReader: builder.mutation<ClearCardReaderRes, ClearCardReaderReq>(
        {
          query: (r) => c.clearCardReader(r),
        }
      ),
      listTaxRatesForCompany: builder.query<
        ListTaxRatesForCompanyRes,
        ListTaxRatesForCompanyReq
      >({
        query: (r) => c.listTaxRatesForCompany(r),
        providesTags: ["TaxRate"],
      }),
      createCustomTaxRate: builder.mutation<
        CreateCustomTaxRateRes,
        CreateCustomTaxRateReq
      >({
        query: (r) => c.createCustomTaxRate(r),
        invalidatesTags: ["TaxRate"],
      }),
      updateCustomTaxRate: builder.mutation<
        UpdateCustomTaxRateRes,
        UpdateCustomTaxRateReq
      >({
        query: (r) => c.updateCustomTaxRate(r),
        invalidatesTags: ["TaxRate"],
      }),
      reorderCustomTaxRates: builder.mutation<
        ReorderCustomTaxRatesRes,
        ReorderCustomTaxRatesReq
      >({
        query: (r) => c.reorderCustomTaxRates(r),
        invalidatesTags: ["TaxRate"],
        onQueryStarted: async (
          request,
          { dispatch, queryFulfilled, getState }
        ) => {
          const patchResults = companyApiSlice.util
            .selectInvalidatedBy(getState(), ["TaxRate"])
            .filter(
              ({ endpointName }) => endpointName === "listTaxRatesForCompany"
            )
            .map(({ originalArgs }) =>
              dispatch(
                companyApiSlice.util.updateQueryData(
                  "listTaxRatesForCompany",
                  originalArgs,
                  ({ taxRates }) => {
                    for (const customTaxRate of taxRates) {
                      const idx = request.ids.findIndex((id) =>
                        UUID.eqFromPB(id, customTaxRate.id)
                      )
                      customTaxRate.position = idx + 1
                    }

                    taxRates.sort((a, b) => {
                      if (a.position < b.position) {
                        return -1
                      }
                      if (a.position > b.position) {
                        return 1
                      }
                      return 0
                    })
                  }
                )
              )
            )

          try {
            await queryFulfilled
          } catch {
            for (const patchResult of patchResults) {
              patchResult.undo()
            }
          }
        },
      }),
      deactivateCustomTaxRate: builder.mutation<
        DeactivateCustomTaxRateRes,
        DeactivateCustomTaxRateReq
      >({
        query: (r) => c.deactivateCustomTaxRate(r),
        invalidatesTags: ["TaxRate"],
      }),
      getTaxRate: builder.query<GetTaxRateRes, GetTaxRateReq>({
        query: (r) => c.getTaxRate(r),
        providesTags: ["TaxRate"],
      }),

      /* Roles */
      getPermissionsGroups: builder.query<
        GetPermissionsGroupsRes,
        GetPermissionsGroupsReq
      >({
        query: (r) => c.getPermissionsGroups(r),
        providesTags: ["PermissionsGroups"],
      }),

      /* Finance Charge Setting */
      createFinanceChargeSetting: builder.mutation<
        CreateFinanceChargeSettingRes,
        CreateFinanceChargeSettingReq
      >({
        query: (r) => c.createFinanceChargeSetting(r),
        invalidatesTags: ["FinanceChargeSetting"],
      }),
      updateFinanceChargeSetting: builder.mutation<
        UpdateFinanceChargeSettingRes,
        UpdateFinanceChargeSettingReq
      >({
        query: (r) => c.updateFinanceChargeSetting(r),
        invalidatesTags: ["FinanceChargeSetting"],
      }),
      listActiveFinanceChargeSettingsForCompany: builder.query<
        ListActiveFinanceChargeSettingsForCompanyRes,
        ListActiveFinanceChargeSettingsForCompanyReq
      >({
        query: (r) => c.listActiveFinanceChargeSettingsForCompany(r),
        providesTags: ["FinanceChargeSetting"],
      }),
      deactivateFinanceChargeSetting: builder.mutation<
        DeactivateFinanceChargeSettingRes,
        DeactivateFinanceChargeSettingReq
      >({
        query: (r) => c.deactivateFinanceChargeSetting(r),
        invalidatesTags: ["FinanceChargeSetting"],
      }),
      reorderFinanceChargeSettings: builder.mutation<
        ReorderFinanceChargeSettingsRes,
        ReorderFinanceChargeSettingsReq
      >({
        query: (r) => c.reorderFinanceChargeSettings(r),
        invalidatesTags: ["FinanceChargeSetting"],
        onQueryStarted: (request, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            companyApiSlice.util.updateQueryData(
              "listActiveFinanceChargeSettingsForCompany",
              {},
              ({ financeChargeSettings }) => {
                financeChargeSettings.forEach((charge) => {
                  const idx = request.ids.findIndex((id: PBUUID) =>
                    UUID.fromPB(id).eq(UUID.fromPB(charge.id!))
                  )
                  charge.position = idx + 1
                })
                financeChargeSettings.sort((a, b) => a.position - b.position)
              }
            )
          )

          queryFulfilled.catch(patchResult.undo)
        },
      }),
      listCustomersForFinanceChargeSetting: builder.query<
        ListCustomersForFinanceChargeSettingRes,
        ListCustomersForFinanceChargeSettingReq
      >({
        query: (r) => c.listCustomersForFinanceChargeSetting(r),
        providesTags: ["FinanceChargeSetting"],
      }),
    }),
  })

export const {
  /* Company */
  useGetCompanyForSubdomainQuery,
  useLazyGetCompanyForSubdomainQuery,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useGetStripeExpressOnboardingURLQuery,
  useLazyGetStripeExpressOnboardingURLQuery,
  useGetStripeCustomOnboardingURLQuery,
  useLazyGetStripeCustomOnboardingURLQuery,
  useGetStripeLoginURLQuery,
  useLazyGetStripeLoginURLQuery,
  useUpdateCompanyGeneralSettingsMutation,
  useUpdateCompanySecuritySettingsMutation,
  useUpdateCompanyGeneralCommunicationsSettingsMutation,
  useUpdateCompanyIsLoyaltyProgramEnabledMutation,
  useUpdateCompanyIsCardSurchargeTaxExemptMutation,
  useUpdateCompanyLoyaltyProgramPolicyMutation,
  useUpdateCompanyStatementSettingsMutation,
  useUpdateCompanyShouldAutoSubmitContractorRewardsMutation,
  useUpdateCompanyContractorRewardsSettingsMutation,
  useUpdateCompanyIsDiscountShownToCustomersMutation,
  useUpdateCompanyIsPriceShownOnWillCallMutation,
  useUpdateCompanyIsFinancingTermsShownOnInvoiceMutation,
  useUpdateCardSurchargeRateSettingsMutation,
  useUpdateCompanyIsColorFormulaHiddenMutation,
  useUpdateCompanyShouldAutoSignOutAfterSaleSettingsMutation,

  /* Location */
  useGetLocationQuery,
  useListLocationsForCompanyQuery,
  useLazyListLocationsForCompanyQuery,
  useListCardReadersForLocationQuery,
  useLazyListCardReadersForLocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useClearCardReaderMutation,
  useUpdateLocationGoogleReviewUrlMutation,
  useUpdateLocationCashFloatMutation,
  useUpdateLocationBMOutletNumberMutation,
  useUpdateLocationQuickbooksClassMutation,
  useUpdateLocationLogoImageUrlMutation,

  /* Staff */
  useGetStaffQuery,
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useListStaffsForCompanyQuery,
  useLazyListStaffsForCompanyQuery,
  useCreateStaffMutation,
  useUpdateStaffMutation,
  useDeactivateStaffMutation,
  useReactivateStaffMutation,
  useDeactivateStaffWebAuthnKeysMutation,
  useListAuthorizedEndpointsQuery,
  useLazyListAuthorizedEndpointsQuery,
  useUpdateStaffPermissionsMutation,

  /* Pricing Tier */
  useListPricingTiersForCompanyQuery,
  useLazyListPricingTiersForCompanyQuery,
  useListCustomersForTierQuery,
  useCreatePricingTierMutation,
  useUpdatePricingTierMutation,
  useDeletePricingTierMutation,
  useReorderPricingTiersMutation,

  /* Financing Term */
  useListFinancingTermsForCompanyQuery,
  useLazyListFinancingTermsForCompanyQuery,
  useListCustomersForTermQuery,
  useCreateFinancingTermMutation,
  useUpdateFinancingTermMutation,
  useDeleteFinancingTermMutation,
  useReorderFinancingTermsMutation,

  /* Product Category V2 */
  useCreateProductCategoryV2Mutation,
  useUpdateProductCategoryV2Mutation,
  useDeactivateProductCategoryV2Mutation,
  useListProductCategoriesForCompanyV2Query,
  useListProductsForCategoryV2Query,

  /* Additional Fee */
  useListAdditionalFeesForCompanyQuery,
  useLazyListAdditionalFeesForCompanyQuery,
  useListProductsForFeeQuery,
  useCreateAdditionalFeeMutation,
  useUpdateAdditionalFeeMutation,
  useDeleteAdditionalFeeMutation,
  useReorderAdditionalFeesMutation,

  /* Tax Rate */
  useListTaxRatesForCompanyQuery,
  useLazyListTaxRatesForCompanyQuery,
  useCreateCustomTaxRateMutation,
  useUpdateCustomTaxRateMutation,
  useReorderCustomTaxRatesMutation,
  useDeactivateCustomTaxRateMutation,
  useGetTaxRateQuery,
  useLazyGetTaxRateQuery,

  /* Roles */
  useGetPermissionsGroupsQuery,

  /* Finance Charge Setting */
  useCreateFinanceChargeSettingMutation,
  useUpdateFinanceChargeSettingMutation,
  useListActiveFinanceChargeSettingsForCompanyQuery,
  useListCustomersForFinanceChargeSettingQuery,
  useDeactivateFinanceChargeSettingMutation,
  useReorderFinanceChargeSettingsMutation,
} = companyApiSlice
