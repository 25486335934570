import { UseEnumFilterProps, UseEnumFilterRes } from "./types"

import { listEnumNumbers } from "@protobuf-ts/runtime"
import { useFilter } from "./useFilter"
import { useMemo } from "react"

export function useEnumFilter({
  enumeration,
  labelExtractor,
  ...otherProps
}: UseEnumFilterProps): UseEnumFilterRes {
  const values = useMemo<number[]>(() => {
    return listEnumNumbers(enumeration)
      .filter(Boolean)
      .sort((a, b) => labelExtractor(a).localeCompare(labelExtractor(b)))
  }, [enumeration, labelExtractor])

  const res = useFilter({
    keyExtractor,
    values,
    labelExtractor,
    ...otherProps,
  })

  const selected = useMemo<number[]>(() => {
    return res.selectedKeys.map((key) => Number(key))
  }, [res.selectedKeys])

  return {
    ...res,
    selected,
  }
}

function keyExtractor(value: number) {
  return value.toString()
}
