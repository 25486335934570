import { customPrices, product, products, promotions } from "./products"
import { customer, customers } from "./customers"
import { vendor, vendors } from "./vendors"

import { bill } from "./bills"
import { inventoryCount } from "./inventoryCounts"
import { job } from "./jobs"
import { order } from "./orders"
import { orderShipment } from "./orderShipments"
import { sale } from "./sales"
import { saleReturn } from "./returns"
import { transfer } from "./transfers"

export const urls = {
  customer,
  customers,
  job,
  product,
  products,
  customPrices,
  promotions,
  vendor,
  vendors,
  inventoryCount,
  sale,
  return: saleReturn,
  transfer,
  order,
  orderShipment,
  bill,
}
