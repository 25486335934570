import { ComponentProps, forwardRef } from "react"

import { AdjustmentsIcon } from "@heroicons/react/outline"
import { ButtonProps } from "components/TableV2/Button"
import { DropdownButton } from "../../DropdownButton/DropdownButton"
import { FilterT } from "../Filter"
import { TimestampRange } from "lib/timestamp_range"
import { matchingPreset } from "lib/dates/presets"

interface FilterButtonProps extends Omit<ButtonProps, "mode"> {
  filter: FilterT | null
}

export const FilterButton = forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ filter, onClick }, ref) => {
    const hideDropdownIcon = !filter
    const label = getFilterLabel(filter)
    const className = !filter ? "border-none" : ""
    const mode =
      !!filter &&
      ((filter.type === "date" && !!filter.selectedDateRange) ||
        (filter?.type === "text" && filter.selectedOptions.length > 0))
        ? "positive"
        : "secondary"

    return (
      <DropdownButton
        ref={ref}
        mode={mode}
        icon={HorizontalAdjustmentsIcon}
        label={label}
        hideDropdownIcon={hideDropdownIcon}
        className={className}
        onClick={onClick}
      />
    )
  }
)

function HorizontalAdjustmentsIcon(props: ComponentProps<"svg">) {
  return <AdjustmentsIcon className="rotate-90" {...props} />
}

const MAX_VALUES_DISPLAYED = 3

export function getFilterLabel(filter: FilterT | null) {
  if (!filter) {
    return "Filter"
  }

  if (filter.type === "date") {
    if (!filter.selectedDateRange) {
      return filter.value
    }

    const selectedPreset = matchingPreset(
      filter.presetsToDisplay,
      filter.selectedDateRange
    )

    return selectedPreset
      ? selectedPreset.displayText
      : TimestampRange.humanize(filter.selectedDateRange.toTimestampRangePB())
  }

  let values = filter.selectedOptions.map((o) => o.value)
  if (values.length === 0) {
    return filter.value
  }

  if (values.length > MAX_VALUES_DISPLAYED) {
    const lengthOverflow = values.length - MAX_VALUES_DISPLAYED
    const overflowText = lengthOverflow > 1 ? "others" : "other"
    values = values.slice(0, MAX_VALUES_DISPLAYED)
    values.push(`and ${lengthOverflow} ${overflowText}`)
  }

  const valueText = values.join(", ")

  return `${filter.value}: ${valueText}`
}
