import {
  Combobox,
  ComboboxProps,
  DefaultEmptyState,
  highlightedRowClassName,
} from "components/TableV2/Combobox/Combobox"
import { PropsWithChildren, ReactNode } from "react"

import { CheckboxLabelledReadOnly } from "components/Checkbox"
import { useMultiCombobox } from "components/TableV2/Combobox/useCombobox"

export interface MultiComboboxProps<T> {
  selectedItemKeys: Set<string>
  items: T[]
  keyExtractor: (item: T) => string
  valueExtractor: (item: T) => string
  contentExtractor?: (item: T) => ReactNode
  onSelect: (item: T) => void
  autoFilter?: boolean
  comboboxProps?: ComboboxProps<T>
  onChangeQuery?: (query: string) => void
}

export function MultiCombobox<T>({
  items,
  keyExtractor,
  valueExtractor,
  contentExtractor,
  onSelect,
  selectedItemKeys,
  autoFilter,
  comboboxProps,
  onChangeQuery,
}: MultiComboboxProps<T>) {
  const useComboboxRes = useMultiCombobox({
    items,
    keyExtractor,
    valueExtractor,
    onSelect,
    autoFilter,
    onChangeQuery,
  })

  return (
    <Combobox
      useComboboxRes={useComboboxRes}
      renderItem={(item, isHighlighted) => (
        <div className={highlightedRowClassName(isHighlighted)}>
          <MultiComboboxItem
            isSelected={selectedItemKeys.has(keyExtractor(item))}
          >
            {contentExtractor?.(item) ?? valueExtractor(item)}
          </MultiComboboxItem>
        </div>
      )}
      keyExtractor={(item) => keyExtractor(item)}
      renderEmptyState={DefaultEmptyState}
      {...comboboxProps}
    />
  )
}

interface MultiComboboxItemProps {
  isSelected: boolean
}

function MultiComboboxItem({
  isSelected,
  children,
}: PropsWithChildren<MultiComboboxItemProps>) {
  return (
    <CheckboxLabelledReadOnly
      checked={isSelected}
      className="gap-x-3 overflow-hidden"
      labelClassName="truncate"
    >
      {children}
    </CheckboxLabelledReadOnly>
  )
}
