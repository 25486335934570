import { FilterType, OptionT, TextFilterT } from "../Filter/Filter"
import { ReactNode, useCallback, useMemo } from "react"
import { UseFilterProps, UseFilterRes } from "./types"

import { useRouter } from "next/router"

export function useFilter<TValue>({
  key,
  label,
  values,
  sort,
  keyExtractor,
  labelExtractor,
  contentExtractor,
  onChangeQuery,
  autoFilterOptions = true,
  allowExternalValues = false,
}: UseFilterProps<TValue>): UseFilterRes {
  const router = useRouter()

  const queryParam = router.query[key]
  const normalizedQueryParam = useMemo<string[]>(() => {
    if (queryParam === undefined) {
      return []
    }
    return Array.isArray(queryParam) ? queryParam : [queryParam]
  }, [queryParam])

  const valuesByKey = useMemo<Record<string, TValue>>(() => {
    return values.reduce<Record<string, TValue>>((acc, value) => {
      acc[keyExtractor(value)] = value
      return acc
    }, {})
  }, [values, keyExtractor])

  const selectedOptions = useMemo<OptionT[]>(() => {
    return normalizedQueryParam.map((param) => {
      const decoded = decodeQueryParam(param)
      const value = valuesByKey[decoded.key]
      if (value) {
        return optionFromValue(
          value,
          keyExtractor,
          labelExtractor,
          contentExtractor
        )
      }
      return decoded
    })
  }, [
    keyExtractor,
    labelExtractor,
    contentExtractor,
    normalizedQueryParam,
    valuesByKey,
  ])

  const selectedOptionKeys = useMemo<string[]>(
    () => selectedOptions.map((o) => o.key),
    [selectedOptions]
  )

  const options = useMemo<OptionT[]>(() => {
    const sorted = sort ? [...values].sort(sort) : values
    return sorted.map((value) =>
      optionFromValue(value, keyExtractor, labelExtractor, contentExtractor)
    )
  }, [values, keyExtractor, labelExtractor, contentExtractor, sort])

  const onSelectOption = useCallback(
    (option: OptionT) => {
      const newQuery = { ...router.query }

      const encoded = encodeQueryParam(option, allowExternalValues)
      if (selectedOptionKeys.includes(option.key)) {
        const filtered = normalizedQueryParam.filter(
          (param) => decodeQueryParam(param).key !== option.key
        )
        if (filtered.length > 0) {
          newQuery[key] = filtered
        } else {
          delete newQuery[key]
        }
      } else {
        newQuery[key] = [...normalizedQueryParam, encoded]
      }

      router.push(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true }
      )
    },
    [key, normalizedQueryParam, router, selectedOptionKeys, allowExternalValues]
  )

  const onClear = useCallback(() => {
    if (normalizedQueryParam.length === 0) {
      return
    }

    const newQuery = { ...router.query }
    delete newQuery[key]
    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    )
  }, [router, normalizedQueryParam, key])

  const filter: TextFilterT = {
    type: FilterType.Text,
    key,
    value: label,
    options,
    selectedOptions,
    onSelectOption,
    onClear,
    onChangeQuery,
    autoFilterOptions,
  }

  return { filter, selectedKeys: selectedOptionKeys }
}

function optionFromValue<TValue>(
  value: TValue,
  keyExtractor: (value: TValue) => string,
  labelExtractor: (value: TValue) => string,
  contentExtractor?: (value: TValue) => ReactNode
): OptionT {
  return {
    key: keyExtractor(value),
    value: labelExtractor(value),
    content: contentExtractor?.(value),
  }
}

const DELIMITER = "|"

export function encodeQueryParam(option: OptionT, cacheValue: boolean): string {
  if (cacheValue) {
    return [option.key, option.value].filter(Boolean).join(DELIMITER)
  }
  return option.key
}

export function decodeQueryParam(queryParam: string): OptionT {
  const [key, value] = queryParam.split(DELIMITER)
  return { key, value: value ?? "" }
}
