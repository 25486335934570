import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { Tag } from "gen/tag/models_pb"
import { UUID } from "uuid-rd"
import { encodeQueryParam } from "components/TableV2/useFilter"

export function product(id?: PBUUID): string {
  return `/pos/products/${UUID.idString(id)}`
}

export function products(): string {
  return "/pos/products?status=2"
}

export function productsWithTag(tag: Tag) {
  return `/pos/products?tag=${encodeQueryParam({ key: UUID.idString(tag.id), value: tag.name }, true)}`
}

export function customPrices(): string {
  return "/pos/products/custom-prices?status=2"
}

export function promotions(): string {
  return "/pos/products/promotions?status=2"
}
