import {
  AuthorizeCardPaymentReq,
  AuthorizeCardPaymentRes,
  CalculateReturnTotalsReq,
  CalculateReturnTotalsRes,
  DownloadItemizedSalesCSVReq,
  DownloadItemizedSalesCSVRes,
  DuplicateSaleReq,
  DuplicateSaleRes,
  EmailReturnPDFReq,
  EmailReturnPDFRes,
  EmailSalePDFReq,
  EmailSalePDFRes,
  GetReturnPDFReq,
  GetReturnPDFRes,
  GetReturnReq,
  GetReturnRes,
  GetSaleCountsByStatusReq,
  GetSaleCountsByStatusRes,
  GetSalePDFReq,
  GetSalePDFRes,
  GetSaleReq,
  GetSaleRes,
  GetScannedSaleReq,
  GetScannedSaleRes,
  ReturnSaleReq,
  ReturnSaleRes,
  SetCardReaderDisplayReq,
  SetCardReaderDisplayRes,
  SyncCartPricesReq,
  SyncCartPricesRes,
  UpdateReturnDetailsReq,
  UpdateReturnDetailsRes,
  UpdateSaleProductCostReq,
  UpdateSaleProductCostRes,
  UpdateSaleProductReturnedCostReq,
  UpdateSaleProductReturnedCostRes,
  UpdateSaleProductReturnedReq,
  UpdateSaleProductReturnedRes,
  UpdateSoldSaleDetailsReq,
  UpdateSoldSaleDetailsRes,
  UpdateSoldSaleProductReq,
  UpdateSoldSaleProductRes,
  UpdateUnpaidChargeAccountSaleDateReq,
  UpdateUnpaidChargeAccountSaleDateRes,
  UpsertSaleV3Req,
  UpsertSaleV3Res,
} from "gen/api/sale/service_pb"
import { apiSlice, transport } from "./apiSlice"

import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { SaleServiceClient } from "gen/api/sale/service_pb.client"
import { UUID } from "uuid-rd"

type OptionalReturnSyncCartPricesReq = Omit<
  SyncCartPricesReq,
  "productsToReturn" | "productsV2ToReturn"
> &
  Partial<SyncCartPricesReq>

const s = new SaleServiceClient(transport)

interface ReturnTagT {
  type: "Return"
  id: string
}

interface SaleTagT {
  type: "Sale"
  id: string
}

const makeReturnTag = (saleId?: PBUUID, retIdentifier?: string): ReturnTagT => {
  return {
    type: "Return",
    id: `${UUID.idString(saleId)}${retIdentifier}}`,
  }
}

const makeSaletag = (saleId?: PBUUID): SaleTagT => {
  return {
    type: "Sale",
    id: UUID.idString(saleId),
  }
}

export const salesApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      "SaleCountsByStatus",
      "Customer",
      "CustomerCards",
      "Product",
      "Sale",
      "Location",
      "RecentJobSites",
      "CustomerAging",
      "TaxEntries",
      "ColorxTintedProduct",
      "JournalEntries",
      "ProfitAndLoss",
      "Return",
      "ReturnTotal",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      downloadItemizedSalesCSV: builder.mutation<
        DownloadItemizedSalesCSVRes,
        DownloadItemizedSalesCSVReq
      >({
        query: (r) => s.downloadItemizedSalesCSV(r),
      }),
      authorizeCardPayment: builder.mutation<
        AuthorizeCardPaymentRes,
        AuthorizeCardPaymentReq
      >({
        query: (r) => s.authorizeCardPayment(r),
      }),
      emailSalePDF: builder.mutation<EmailSalePDFRes, EmailSalePDFReq>({
        query: (r) => s.emailSalePDF(r),
      }),
      getSale: builder.query<GetSaleRes, GetSaleReq>({
        query: (r) => s.getSale(r),
        providesTags: (_response, _error, request) => [makeSaletag(request.id)],
      }),
      getScannedSale: builder.query<GetScannedSaleRes, GetScannedSaleReq>({
        query: (r) => s.getScannedSale(r),
      }),
      getSaleCountsByStatus: builder.query<
        GetSaleCountsByStatusRes,
        GetSaleCountsByStatusReq
      >({
        query: (r) => s.getSaleCountsByStatus(r),
        providesTags: ["SaleCountsByStatus"],
      }),
      getSalePDF: builder.query<GetSalePDFRes, GetSalePDFReq>({
        query: (r) => s.getSalePDF(r),
      }),
      setCardReaderDisplay: builder.mutation<
        SetCardReaderDisplayRes,
        SetCardReaderDisplayReq
      >({
        query: (r) => s.setCardReaderDisplay(r),
      }),
      syncCartPrices: builder.mutation<
        SyncCartPricesRes,
        OptionalReturnSyncCartPricesReq
      >({
        query: (r) => s.syncCartPrices(SyncCartPricesReq.create(r)),
      }),
      upsertSaleV3: builder.mutation<UpsertSaleV3Res, UpsertSaleV3Req>({
        query: (r) => s.upsertSaleV3(r),
        invalidatesTags: (_response, _error, request) => [
          "SaleCountsByStatus",
          "Location",
          "CustomerAging",
          "TaxEntries",
          "JournalEntries",
          "ProfitAndLoss",
          "ColorxTintedProduct",
          ...(request.customerId
            ? [
                {
                  type: "CustomerCards" as const,
                  id: UUID.fromPB(request.customerId).toString(),
                },
                {
                  type: "Customer" as const,
                  id: UUID.fromPB(request.customerId).toString(),
                },
                {
                  type: "RecentJobSites" as const,
                  id: UUID.fromPB(request.customerId).toString(),
                },
              ]
            : []),
        ],
      }),
      updateSoldSaleDetails: builder.mutation<
        UpdateSoldSaleDetailsRes,
        UpdateSoldSaleDetailsReq
      >({
        query: (r) => s.updateSoldSaleDetails(r),
        invalidatesTags: (_response, _error, request) => [
          makeSaletag(request.id),
        ],
      }),
      updateSoldSaleProduct: builder.mutation<
        UpdateSoldSaleProductRes,
        UpdateSoldSaleProductReq
      >({
        query: (r) => s.updateSoldSaleProduct(r),
        invalidatesTags: (_response, _error, request) => [
          makeSaletag(request.saleId),
        ],
      }),
      updateSaleProductCost: builder.mutation<
        UpdateSaleProductCostRes,
        UpdateSaleProductCostReq & { saleId?: PBUUID }
      >({
        query: (r) => s.updateSaleProductCost(r),
        invalidatesTags: (_response, _error, request) => [
          makeSaletag(request.saleId),
        ],
      }),
      duplicateSale: builder.mutation<DuplicateSaleRes, DuplicateSaleReq>({
        query: (r) => s.duplicateSale(r),
        invalidatesTags: (_response, _error) => ["SaleCountsByStatus"],
      }),
      updateUnpaidChargeAccountSaleDate: builder.mutation<
        UpdateUnpaidChargeAccountSaleDateRes,
        UpdateUnpaidChargeAccountSaleDateReq
      >({
        query: (r) => s.updateUnpaidChargeAccountSaleDate(r),
        invalidatesTags: (_response, _error, request) => [
          makeSaletag(request.id),
        ],
      }),
      // Returns
      returnSale: builder.mutation<ReturnSaleRes, ReturnSaleReq>({
        query: (r) => s.returnSale(r),
        invalidatesTags: (_response, _error, request) => [
          "Location",
          makeSaletag(request.saleId),
          "ReturnTotal",
        ],
      }),
      getReturn: builder.query<GetReturnRes, GetReturnReq>({
        query: (r) => s.getReturn(r),
        providesTags: (_response, _error, request) => [
          makeSaletag(request.saleId),
          makeReturnTag(request.saleId, request.returnIdentifier),
        ],
      }),
      getReturnPDF: builder.query<GetReturnPDFRes, GetReturnPDFReq>({
        query: (r) => s.getReturnPDF(r),
      }),
      emailReturnPDF: builder.mutation<EmailReturnPDFRes, EmailReturnPDFReq>({
        query: (r) => s.emailReturnPDF(r),
      }),
      updateReturnDetails: builder.mutation<
        UpdateReturnDetailsRes,
        UpdateReturnDetailsReq
      >({
        query: (r) => s.updateReturnDetails(r),
        invalidatesTags: (_response, _error, request) => [
          makeReturnTag(request.saleId, request.returnIdentifier),
          makeSaletag(request.saleId),
        ],
      }),
      updateSaleProductReturned: builder.mutation<
        UpdateSaleProductReturnedRes,
        UpdateSaleProductReturnedReq
      >({
        query: (r) => s.updateSaleProductReturned(r),
        invalidatesTags: (_response, _error, request) => [
          makeReturnTag(request.saleId, request.returnIdentifier),
          makeSaletag(request.saleId),
        ],
      }),
      calculateReturnTotals: builder.query<
        CalculateReturnTotalsRes,
        CalculateReturnTotalsReq
      >({
        query: (r) => s.calculateReturnTotals(r),
        providesTags: ["ReturnTotal"],
      }),
      updateSaleProductReturnedCost: builder.mutation<
        UpdateSaleProductReturnedCostRes,
        UpdateSaleProductReturnedCostReq
      >({
        query: (r) => s.updateSaleProductReturnedCost(r),
        invalidatesTags: (_response, _error, request) => [
          makeReturnTag(request.saleId, request.returnIdentifier),
          makeSaletag(request.saleId),
        ],
      }),
    }),
  })

export const {
  usePrefetch: useSalePrefetch,
  useGetSaleQuery,
  useLazyGetScannedSaleQuery,
  useDownloadItemizedSalesCSVMutation,
  useLazyGetSaleQuery,
  useGetSaleCountsByStatusQuery,
  useLazyGetSaleCountsByStatusQuery,
  useUpsertSaleV3Mutation,
  useReturnSaleMutation,
  useSyncCartPricesMutation,
  useEmailSalePDFMutation,
  useGetSalePDFQuery,
  useLazyGetSalePDFQuery,
  useUpdateSoldSaleProductMutation,
  useUpdateSoldSaleDetailsMutation,
  useAuthorizeCardPaymentMutation,
  useSetCardReaderDisplayMutation,
  useUpdateSaleProductCostMutation,
  useDuplicateSaleMutation,
  useUpdateUnpaidChargeAccountSaleDateMutation,
  useGetReturnQuery,
  useLazyGetReturnPDFQuery,
  useEmailReturnPDFMutation,
  useUpdateReturnDetailsMutation,
  useUpdateSaleProductReturnedMutation,
  useUpdateSaleProductReturnedCostMutation,
  useCalculateReturnTotalsQuery,
} = salesApiSlice
