// @ts-strict-ignore
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  Transfer_Product as TransferProduct,
  Transfer_Status as TransferStatus,
} from "gen/txn/models_pb"
import {
  checkProductsEquality,
  fixTxnProductsOrders,
} from "../transactions/helpers"

import { AppState } from "app/store"
import { GetTransferRes } from "gen/api/transfer/service_pb"
import { Location } from "gen/company/models_pb"
import { Timestamp as TimestampPB } from "gen/google/protobuf/timestamp_pb"
import { TransactionProduct } from "../transactions/transactionProduct"
import TransferForm from "./types/TransferForm"
import { UUID } from "uuid-rd"
import { stringify } from "lib/stringify"

export interface TransferFormState extends TransferForm {
  initialState?: TransferForm
  isEdited?: boolean
  scrollToBottom?: boolean
}

const initialState: TransferFormState = {}

const checkTransferIsEdited = (state: TransferFormState) => {
  const initState = { ...state.initialState }
  const currState = { ...state, isEdited: undefined, initialState: undefined }

  return !checkStateEquality(initState ?? {}, currState)
}

const checkStateEquality = (
  stateA: TransferFormState,
  stateB: TransferFormState
) =>
  UUID.eqFromPB(
    stateA.transferFromLocation?.id,
    stateB.transferFromLocation?.id
  ) &&
  UUID.eqFromPB(stateA.transferToLocation?.id, stateB.transferToLocation?.id) &&
  checkProductsEquality(
    Object.values(stateA.transferProductsById || {}),
    Object.values(stateB.transferProductsById || {})
  ) &&
  stateA.notes === stateB.notes &&
  stringify(stateA.searchProduct) === stringify(stateB.searchProduct) &&
  stateA.addProductQuantity === stateB.addProductQuantity &&
  TimestampPB.equals(stateA.requestDate, stateB.requestDate)

function seedProductsById(
  productsById?: Record<string, TransferProduct>,
  status?: TransferStatus
) {
  if (status !== TransferStatus.REQUESTED && status !== TransferStatus.SENT) {
    return productsById
  }

  if (!productsById) {
    return {}
  }

  const seededProductsById: Record<string, TransferProduct> = {}
  for (const [id, product] of Object.entries(productsById)) {
    switch (status) {
      case TransferStatus.REQUESTED:
        seededProductsById[id] = {
          ...product,
          quantitySent: product.quantityRequested,
        }
        continue
      case TransferStatus.SENT:
        seededProductsById[id] = {
          ...product,
          quantityReceived: product.quantitySent,
        }
        continue
      default:
        seededProductsById[id] = { ...product }
        continue
    }
  }

  return seededProductsById
}

export const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    setTransferInitialState: (state, action: PayloadAction<GetTransferRes>) => {
      state.initialState = {
        inventoriesByProductId: action.payload.inventoriesByProductId,
        productsById: action.payload.productsById,
        catalogProductsById: action.payload.catalogProductsById,
        notes: action.payload.transfer?.notes,
        transferFromLocation: action.payload.fromLocation,
        transferToLocation: action.payload.toLocation,
        transferProductsById: seedProductsById(
          action.payload.transfer?.productsById,
          action.payload.transfer?.status
        ),
      }
      state.inventoriesByProductId = action.payload.inventoriesByProductId
      state.isEdited = false
      state.productsById = action.payload.productsById
      state.catalogProductsById = action.payload.catalogProductsById
      state.notes = action.payload.transfer?.notes
      state.transferFromLocation = action.payload.fromLocation
      state.transferProductsById = seedProductsById(
        action.payload.transfer?.productsById,
        action.payload.transfer?.status
      )
      state.transferToLocation = action.payload.toLocation
      state.searchProduct = undefined
      state.addProductQuantity = 1
      state.requestDate = action.payload.transfer?.requestDate
    },
    resetTransferState: (state) => {
      state.notes = ""
      state.transferToLocation = undefined
      state.transferFromLocation = undefined
      state.transferProductsById = {}
      state.isEdited = false
      state.searchProduct = undefined
      state.addProductQuantity = 1
      state.requestDate = undefined
    },
    setTransferFromLocation: (
      state,
      action: PayloadAction<Location | undefined>
    ) => {
      if (
        !!action.payload?.id &&
        UUID.eqFromPB(action.payload?.id, state.transferToLocation?.id)
      ) {
        return
      }
      state.transferFromLocation = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferToLocation: (
      state,
      action: PayloadAction<Location | undefined>
    ) => {
      if (
        !!action.payload?.id &&
        UUID.eqFromPB(action.payload?.id, state.transferFromLocation?.id)
      ) {
        return
      }
      state.transferToLocation = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferNotes: (state, action: PayloadAction<string | undefined>) => {
      state.notes = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferRequestDate: (
      state,
      action: PayloadAction<TimestampPB | undefined>
    ) => {
      state.requestDate = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProducts: (
      state,
      action: PayloadAction<Record<string, TransferProduct>>
    ) => {
      state.transferProductsById = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProductQuantityRequested: (
      state,
      action: PayloadAction<TransferProduct>
    ) => {
      if (!action.payload.productId) {
        return
      }
      const payloadId = UUID.fromPB(action.payload.productId).toString()
      if (!state.transferProductsById?.[payloadId]) {
        return
      }

      state.transferProductsById![payloadId] = {
        ...state.transferProductsById![payloadId],
        quantityRequested: action.payload.quantityRequested,
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProductQuantitySent: (
      state,
      action: PayloadAction<TransferProduct>
    ) => {
      if (!action.payload.productId) {
        return
      }
      const payloadId = UUID.fromPB(action.payload.productId).toString()
      if (!state.transferProductsById?.[payloadId]) {
        return
      }

      state.transferProductsById![payloadId] = {
        ...state.transferProductsById![payloadId],
        quantitySent: action.payload.quantitySent,
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    setTransferProductQuantity: (
      state,
      action: PayloadAction<TransferProduct>
    ) => {
      if (!action.payload.productId) {
        return
      }

      const payloadId = UUID.fromPB(action.payload.productId).toString()
      if (!state.transferProductsById?.[payloadId]) {
        return
      }

      state.transferProductsById![payloadId] = {
        ...state.transferProductsById![payloadId],
        quantityReceived: action.payload.quantityReceived,
        quantitySent: action.payload.quantitySent,
        quantityRequested: action.payload.quantityRequested,
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    setNewTransferProduct: (state, action: PayloadAction<TransferProduct>) => {
      if (!action.payload.productId) {
        return
      }

      if (!state.transferProductsById) {
        state.transferProductsById = {}
      }

      const payloadId = UUID.fromPB(action.payload.productId).toString()

      state.transferProductsById[payloadId] = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
    removeTransferProduct: (state, action: PayloadAction<string>) => {
      if (state.transferProductsById?.[action.payload]) {
        delete state.transferProductsById[action.payload]
        state.transferProductsById = fixTxnProductsOrders(
          state.transferProductsById
        )
      }

      if (state.productsById?.[action.payload]) {
        delete state.productsById[action.payload]
      }

      if (state.catalogProductsById?.[action.payload]) {
        delete state.catalogProductsById[action.payload]
      }

      if (state.inventoriesByProductId?.[action.payload]) {
        delete state.inventoriesByProductId[action.payload]
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    searchProductSelected: (
      state,
      action: PayloadAction<{
        result: TransactionProduct
      }>
    ) => {
      const { result } = action.payload
      if (!result || !result?.product?.id) {
        return
      }
      state.searchProduct = result

      if (result.isScan && result.quantity > 0) {
        state.addProductQuantity = result.quantity
      }

      state.isEdited = checkTransferIsEdited(state)
    },
    searchProductSubmitted: (
      state,
      action: PayloadAction<{
        status?: TransferStatus
      }>
    ) => {
      const { status } = action.payload

      if (!state.addProductQuantity || !state.searchProduct?.product?.id) {
        return
      }

      if (!state.transferProductsById) {
        state.transferProductsById = {}
      }

      if (!state.productsById) {
        state.productsById = {}
      }

      if (!state.catalogProductsById) {
        state.catalogProductsById = {}
      }

      if (!state.inventoriesByProductId) {
        state.inventoriesByProductId = {}
      }

      const payloadId = UUID.fromPB(state.searchProduct.product.id).toString()

      const existingTransferProduct = state.transferProductsById?.[payloadId]

      if (existingTransferProduct) {
        state.transferProductsById[payloadId] = {
          ...existingTransferProduct,
          quantitySent:
            status === TransferStatus.SENT
              ? existingTransferProduct.quantitySent
              : existingTransferProduct.quantitySent + state.addProductQuantity,
          quantityReceived:
            status === TransferStatus.SENT
              ? existingTransferProduct.quantityReceived +
                state.addProductQuantity
              : 0,
          quantityRequested:
            status === TransferStatus.SENT
              ? 0
              : existingTransferProduct.quantityRequested +
                state.addProductQuantity,
          position: Object.values(state.transferProductsById).length + 1,
        }
      } else {
        state.transferProductsById[payloadId] = {
          productId: state.searchProduct.product.id,
          quantitySent:
            status === TransferStatus.SENT ? 0 : state.addProductQuantity,
          quantityReceived:
            status === TransferStatus.SENT ? state.addProductQuantity : 0,
          position: Object.values(state.transferProductsById).length + 1,
          quantityRequested:
            status === TransferStatus.SENT ? 0 : state.addProductQuantity,
        }
      }

      state.transferProductsById = fixTxnProductsOrders(
        state.transferProductsById
      )

      state.productsById = {
        ...state.productsById,
        [UUID.fromPB(state.searchProduct.product.id).toString()]:
          state.searchProduct.product,
      }

      if (state.searchProduct.matchedCostProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedCostProduct.id).toString()]:
            state.searchProduct.matchedCostProduct,
        }
      }

      if (state.searchProduct.matchedPriceProduct?.id) {
        state.productsById = {
          ...state.productsById,
          [UUID.fromPB(state.searchProduct.matchedPriceProduct.id).toString()]:
            state.searchProduct.matchedPriceProduct,
        }
      }

      if (state.searchProduct.catalogProduct) {
        state.catalogProductsById = {
          ...state.catalogProductsById,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.catalogProduct,
        }
      }

      if (state.searchProduct.inventories) {
        state.inventoriesByProductId = {
          ...state.inventoriesByProductId,
          [UUID.fromPB(state.searchProduct.product.id).toString()]:
            state.searchProduct.inventories,
        }
      }

      state.scrollToBottom = true
      state.addProductQuantity = 1
      delete state.searchProduct
      state.isEdited = checkTransferIsEdited(state)
    },
    setScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.scrollToBottom = action.payload
    },
    resetSearchProductSelected: (state) => {
      delete state.searchProduct
      state.isEdited = checkTransferIsEdited(state)
    },
    setAddProductQuantity: (state, action: PayloadAction<number>) => {
      state.addProductQuantity = action.payload
      state.isEdited = checkTransferIsEdited(state)
    },
  },
})

export const {
  removeTransferProduct,
  resetTransferState,
  setNewTransferProduct,
  setTransferFromLocation,
  setTransferInitialState,
  setTransferProducts,
  setTransferNotes,
  setTransferToLocation,
  searchProductSelected,
  setTransferProductQuantity,
  setScrollToBottom,
  searchProductSubmitted,
  resetSearchProductSelected,
  setAddProductQuantity,
  setTransferRequestDate,
  setTransferProductQuantityRequested,
  setTransferProductQuantitySent,
} = transfersSlice.actions

export const selectTransferFromLocation = (state: AppState) =>
  state.transfers.transferFromLocation
export const selectTransferInitialState = (state: AppState) =>
  state.transfers.initialState
export const selectTransferInventoriesByProductId = (state: AppState) =>
  state.transfers.inventoriesByProductId
export const selectTransferIsEdited = (state: AppState) =>
  state.transfers.isEdited
export const selectTransferProducts = (state: AppState) =>
  state.transfers.transferProductsById
export const selectTransferProductsById = (state: AppState) =>
  state.transfers.productsById
export const selectTransferCatalogProductsById = (state: AppState) =>
  state.transfers.catalogProductsById
export const selectTransferNotes = (state: AppState) => state.transfers.notes
export const selectTransferToLocation = (state: AppState) =>
  state.transfers.transferToLocation
export const selectScrollToBottom = (state: AppState) =>
  state.transfers.scrollToBottom
export const selectSearchProduct = (state: AppState) =>
  state.transfers.searchProduct
export const selectAddProductQuantity = (state: AppState) =>
  state.transfers.addProductQuantity
export const selectTransferRequestDate = (state: AppState) =>
  state.transfers.requestDate
