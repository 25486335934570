import { UUID as PBUUID } from "gen/proto/uuid/models_pb"
import { UUID } from "uuid-rd"

export function vendor(id?: PBUUID): string {
  return `/pos/vendors/${UUID.idString(id)}`
}

export function vendors(): string {
  return "/pos/vendors?status=2"
}
