/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,add_pb_suffix,ts_nocheck,eslint_disable
// @generated from protobuf file "product/models.proto" (package "rd.product", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Money } from "../proto/money/models_pb";
import { Decimal } from "../proto/decimal/models_pb";
import { FractionalMoney } from "../proto/money/models_pb";
import { User } from "../user/models_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { UUID } from "../proto/uuid/models_pb";
/**
 * Product represents a good sold by a Company.
 *
 * @generated from protobuf message rd.product.Product
 */
export interface Product {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 4;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.user.User created_by = 5;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 6;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID matched_cost_product_id = 8;
     */
    matchedCostProductId?: UUID;
    /**
     * @generated from protobuf field: string additional_fee_id_string = 10;
     */
    additionalFeeIdString: string;
    /**
     * First identifier in the list is the primary identifier
     *
     * @generated from protobuf field: repeated string identifiers = 11;
     */
    identifiers: string[];
    /**
     * @generated from protobuf field: string name = 12;
     */
    name: string;
    /**
     * @generated from protobuf field: bool is_commissionable = 14;
     */
    isCommissionable: boolean;
    /**
     * @generated from protobuf field: bool is_tintable = 15;
     */
    isTintable: boolean;
    /**
     * @generated from protobuf field: bool is_active = 16;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: repeated rd.product.Product.Cost costs = 18;
     */
    costs: Product_Cost[];
    /**
     * @generated from protobuf field: int32 case_quantity = 21;
     */
    caseQuantity: number;
    /**
     * @generated from protobuf field: map<string, rd.product.Product.OrderSettings> order_settings_by_location_id = 22;
     */
    orderSettingsByLocationId: {
        [key: string]: Product_OrderSettings;
    };
    /**
     * @generated from protobuf field: map<string, string> bin_locations_by_location_id = 24;
     */
    binLocationsByLocationId: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated rd.product.Product.QuantityBreak quantity_breaks = 25;
     */
    quantityBreaks: Product_QuantityBreak[];
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney average_cost = 26;
     */
    averageCost?: FractionalMoney;
    /**
     * @generated from protobuf field: bool is_special_orderable = 28;
     */
    isSpecialOrderable: boolean;
    /**
     * @generated from protobuf field: rd.product.Product.TaxStatus tax_status = 29;
     */
    taxStatus: Product_TaxStatus;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID department_id = 30;
     */
    departmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID class_id = 31;
     */
    classId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID fineline_id = 32;
     */
    finelineId?: UUID;
    /**
     * @generated from protobuf field: string notes = 33;
     */
    notes: string;
    /**
     * @generated from protobuf field: string sales_reminder = 34;
     */
    salesReminder: string;
    /**
     * @generated from protobuf field: repeated rd.proto.uuid.UUID tag_ids = 35;
     */
    tagIds: UUID[];
}
/**
 * @generated from protobuf message rd.product.Product.Cost
 */
export interface Product_Cost {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID vendor_id = 1;
     */
    vendorId?: UUID;
    /**
     * @generated from protobuf field: bool is_default = 3;
     */
    isDefault: boolean;
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney cost_v2 = 4;
     */
    costV2?: FractionalMoney;
}
/**
 * @generated from protobuf message rd.product.Product.OrderSettings
 */
export interface Product_OrderSettings {
    /**
     * @generated from protobuf field: int32 target_stock = 1;
     */
    targetStock: number;
    /**
     * @generated from protobuf field: int32 reorder_point = 2;
     */
    reorderPoint: number;
}
/**
 * @generated from protobuf message rd.product.Product.QuantityBreak
 */
export interface Product_QuantityBreak {
    /**
     * @generated from protobuf field: int32 quantity = 1;
     */
    quantity: number;
    /**
     * @generated from protobuf field: string identifier = 2;
     */
    identifier: string;
    /**
     * @generated from protobuf field: rd.proto.decimal.Decimal discount = 3;
     */
    discount?: Decimal;
}
/**
 * @generated from protobuf enum rd.product.Product.TaxStatus
 */
export enum Product_TaxStatus {
    /**
     * @generated from protobuf enum value: TAX_STATUS_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TAX_STATUS_NON_TAXABLE = 1;
     */
    NON_TAXABLE = 1,
    /**
     * @generated from protobuf enum value: TAX_STATUS_SINGLE_USE_TAX_EXEMPT = 2;
     */
    SINGLE_USE_TAX_EXEMPT = 2,
    /**
     * @generated from protobuf enum value: TAX_STATUS_TAXABLE = 3;
     */
    TAXABLE = 3
}
/**
 * ProductLog represents a change of a Product.
 *
 * @generated from protobuf message rd.product.ProductLog
 */
export interface ProductLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.product.Product product = 4;
     */
    product?: Product;
}
/**
 * IdentifierMapping stores product identifier assignments, one row per
 * identifier. The id is derived from the company id and identifier string.
 *
 * @generated from protobuf message rd.product.IdentifierMapping
 */
export interface IdentifierMapping {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 3;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: string identifier = 4;
     */
    identifier: string;
}
/**
 * StockStatusDelta is used to store changes of stock status
 *
 * @generated from protobuf message rd.product.StockStatusDelta
 */
export interface StockStatusDelta {
    /**
     * @generated from protobuf field: map<int32, int32> StatusDelta = 1 [json_name = "StatusDelta"];
     */
    statusDelta: {
        [key: number]: number;
    }; // key is used to indicate an inventory status, and value is
}
/**
 * Inventories represents the counts of Products at LocationIds.
 *
 * @generated from protobuf message rd.product.Inventories
 */
export interface Inventories {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // Product ID
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: map<string, int32> inventories_by_location_id = 4;
     */
    inventoriesByLocationId: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 5;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: map<string, int32> on_hand_inventories_by_location_id = 6;
     */
    onHandInventoriesByLocationId: {
        [key: string]: number;
    }; // inventory available for sale
    /**
     * @generated from protobuf field: map<string, int32> on_order_inventories_by_location_id = 7;
     */
    onOrderInventoriesByLocationId: {
        [key: string]: number;
    }; // inventory expected to be received
    /**
     * @generated from protobuf field: map<string, int32> committed_inventories_by_location_id = 8;
     */
    committedInventoriesByLocationId: {
        [key: string]: number;
    }; // inventory designated to leave
}
/**
 * InventoryLog represents changes to a Product's Inventory.
 *
 * @generated from protobuf message rd.product.InventoriesLog
 */
export interface InventoriesLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 4;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 5;
     */
    orderId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 6;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: rd.product.Inventories inventories = 7;
     */
    inventories?: Inventories;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 8;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID order_shipment_id = 9;
     */
    orderShipmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 10;
     */
    saleProductId?: UUID;
}
/**
 * InventoryChange is a record of a change made to a Product's inventory.
 * A change is recorded whenever a product is sold, returned, transferred, received as part of an
 * order shipment, counted or manually edited.
 * A manual edit is recorded when a product is created, exported from a client's old system
 * or when it's inventory is manually edited.
 *
 * @generated from protobuf message rd.product.InventoryChange
 */
export interface InventoryChange {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 2;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 3;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 4;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_id = 5;
     */
    saleId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID shipment_id = 6;
     */
    shipmentId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID count_id = 7;
     */
    countId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID transfer_id = 8;
     */
    transferId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 9;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User staff = 10;
     */
    staff?: User;
    /**
     * @generated from protobuf field: rd.product.InventoryChange.Type type = 11;
     */
    type: InventoryChange_Type;
    /**
     * @generated from protobuf field: int32 change = 12;
     */
    change: number;
    /**
     * @generated from protobuf field: int32 quantity = 13;
     */
    quantity: number; // inventory quantity AFTER inventory change
    /**
     * @generated from protobuf field: rd.proto.money.FractionalMoney average_cost = 14;
     */
    averageCost?: FractionalMoney;
    /**
     * non-nil only for returns, order shipments, and product creation manual edits
     *
     * @generated from protobuf field: rd.proto.money.FractionalMoney received_cost = 15;
     */
    receivedCost?: FractionalMoney;
    /**
     * @generated from protobuf field: string txn_identifier = 16;
     */
    txnIdentifier: string; // only applicable for order shipments, transfers, returns and sales
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID sale_product_id = 17;
     */
    saleProductId?: UUID;
    /**
     * @generated from protobuf field: bool is_cost_edit = 18;
     */
    isCostEdit: boolean;
    /**
     * @generated from protobuf field: rd.product.StockStatus inventory_status = 19;
     */
    inventoryStatus: StockStatus; // indicate what status the changed is involved
    /**
     * @generated from protobuf field: map<int32, int32> status_quantities = 20;
     */
    statusQuantities: {
        [key: number]: number;
    }; // used to store quantities of inventory status for the location
    /**
     * at the time when inventory change is created
     *
     * @generated from protobuf field: rd.proto.uuid.UUID order_id = 21;
     */
    orderId?: UUID;
}
/**
 * @generated from protobuf enum rd.product.InventoryChange.Type
 */
export enum InventoryChange_Type {
    /**
     * @generated from protobuf enum value: TYPE_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: TYPE_SALE = 1;
     */
    SALE = 1,
    /**
     * @generated from protobuf enum value: TYPE_RETURN = 2;
     */
    RETURN = 2,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_SHIPMENT = 3;
     */
    ORDER_SHIPMENT = 3,
    /**
     * @generated from protobuf enum value: TYPE_TRANSFER = 4;
     */
    TRANSFER = 4,
    /**
     * @generated from protobuf enum value: TYPE_COUNT = 5;
     */
    COUNT = 5,
    /**
     * @generated from protobuf enum value: TYPE_MANUAL_EDIT = 6;
     */
    MANUAL_EDIT = 6,
    /**
     * @generated from protobuf enum value: TYPE_ORDER_ORDERED = 7;
     */
    ORDER_ORDERED = 7,
    /**
     * @generated from protobuf enum value: TYPE_SALE_WILL_CALL = 8;
     */
    SALE_WILL_CALL = 8
}
/**
 * ProductLabel represents the price label of a product at a given location.
 *
 * @generated from protobuf message rd.product.ProductLabel
 */
export interface ProductLabel {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 4;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 5;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp price_updated_at = 6;
     */
    priceUpdatedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp last_printed_at = 7;
     */
    lastPrintedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.proto.money.Money last_printed_price = 8;
     */
    lastPrintedPrice?: Money;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 9;
     */
    companyId?: UUID;
}
/**
 * InventoryCount is a record of an inventory count.
 *
 * @generated from protobuf message rd.product.InventoryCount
 */
export interface InventoryCount {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 4;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 5;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: repeated rd.product.InventoryCount.UpdateHistory update_history = 6;
     */
    updateHistory: InventoryCount_UpdateHistory[];
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 7;
     */
    locationId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 8;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: string name = 9;
     */
    name: string;
    /**
     * @generated from protobuf field: rd.product.InventoryCount.Status status = 10;
     */
    status: InventoryCount_Status;
    /**
     * @generated from protobuf field: rd.user.User accepted_by = 11;
     */
    acceptedBy?: User;
    /**
     * @generated from protobuf field: rd.user.User voided_by = 12;
     */
    voidedBy?: User;
    /**
     * @generated from protobuf field: string notes = 13;
     */
    notes: string;
    /**
     * @generated from protobuf field: rd.product.InventoryCount.Type type = 14;
     */
    type: InventoryCount_Type;
}
/**
 * @generated from protobuf message rd.product.InventoryCount.UpdateHistory
 */
export interface InventoryCount_UpdateHistory {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 1;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 2;
     */
    updatedBy?: User;
}
/**
 * @generated from protobuf enum rd.product.InventoryCount.Status
 */
export enum InventoryCount_Status {
    /**
     * @generated from protobuf enum value: UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ACCEPTED = 2;
     */
    ACCEPTED = 2,
    /**
     * @generated from protobuf enum value: VOIDED = 3;
     */
    VOIDED = 3
}
/**
 * @generated from protobuf enum rd.product.InventoryCount.Type
 */
export enum InventoryCount_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: TYPE_FULL = 1;
     */
    FULL = 1,
    /**
     * @generated from protobuf enum value: TYPE_CYCLE = 2;
     */
    CYCLE = 2
}
/**
 * InventoryCountLog is a record of a change to an inventory count.
 *
 * @generated from protobuf message rd.product.InventoryCountLog
 */
export interface InventoryCountLog {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 3;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.product.InventoryCount inventory_count = 4;
     */
    inventoryCount?: InventoryCount;
}
/**
 * Inventory Count Products Table
 *
 * @generated from protobuf message rd.product.InventoryCountProduct
 */
export interface InventoryCountProduct {
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID id = 1;
     */
    id?: UUID; // Product ID Xor Inventory Count ID
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated_at = 3;
     */
    updatedAt?: Timestamp;
    /**
     * @generated from protobuf field: rd.user.User created_by = 4;
     */
    createdBy?: User;
    /**
     * @generated from protobuf field: rd.user.User updated_by = 5;
     */
    updatedBy?: User;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID inventory_count_id = 6;
     */
    inventoryCountId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID product_id = 7;
     */
    productId?: UUID;
    /**
     * @generated from protobuf field: int32 count = 8;
     */
    count: number; // deprecated
    /**
     * @generated from protobuf field: rd.product.InventoryCountProduct.Status status = 9;
     */
    status: InventoryCountProduct_Status;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID company_id = 10;
     */
    companyId?: UUID;
    /**
     * @generated from protobuf field: rd.proto.uuid.UUID location_id = 11;
     */
    locationId?: UUID;
    /**
     * The variance is stored instead of the counted value so that the difference
     * between the system's reported amount and the counted amount stays fixed.
     *
     * @generated from protobuf field: int32 variance = 12;
     */
    variance: number;
    /**
     * @generated from protobuf field: int32 finalized_count = 13;
     */
    finalizedCount: number;
}
/**
 * @generated from protobuf enum rd.product.InventoryCountProduct.Status
 */
export enum InventoryCountProduct_Status {
    /**
     * @generated from protobuf enum value: UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: UNCOUNTED = 1;
     */
    UNCOUNTED = 1,
    /**
     * @generated from protobuf enum value: COUNTED = 2;
     */
    COUNTED = 2,
    /**
     * @generated from protobuf enum value: REMOVED = 3;
     */
    REMOVED = 3,
    /**
     * @generated from protobuf enum value: FINALIZED = 4;
     */
    FINALIZED = 4
}
/**
 * @generated from protobuf enum rd.product.StockStatus
 */
export enum StockStatus {
    /**
     * inventory available for sale
     *
     * @generated from protobuf enum value: STATUS_ON_HAND = 0;
     */
    STATUS_ON_HAND = 0,
    /**
     * inventory expected to be received
     *
     * @generated from protobuf enum value: STATUS_ON_ORDER = 1;
     */
    STATUS_ON_ORDER = 1,
    /**
     * inventory designated to leave
     *
     * @generated from protobuf enum value: STATUS_COMMITTED = 2;
     */
    STATUS_COMMITTED = 2
}
/**
 * LabelTemplate represents the different types of label templates available for printing product
 * labels. Each is a specific branded template that has a specific layout and dimensions.
 *
 * @generated from protobuf enum rd.product.LabelTemplate
 */
export enum LabelTemplate {
    /**
     * @generated from protobuf enum value: LABEL_TEMPLATE_UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * 1" x 2 5/8" labels (full sheet)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_AVERY_5160 = 1;
     */
    AVERY_5160 = 1,
    /**
     * 1" x 2 1/8" labels (roll)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_DYMO_30336 = 2;
     */
    DYMO_30336 = 2,
    /**
     * 1.25" x 1.93" labels (full sheet)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_CENTURION_UNG4 = 3;
     */
    CENTURION_UNG4 = 3,
    /**
     * 1.25" x 2" labels (full sheet)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_ULINE_S20247 = 4;
     */
    ULINE_S20247 = 4,
    /**
     * 1.125" x 2" labels (roll)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_CENTURION_CSGENYRR = 5;
     */
    CENTURION_CSGENYRR = 5,
    /**
     * 1.25" x 2.325" labels (roll)
     *
     * @generated from protobuf enum value: LABEL_TEMPLATE_CENTURION_40030 = 6;
     */
    CENTURION_40030 = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class Product$Type extends MessageType<Product> {
    constructor() {
        super("rd.product.Product", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "company_id", kind: "message", T: () => UUID },
            { no: 5, name: "created_by", kind: "message", T: () => User },
            { no: 6, name: "updated_by", kind: "message", T: () => User },
            { no: 8, name: "matched_cost_product_id", kind: "message", T: () => UUID },
            { no: 10, name: "additional_fee_id_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "identifiers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "is_commissionable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "is_tintable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "costs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product_Cost },
            { no: 21, name: "case_quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "order_settings_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Product_OrderSettings } },
            { no: 24, name: "bin_locations_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 25, name: "quantity_breaks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Product_QuantityBreak },
            { no: 26, name: "average_cost", kind: "message", T: () => FractionalMoney },
            { no: 28, name: "is_special_orderable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "tax_status", kind: "enum", T: () => ["rd.product.Product.TaxStatus", Product_TaxStatus, "TAX_STATUS_"] },
            { no: 30, name: "department_id", kind: "message", T: () => UUID },
            { no: 31, name: "class_id", kind: "message", T: () => UUID },
            { no: 32, name: "fineline_id", kind: "message", T: () => UUID },
            { no: 33, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 34, name: "sales_reminder", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 35, name: "tag_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<Product>): Product {
        const message = { additionalFeeIdString: "", identifiers: [], name: "", isCommissionable: false, isTintable: false, isActive: false, costs: [], caseQuantity: 0, orderSettingsByLocationId: {}, binLocationsByLocationId: {}, quantityBreaks: [], isSpecialOrderable: false, taxStatus: 0, notes: "", salesReminder: "", tagIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product): Product {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 4:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.user.User created_by */ 5:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 6:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.proto.uuid.UUID matched_cost_product_id */ 8:
                    message.matchedCostProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.matchedCostProductId);
                    break;
                case /* string additional_fee_id_string */ 10:
                    message.additionalFeeIdString = reader.string();
                    break;
                case /* repeated string identifiers */ 11:
                    message.identifiers.push(reader.string());
                    break;
                case /* string name */ 12:
                    message.name = reader.string();
                    break;
                case /* bool is_commissionable */ 14:
                    message.isCommissionable = reader.bool();
                    break;
                case /* bool is_tintable */ 15:
                    message.isTintable = reader.bool();
                    break;
                case /* bool is_active */ 16:
                    message.isActive = reader.bool();
                    break;
                case /* repeated rd.product.Product.Cost costs */ 18:
                    message.costs.push(Product_Cost.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 case_quantity */ 21:
                    message.caseQuantity = reader.int32();
                    break;
                case /* map<string, rd.product.Product.OrderSettings> order_settings_by_location_id */ 22:
                    this.binaryReadMap22(message.orderSettingsByLocationId, reader, options);
                    break;
                case /* map<string, string> bin_locations_by_location_id */ 24:
                    this.binaryReadMap24(message.binLocationsByLocationId, reader, options);
                    break;
                case /* repeated rd.product.Product.QuantityBreak quantity_breaks */ 25:
                    message.quantityBreaks.push(Product_QuantityBreak.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.money.FractionalMoney average_cost */ 26:
                    message.averageCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.averageCost);
                    break;
                case /* bool is_special_orderable */ 28:
                    message.isSpecialOrderable = reader.bool();
                    break;
                case /* rd.product.Product.TaxStatus tax_status */ 29:
                    message.taxStatus = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID department_id */ 30:
                    message.departmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.departmentId);
                    break;
                case /* rd.proto.uuid.UUID class_id */ 31:
                    message.classId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.classId);
                    break;
                case /* rd.proto.uuid.UUID fineline_id */ 32:
                    message.finelineId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.finelineId);
                    break;
                case /* string notes */ 33:
                    message.notes = reader.string();
                    break;
                case /* string sales_reminder */ 34:
                    message.salesReminder = reader.string();
                    break;
                case /* repeated rd.proto.uuid.UUID tag_ids */ 35:
                    message.tagIds.push(UUID.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap22(map: Product["orderSettingsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Product["orderSettingsByLocationId"] | undefined, val: Product["orderSettingsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Product_OrderSettings.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Product.order_settings_by_location_id");
            }
        }
        map[key ?? ""] = val ?? Product_OrderSettings.create();
    }
    private binaryReadMap24(map: Product["binLocationsByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Product["binLocationsByLocationId"] | undefined, val: Product["binLocationsByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Product.bin_locations_by_location_id");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Product, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 4; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 5; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 6; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID matched_cost_product_id = 8; */
        if (message.matchedCostProductId)
            UUID.internalBinaryWrite(message.matchedCostProductId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string additional_fee_id_string = 10; */
        if (message.additionalFeeIdString !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.additionalFeeIdString);
        /* repeated string identifiers = 11; */
        for (let i = 0; i < message.identifiers.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.identifiers[i]);
        /* string name = 12; */
        if (message.name !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.name);
        /* bool is_commissionable = 14; */
        if (message.isCommissionable !== false)
            writer.tag(14, WireType.Varint).bool(message.isCommissionable);
        /* bool is_tintable = 15; */
        if (message.isTintable !== false)
            writer.tag(15, WireType.Varint).bool(message.isTintable);
        /* bool is_active = 16; */
        if (message.isActive !== false)
            writer.tag(16, WireType.Varint).bool(message.isActive);
        /* repeated rd.product.Product.Cost costs = 18; */
        for (let i = 0; i < message.costs.length; i++)
            Product_Cost.internalBinaryWrite(message.costs[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* int32 case_quantity = 21; */
        if (message.caseQuantity !== 0)
            writer.tag(21, WireType.Varint).int32(message.caseQuantity);
        /* map<string, rd.product.Product.OrderSettings> order_settings_by_location_id = 22; */
        for (let k of Object.keys(message.orderSettingsByLocationId)) {
            writer.tag(22, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Product_OrderSettings.internalBinaryWrite(message.orderSettingsByLocationId[k], writer, options);
            writer.join().join();
        }
        /* map<string, string> bin_locations_by_location_id = 24; */
        for (let k of Object.keys(message.binLocationsByLocationId))
            writer.tag(24, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.binLocationsByLocationId[k]).join();
        /* repeated rd.product.Product.QuantityBreak quantity_breaks = 25; */
        for (let i = 0; i < message.quantityBreaks.length; i++)
            Product_QuantityBreak.internalBinaryWrite(message.quantityBreaks[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney average_cost = 26; */
        if (message.averageCost)
            FractionalMoney.internalBinaryWrite(message.averageCost, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* bool is_special_orderable = 28; */
        if (message.isSpecialOrderable !== false)
            writer.tag(28, WireType.Varint).bool(message.isSpecialOrderable);
        /* rd.product.Product.TaxStatus tax_status = 29; */
        if (message.taxStatus !== 0)
            writer.tag(29, WireType.Varint).int32(message.taxStatus);
        /* rd.proto.uuid.UUID department_id = 30; */
        if (message.departmentId)
            UUID.internalBinaryWrite(message.departmentId, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID class_id = 31; */
        if (message.classId)
            UUID.internalBinaryWrite(message.classId, writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID fineline_id = 32; */
        if (message.finelineId)
            UUID.internalBinaryWrite(message.finelineId, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 33; */
        if (message.notes !== "")
            writer.tag(33, WireType.LengthDelimited).string(message.notes);
        /* string sales_reminder = 34; */
        if (message.salesReminder !== "")
            writer.tag(34, WireType.LengthDelimited).string(message.salesReminder);
        /* repeated rd.proto.uuid.UUID tag_ids = 35; */
        for (let i = 0; i < message.tagIds.length; i++)
            UUID.internalBinaryWrite(message.tagIds[i], writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.Product
 */
export const Product = new Product$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Product_Cost$Type extends MessageType<Product_Cost> {
    constructor() {
        super("rd.product.Product.Cost", [
            { no: 1, name: "vendor_id", kind: "message", T: () => UUID },
            { no: 3, name: "is_default", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "cost_v2", kind: "message", T: () => FractionalMoney }
        ]);
    }
    create(value?: PartialMessage<Product_Cost>): Product_Cost {
        const message = { isDefault: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product_Cost>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product_Cost): Product_Cost {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID vendor_id */ 1:
                    message.vendorId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.vendorId);
                    break;
                case /* bool is_default */ 3:
                    message.isDefault = reader.bool();
                    break;
                case /* rd.proto.money.FractionalMoney cost_v2 */ 4:
                    message.costV2 = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.costV2);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Product_Cost, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID vendor_id = 1; */
        if (message.vendorId)
            UUID.internalBinaryWrite(message.vendorId, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool is_default = 3; */
        if (message.isDefault !== false)
            writer.tag(3, WireType.Varint).bool(message.isDefault);
        /* rd.proto.money.FractionalMoney cost_v2 = 4; */
        if (message.costV2)
            FractionalMoney.internalBinaryWrite(message.costV2, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.Product.Cost
 */
export const Product_Cost = new Product_Cost$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Product_OrderSettings$Type extends MessageType<Product_OrderSettings> {
    constructor() {
        super("rd.product.Product.OrderSettings", [
            { no: 1, name: "target_stock", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "reorder_point", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Product_OrderSettings>): Product_OrderSettings {
        const message = { targetStock: 0, reorderPoint: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product_OrderSettings>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product_OrderSettings): Product_OrderSettings {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 target_stock */ 1:
                    message.targetStock = reader.int32();
                    break;
                case /* int32 reorder_point */ 2:
                    message.reorderPoint = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Product_OrderSettings, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 target_stock = 1; */
        if (message.targetStock !== 0)
            writer.tag(1, WireType.Varint).int32(message.targetStock);
        /* int32 reorder_point = 2; */
        if (message.reorderPoint !== 0)
            writer.tag(2, WireType.Varint).int32(message.reorderPoint);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.Product.OrderSettings
 */
export const Product_OrderSettings = new Product_OrderSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Product_QuantityBreak$Type extends MessageType<Product_QuantityBreak> {
    constructor() {
        super("rd.product.Product.QuantityBreak", [
            { no: 1, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "discount", kind: "message", T: () => Decimal }
        ]);
    }
    create(value?: PartialMessage<Product_QuantityBreak>): Product_QuantityBreak {
        const message = { quantity: 0, identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Product_QuantityBreak>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Product_QuantityBreak): Product_QuantityBreak {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantity */ 1:
                    message.quantity = reader.int32();
                    break;
                case /* string identifier */ 2:
                    message.identifier = reader.string();
                    break;
                case /* rd.proto.decimal.Decimal discount */ 3:
                    message.discount = Decimal.internalBinaryRead(reader, reader.uint32(), options, message.discount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Product_QuantityBreak, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantity = 1; */
        if (message.quantity !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantity);
        /* string identifier = 2; */
        if (message.identifier !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.identifier);
        /* rd.proto.decimal.Decimal discount = 3; */
        if (message.discount)
            Decimal.internalBinaryWrite(message.discount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.Product.QuantityBreak
 */
export const Product_QuantityBreak = new Product_QuantityBreak$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductLog$Type extends MessageType<ProductLog> {
    constructor() {
        super("rd.product.ProductLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "product", kind: "message", T: () => Product }
        ]);
    }
    create(value?: PartialMessage<ProductLog>): ProductLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductLog): ProductLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.product.Product product */ 4:
                    message.product = Product.internalBinaryRead(reader, reader.uint32(), options, message.product);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Product product = 4; */
        if (message.product)
            Product.internalBinaryWrite(message.product, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.ProductLog
 */
export const ProductLog = new ProductLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentifierMapping$Type extends MessageType<IdentifierMapping> {
    constructor() {
        super("rd.product.IdentifierMapping", [
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "product_id", kind: "message", T: () => UUID },
            { no: 4, name: "identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IdentifierMapping>): IdentifierMapping {
        const message = { identifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IdentifierMapping>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdentifierMapping): IdentifierMapping {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 3:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* string identifier */ 4:
                    message.identifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IdentifierMapping, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 3; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string identifier = 4; */
        if (message.identifier !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.identifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.IdentifierMapping
 */
export const IdentifierMapping = new IdentifierMapping$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StockStatusDelta$Type extends MessageType<StockStatusDelta> {
    constructor() {
        super("rd.product.StockStatusDelta", [
            { no: 1, name: "StatusDelta", kind: "map", jsonName: "StatusDelta", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<StockStatusDelta>): StockStatusDelta {
        const message = { statusDelta: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StockStatusDelta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StockStatusDelta): StockStatusDelta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, int32> StatusDelta = 1 [json_name = "StatusDelta"];*/ 1:
                    this.binaryReadMap1(message.statusDelta, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: StockStatusDelta["statusDelta"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof StockStatusDelta["statusDelta"] | undefined, val: StockStatusDelta["statusDelta"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.StockStatusDelta.StatusDelta");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: StockStatusDelta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, int32> StatusDelta = 1 [json_name = "StatusDelta"]; */
        for (let k of Object.keys(message.statusDelta))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Varint).int32(message.statusDelta[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.StockStatusDelta
 */
export const StockStatusDelta = new StockStatusDelta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Inventories$Type extends MessageType<Inventories> {
    constructor() {
        super("rd.product.Inventories", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 5, name: "company_id", kind: "message", T: () => UUID },
            { no: 6, name: "on_hand_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 7, name: "on_order_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 8, name: "committed_inventories_by_location_id", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<Inventories>): Inventories {
        const message = { inventoriesByLocationId: {}, onHandInventoriesByLocationId: {}, onOrderInventoriesByLocationId: {}, committedInventoriesByLocationId: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Inventories>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Inventories): Inventories {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* map<string, int32> inventories_by_location_id */ 4:
                    this.binaryReadMap4(message.inventoriesByLocationId, reader, options);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 5:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* map<string, int32> on_hand_inventories_by_location_id */ 6:
                    this.binaryReadMap6(message.onHandInventoriesByLocationId, reader, options);
                    break;
                case /* map<string, int32> on_order_inventories_by_location_id */ 7:
                    this.binaryReadMap7(message.onOrderInventoriesByLocationId, reader, options);
                    break;
                case /* map<string, int32> committed_inventories_by_location_id */ 8:
                    this.binaryReadMap8(message.committedInventoriesByLocationId, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: Inventories["inventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Inventories["inventoriesByLocationId"] | undefined, val: Inventories["inventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Inventories.inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap6(map: Inventories["onHandInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Inventories["onHandInventoriesByLocationId"] | undefined, val: Inventories["onHandInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Inventories.on_hand_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap7(map: Inventories["onOrderInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Inventories["onOrderInventoriesByLocationId"] | undefined, val: Inventories["onOrderInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Inventories.on_order_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap8(map: Inventories["committedInventoriesByLocationId"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Inventories["committedInventoriesByLocationId"] | undefined, val: Inventories["committedInventoriesByLocationId"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.Inventories.committed_inventories_by_location_id");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: Inventories, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* map<string, int32> inventories_by_location_id = 4; */
        for (let k of Object.keys(message.inventoriesByLocationId))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.inventoriesByLocationId[k]).join();
        /* rd.proto.uuid.UUID company_id = 5; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* map<string, int32> on_hand_inventories_by_location_id = 6; */
        for (let k of Object.keys(message.onHandInventoriesByLocationId))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.onHandInventoriesByLocationId[k]).join();
        /* map<string, int32> on_order_inventories_by_location_id = 7; */
        for (let k of Object.keys(message.onOrderInventoriesByLocationId))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.onOrderInventoriesByLocationId[k]).join();
        /* map<string, int32> committed_inventories_by_location_id = 8; */
        for (let k of Object.keys(message.committedInventoriesByLocationId))
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.committedInventoriesByLocationId[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.Inventories
 */
export const Inventories = new Inventories$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoriesLog$Type extends MessageType<InventoriesLog> {
    constructor() {
        super("rd.product.InventoriesLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "sale_id", kind: "message", T: () => UUID },
            { no: 5, name: "order_id", kind: "message", T: () => UUID },
            { no: 6, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 7, name: "inventories", kind: "message", T: () => Inventories },
            { no: 8, name: "inventory_count_id", kind: "message", T: () => UUID },
            { no: 9, name: "order_shipment_id", kind: "message", T: () => UUID },
            { no: 10, name: "sale_product_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<InventoriesLog>): InventoriesLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoriesLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoriesLog): InventoriesLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 4:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID order_id */ 5:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 6:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* rd.product.Inventories inventories */ 7:
                    message.inventories = Inventories.internalBinaryRead(reader, reader.uint32(), options, message.inventories);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 8:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* rd.proto.uuid.UUID order_shipment_id */ 9:
                    message.orderShipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderShipmentId);
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 10:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoriesLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 4; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_id = 5; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID transfer_id = 6; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.Inventories inventories = 7; */
        if (message.inventories)
            Inventories.internalBinaryWrite(message.inventories, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 8; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID order_shipment_id = 9; */
        if (message.orderShipmentId)
            UUID.internalBinaryWrite(message.orderShipmentId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_product_id = 10; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoriesLog
 */
export const InventoriesLog = new InventoriesLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryChange$Type extends MessageType<InventoryChange> {
    constructor() {
        super("rd.product.InventoryChange", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "company_id", kind: "message", T: () => UUID },
            { no: 3, name: "product_id", kind: "message", T: () => UUID },
            { no: 4, name: "location_id", kind: "message", T: () => UUID },
            { no: 5, name: "sale_id", kind: "message", T: () => UUID },
            { no: 6, name: "shipment_id", kind: "message", T: () => UUID },
            { no: 7, name: "count_id", kind: "message", T: () => UUID },
            { no: 8, name: "transfer_id", kind: "message", T: () => UUID },
            { no: 9, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "staff", kind: "message", T: () => User },
            { no: 11, name: "type", kind: "enum", T: () => ["rd.product.InventoryChange.Type", InventoryChange_Type, "TYPE_"] },
            { no: 12, name: "change", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "average_cost", kind: "message", T: () => FractionalMoney },
            { no: 15, name: "received_cost", kind: "message", T: () => FractionalMoney },
            { no: 16, name: "txn_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "sale_product_id", kind: "message", T: () => UUID },
            { no: 18, name: "is_cost_edit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "inventory_status", kind: "enum", T: () => ["rd.product.StockStatus", StockStatus] },
            { no: 20, name: "status_quantities", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 21, name: "order_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<InventoryChange>): InventoryChange {
        const message = { type: 0, change: 0, quantity: 0, txnIdentifier: "", isCostEdit: false, inventoryStatus: 0, statusQuantities: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryChange): InventoryChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 2:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 3:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 4:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID sale_id */ 5:
                    message.saleId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleId);
                    break;
                case /* rd.proto.uuid.UUID shipment_id */ 6:
                    message.shipmentId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.shipmentId);
                    break;
                case /* rd.proto.uuid.UUID count_id */ 7:
                    message.countId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.countId);
                    break;
                case /* rd.proto.uuid.UUID transfer_id */ 8:
                    message.transferId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.transferId);
                    break;
                case /* google.protobuf.Timestamp created_at */ 9:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User staff */ 10:
                    message.staff = User.internalBinaryRead(reader, reader.uint32(), options, message.staff);
                    break;
                case /* rd.product.InventoryChange.Type type */ 11:
                    message.type = reader.int32();
                    break;
                case /* int32 change */ 12:
                    message.change = reader.int32();
                    break;
                case /* int32 quantity */ 13:
                    message.quantity = reader.int32();
                    break;
                case /* rd.proto.money.FractionalMoney average_cost */ 14:
                    message.averageCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.averageCost);
                    break;
                case /* rd.proto.money.FractionalMoney received_cost */ 15:
                    message.receivedCost = FractionalMoney.internalBinaryRead(reader, reader.uint32(), options, message.receivedCost);
                    break;
                case /* string txn_identifier */ 16:
                    message.txnIdentifier = reader.string();
                    break;
                case /* rd.proto.uuid.UUID sale_product_id */ 17:
                    message.saleProductId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.saleProductId);
                    break;
                case /* bool is_cost_edit */ 18:
                    message.isCostEdit = reader.bool();
                    break;
                case /* rd.product.StockStatus inventory_status */ 19:
                    message.inventoryStatus = reader.int32();
                    break;
                case /* map<int32, int32> status_quantities */ 20:
                    this.binaryReadMap20(message.statusQuantities, reader, options);
                    break;
                case /* rd.proto.uuid.UUID order_id */ 21:
                    message.orderId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.orderId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap20(map: InventoryChange["statusQuantities"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InventoryChange["statusQuantities"] | undefined, val: InventoryChange["statusQuantities"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field rd.product.InventoryChange.status_quantities");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: InventoryChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 2; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 3; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 4; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID sale_id = 5; */
        if (message.saleId)
            UUID.internalBinaryWrite(message.saleId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID shipment_id = 6; */
        if (message.shipmentId)
            UUID.internalBinaryWrite(message.shipmentId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID count_id = 7; */
        if (message.countId)
            UUID.internalBinaryWrite(message.countId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID transfer_id = 8; */
        if (message.transferId)
            UUID.internalBinaryWrite(message.transferId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 9; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User staff = 10; */
        if (message.staff)
            User.internalBinaryWrite(message.staff, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.InventoryChange.Type type = 11; */
        if (message.type !== 0)
            writer.tag(11, WireType.Varint).int32(message.type);
        /* int32 change = 12; */
        if (message.change !== 0)
            writer.tag(12, WireType.Varint).int32(message.change);
        /* int32 quantity = 13; */
        if (message.quantity !== 0)
            writer.tag(13, WireType.Varint).int32(message.quantity);
        /* rd.proto.money.FractionalMoney average_cost = 14; */
        if (message.averageCost)
            FractionalMoney.internalBinaryWrite(message.averageCost, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.FractionalMoney received_cost = 15; */
        if (message.receivedCost)
            FractionalMoney.internalBinaryWrite(message.receivedCost, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string txn_identifier = 16; */
        if (message.txnIdentifier !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.txnIdentifier);
        /* rd.proto.uuid.UUID sale_product_id = 17; */
        if (message.saleProductId)
            UUID.internalBinaryWrite(message.saleProductId, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bool is_cost_edit = 18; */
        if (message.isCostEdit !== false)
            writer.tag(18, WireType.Varint).bool(message.isCostEdit);
        /* rd.product.StockStatus inventory_status = 19; */
        if (message.inventoryStatus !== 0)
            writer.tag(19, WireType.Varint).int32(message.inventoryStatus);
        /* map<int32, int32> status_quantities = 20; */
        for (let k of Object.keys(message.statusQuantities))
            writer.tag(20, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Varint).int32(message.statusQuantities[k as any]).join();
        /* rd.proto.uuid.UUID order_id = 21; */
        if (message.orderId)
            UUID.internalBinaryWrite(message.orderId, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoryChange
 */
export const InventoryChange = new InventoryChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProductLabel$Type extends MessageType<ProductLabel> {
    constructor() {
        super("rd.product.ProductLabel", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "product_id", kind: "message", T: () => UUID },
            { no: 5, name: "location_id", kind: "message", T: () => UUID },
            { no: 6, name: "price_updated_at", kind: "message", T: () => Timestamp },
            { no: 7, name: "last_printed_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "last_printed_price", kind: "message", T: () => Money },
            { no: 9, name: "company_id", kind: "message", T: () => UUID }
        ]);
    }
    create(value?: PartialMessage<ProductLabel>): ProductLabel {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProductLabel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProductLabel): ProductLabel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 4:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 5:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* google.protobuf.Timestamp price_updated_at */ 6:
                    message.priceUpdatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.priceUpdatedAt);
                    break;
                case /* google.protobuf.Timestamp last_printed_at */ 7:
                    message.lastPrintedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastPrintedAt);
                    break;
                case /* rd.proto.money.Money last_printed_price */ 8:
                    message.lastPrintedPrice = Money.internalBinaryRead(reader, reader.uint32(), options, message.lastPrintedPrice);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 9:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProductLabel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 4; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 5; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp price_updated_at = 6; */
        if (message.priceUpdatedAt)
            Timestamp.internalBinaryWrite(message.priceUpdatedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp last_printed_at = 7; */
        if (message.lastPrintedAt)
            Timestamp.internalBinaryWrite(message.lastPrintedAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.money.Money last_printed_price = 8; */
        if (message.lastPrintedPrice)
            Money.internalBinaryWrite(message.lastPrintedPrice, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 9; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.ProductLabel
 */
export const ProductLabel = new ProductLabel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryCount$Type extends MessageType<InventoryCount> {
    constructor() {
        super("rd.product.InventoryCount", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "message", T: () => User },
            { no: 5, name: "updated_by", kind: "message", T: () => User },
            { no: 6, name: "update_history", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InventoryCount_UpdateHistory },
            { no: 7, name: "location_id", kind: "message", T: () => UUID },
            { no: 8, name: "company_id", kind: "message", T: () => UUID },
            { no: 9, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "status", kind: "enum", T: () => ["rd.product.InventoryCount.Status", InventoryCount_Status] },
            { no: 11, name: "accepted_by", kind: "message", T: () => User },
            { no: 12, name: "voided_by", kind: "message", T: () => User },
            { no: 13, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "type", kind: "enum", T: () => ["rd.product.InventoryCount.Type", InventoryCount_Type, "TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<InventoryCount>): InventoryCount {
        const message = { updateHistory: [], name: "", status: 0, notes: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryCount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryCount): InventoryCount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User created_by */ 4:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 5:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* repeated rd.product.InventoryCount.UpdateHistory update_history */ 6:
                    message.updateHistory.push(InventoryCount_UpdateHistory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* rd.proto.uuid.UUID location_id */ 7:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* rd.proto.uuid.UUID company_id */ 8:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* string name */ 9:
                    message.name = reader.string();
                    break;
                case /* rd.product.InventoryCount.Status status */ 10:
                    message.status = reader.int32();
                    break;
                case /* rd.user.User accepted_by */ 11:
                    message.acceptedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.acceptedBy);
                    break;
                case /* rd.user.User voided_by */ 12:
                    message.voidedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.voidedBy);
                    break;
                case /* string notes */ 13:
                    message.notes = reader.string();
                    break;
                case /* rd.product.InventoryCount.Type type */ 14:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryCount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 4; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 5; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated rd.product.InventoryCount.UpdateHistory update_history = 6; */
        for (let i = 0; i < message.updateHistory.length; i++)
            InventoryCount_UpdateHistory.internalBinaryWrite(message.updateHistory[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 7; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID company_id = 8; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string name = 9; */
        if (message.name !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.name);
        /* rd.product.InventoryCount.Status status = 10; */
        if (message.status !== 0)
            writer.tag(10, WireType.Varint).int32(message.status);
        /* rd.user.User accepted_by = 11; */
        if (message.acceptedBy)
            User.internalBinaryWrite(message.acceptedBy, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User voided_by = 12; */
        if (message.voidedBy)
            User.internalBinaryWrite(message.voidedBy, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string notes = 13; */
        if (message.notes !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.notes);
        /* rd.product.InventoryCount.Type type = 14; */
        if (message.type !== 0)
            writer.tag(14, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoryCount
 */
export const InventoryCount = new InventoryCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryCount_UpdateHistory$Type extends MessageType<InventoryCount_UpdateHistory> {
    constructor() {
        super("rd.product.InventoryCount.UpdateHistory", [
            { no: 1, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "updated_by", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<InventoryCount_UpdateHistory>): InventoryCount_UpdateHistory {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryCount_UpdateHistory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryCount_UpdateHistory): InventoryCount_UpdateHistory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp updated_at */ 1:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User updated_by */ 2:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryCount_UpdateHistory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp updated_at = 1; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 2; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoryCount.UpdateHistory
 */
export const InventoryCount_UpdateHistory = new InventoryCount_UpdateHistory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryCountLog$Type extends MessageType<InventoryCountLog> {
    constructor() {
        super("rd.product.InventoryCountLog", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "message", T: () => User },
            { no: 4, name: "inventory_count", kind: "message", T: () => InventoryCount }
        ]);
    }
    create(value?: PartialMessage<InventoryCountLog>): InventoryCountLog {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryCountLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryCountLog): InventoryCountLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* rd.user.User created_by */ 3:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.product.InventoryCount inventory_count */ 4:
                    message.inventoryCount = InventoryCount.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryCountLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 3; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.product.InventoryCount inventory_count = 4; */
        if (message.inventoryCount)
            InventoryCount.internalBinaryWrite(message.inventoryCount, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoryCountLog
 */
export const InventoryCountLog = new InventoryCountLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InventoryCountProduct$Type extends MessageType<InventoryCountProduct> {
    constructor() {
        super("rd.product.InventoryCountProduct", [
            { no: 1, name: "id", kind: "message", T: () => UUID },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "updated_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "created_by", kind: "message", T: () => User },
            { no: 5, name: "updated_by", kind: "message", T: () => User },
            { no: 6, name: "inventory_count_id", kind: "message", T: () => UUID },
            { no: 7, name: "product_id", kind: "message", T: () => UUID },
            { no: 8, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "status", kind: "enum", T: () => ["rd.product.InventoryCountProduct.Status", InventoryCountProduct_Status] },
            { no: 10, name: "company_id", kind: "message", T: () => UUID },
            { no: 11, name: "location_id", kind: "message", T: () => UUID },
            { no: 12, name: "variance", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "finalized_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InventoryCountProduct>): InventoryCountProduct {
        const message = { count: 0, status: 0, variance: 0, finalizedCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InventoryCountProduct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InventoryCountProduct): InventoryCountProduct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rd.proto.uuid.UUID id */ 1:
                    message.id = UUID.internalBinaryRead(reader, reader.uint32(), options, message.id);
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp updated_at */ 3:
                    message.updatedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updatedAt);
                    break;
                case /* rd.user.User created_by */ 4:
                    message.createdBy = User.internalBinaryRead(reader, reader.uint32(), options, message.createdBy);
                    break;
                case /* rd.user.User updated_by */ 5:
                    message.updatedBy = User.internalBinaryRead(reader, reader.uint32(), options, message.updatedBy);
                    break;
                case /* rd.proto.uuid.UUID inventory_count_id */ 6:
                    message.inventoryCountId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.inventoryCountId);
                    break;
                case /* rd.proto.uuid.UUID product_id */ 7:
                    message.productId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.productId);
                    break;
                case /* int32 count */ 8:
                    message.count = reader.int32();
                    break;
                case /* rd.product.InventoryCountProduct.Status status */ 9:
                    message.status = reader.int32();
                    break;
                case /* rd.proto.uuid.UUID company_id */ 10:
                    message.companyId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.companyId);
                    break;
                case /* rd.proto.uuid.UUID location_id */ 11:
                    message.locationId = UUID.internalBinaryRead(reader, reader.uint32(), options, message.locationId);
                    break;
                case /* int32 variance */ 12:
                    message.variance = reader.int32();
                    break;
                case /* int32 finalized_count */ 13:
                    message.finalizedCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InventoryCountProduct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rd.proto.uuid.UUID id = 1; */
        if (message.id)
            UUID.internalBinaryWrite(message.id, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated_at = 3; */
        if (message.updatedAt)
            Timestamp.internalBinaryWrite(message.updatedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User created_by = 4; */
        if (message.createdBy)
            User.internalBinaryWrite(message.createdBy, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rd.user.User updated_by = 5; */
        if (message.updatedBy)
            User.internalBinaryWrite(message.updatedBy, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID inventory_count_id = 6; */
        if (message.inventoryCountId)
            UUID.internalBinaryWrite(message.inventoryCountId, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID product_id = 7; */
        if (message.productId)
            UUID.internalBinaryWrite(message.productId, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 8; */
        if (message.count !== 0)
            writer.tag(8, WireType.Varint).int32(message.count);
        /* rd.product.InventoryCountProduct.Status status = 9; */
        if (message.status !== 0)
            writer.tag(9, WireType.Varint).int32(message.status);
        /* rd.proto.uuid.UUID company_id = 10; */
        if (message.companyId)
            UUID.internalBinaryWrite(message.companyId, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* rd.proto.uuid.UUID location_id = 11; */
        if (message.locationId)
            UUID.internalBinaryWrite(message.locationId, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* int32 variance = 12; */
        if (message.variance !== 0)
            writer.tag(12, WireType.Varint).int32(message.variance);
        /* int32 finalized_count = 13; */
        if (message.finalizedCount !== 0)
            writer.tag(13, WireType.Varint).int32(message.finalizedCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rd.product.InventoryCountProduct
 */
export const InventoryCountProduct = new InventoryCountProduct$Type();
